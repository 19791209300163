import React, {useState} from 'react';

import {DateRange } from 'react-date-range'
import {ptBR} from 'date-fns/locale'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {ContainerDate} from './styles'
import {addDays} from "date-fns";

export default function DateRangePicker({onChange, dates}) {

    const handleChange = (rangeDate) => {
        setRange(rangeDate)
        onChange(rangeDate)
    };

    const [range, setRange] = useState([
        {
            startDate: dates.startDate,
            endDate: dates.endDate,
            key: 'selection',
            color: '#40545E'
        }
    ]);

    const valid = !range[0].startDate && !range[0].endDate ? true : range[0].startDate && !range[0].endDate

    return (
        <ContainerDate noDate={valid}>
            <DateRange
                onChange={item => handleChange([item.selection])}
                showMonthAndYearPickers={false}
                moveRangeOnFirstSelection={false}
                showDateDisplay={false}
                dateDisplayFormat="dd/MM/yyyy"
                locale={ptBR}
                minDate={addDays(new Date(), 3)}
                monthDisplayFormat="LLLL"
                weekdayDisplayFormat="EEEEE"
                ranges={range}
                months={1}
                className="calendarElement"
                //onRangeFocusChange={item => setFocusedCalendar(item)}
            />
        </ContainerDate>
    );
}
