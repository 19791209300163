import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import api from '../_services/api'

const loadParams = (id,uuidsCheckCategories,uuidsCheckBrands,order,rangeSlide,newPage,limit,cpf,desc,target) => {
  const param = {}
  param.page = newPage
  param.limit = limit
  param.partner_customer_id = cpf

  if(target === 'brands') {
    param.brand_uuid = id
   }
  if(target === 'categories') {
   param.media_category_uuid = id
  }
  if(target === 'products') {
    param.q = desc
   }
  if (uuidsCheckCategories.length > 0) {
    param.category_uuid = uuidsCheckCategories
  }

  if (uuidsCheckBrands.length > 0) {
    param.brand_uuid =  uuidsCheckBrands
  }

  if (order !== '') {
      param.order = order
  }
  if (rangeSlide.current !== '') {
      param.min_price = Math.trunc(rangeSlide.lowerValue)
      param.max_price = rangeSlide.current || Math.trunc(rangeSlide.greaterValue)
  }
  return param
}

const orderAlphabetical = (a,b) => {
  const first = a?.label.toLowerCase() || ''
  const second = b?.label.toLowerCase() || ''
  if (first < second) return -1;
  if (first > second) return 1;
  return 0;
}

export const fetchCategory = createAsyncThunk('/fetchCategory', async ({id,newPage,limit,cpf},{getState}) => {
  const state = getState()
  const uuidsCheckCategories = UuidsCheckCategories(state)
  const uuidsCheckBrands = UuidsCheckBrands(state)
  const order = selectOrder(state)
  const rangeSlide = {
    'current': selectRangeSlide(state),
    'lowerValue': selectLowerValue(state),
    'greaterValue': selectGreaterValue(state),
    'greaterValuePoints': selectGreaterValuePoints(state),
    'lowerValuePoints': selectLowerValuePoints(state)
  }
  const response = await api.get('/api/product/category')
  const category = response.data.filter(item => item.id === id)
  const responseCategories = await api.get(category[0].location, 
    { params: loadParams(id,uuidsCheckCategories,uuidsCheckBrands,order,rangeSlide,newPage,limit,cpf,null,'categories')})
  return responseCategories.data
});

export const fetchBrands = createAsyncThunk('/fetchBrands', async ({id,newPage,limit,cpf},{getState}) => {
  const state = getState()
  const uuidsCheckCategories = UuidsCheckCategories(state)
  const uuidsCheckBrands = UuidsCheckBrands(state)
  const order = selectOrder(state)
  const rangeSlide = {
    'current': selectRangeSlide(state),
    'lowerValue': selectLowerValue(state),
    'greaterValue': selectGreaterValue(state),
    'greaterValuePoints': selectGreaterValuePoints(state),
    'lowerValuePoints': selectLowerValuePoints(state)
  }

  const response = await api.get('/api/product/list', 
    { params: loadParams(id,uuidsCheckCategories,uuidsCheckBrands,order,rangeSlide,newPage,limit,cpf,null,'brands')})
  return response.data
});

export const fetchProducts = createAsyncThunk('/fetchProducts', async ({newPage,limit,cpf,desc},{getState}) => {
  const state = getState()
  const uuidsCheckCategories = UuidsCheckCategories(state)
  const uuidsCheckBrands = UuidsCheckBrands(state)
  const order = selectOrder(state)
  const rangeSlide = {
    'current': selectRangeSlide(state),
    'lowerValue': selectLowerValue(state),
    'greaterValue': selectGreaterValue(state),
    'greaterValuePoints': selectGreaterValuePoints(state),
    'lowerValuePoints': selectLowerValuePoints(state)
  }

  const response = await api.get('/api/product/list', 
    { params: loadParams(null,uuidsCheckCategories,uuidsCheckBrands,order,rangeSlide,newPage,limit,cpf,desc,'products')})
  return response.data
});

function MountCategories(Categories,state) {
  const categoriesArray = []
  for (let i in Categories) {
      const Exists = current(state).filter(element => element.id == Categories[i].categories[0].id)
      if (Exists.length === 0) {
        const categoryJson = {}
        categoryJson.label = Categories[i].categories[0].label
        categoryJson.id = Categories[i].categories[0].id
        categoryJson.check = false
        categoriesArray[categoryJson.id] = categoryJson
      }
  }
  const values = Object.values(categoriesArray).sort(orderAlphabetical)
  return values;
}

function MountBrands(Brands,state) {
  const brandsArray = []
  for (let i in Brands) {
      const Exists = current(state).filter(element => element.id == Brands[i].brand.id)
      if (Exists.length === 0) {
        const brandJson = {}
        brandJson.label = Brands[i].brand.label
        brandJson.id = Brands[i].brand.id
        brandJson.check = false
        brandsArray[brandJson.id] = brandJson
      }
  }
  const values = Object.values(brandsArray).sort(orderAlphabetical)
  return values;
}

const initialState = {
  brands: [],
  categories: [],
  order: '',
  lowerValue: '',
  greaterValue: '',
  greaterValuePoints: '',
  lowerValuePoints: '',
  rangeSlide: '',
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    addBrand: (state, action) => {
      state.brands = action.payload;
    },
    delBrand: (state, action) => {
      var arr = state.brands.filter((item) => {
        return item.id == action.payload.id;
      });
      state.brands = arr;
    },
    toggleCheckBrand: (state, action) => {
      const index = state.brands.findIndex(
        (todo) => todo.id === action.payload.id
      )
    state.brands[index].check = !state.brands[index].check
    },
    toggleCheckCategory: (state, action) => {
      const index = state.categories.findIndex(
        (todo) => todo.id === action.payload.id
      )
    state.categories[index].check = !state.categories[index].check
    },
    clearCheckBrand: (state,action) => {
      state.brands.map((item) => {
        item.check = false;
      });
      state.order = ''
    },
    clearCheckCategories: (state,action) => {
      state.categories.map((item) => {
        item.check = false;
      });
      state.order = ''
    },
    setOrder: (state,action) => {
      state.order = action.payload.order
    },
    setCurrentLocation: (state,action) => {
      state.currentLocation = action.payload.location
    },
    setRangeSlide: (state,action) => {
      state.rangeSlide = action.payload.value
    },
    setBrandsCategory: (state,action) => {
      state.categories = MountCategories(action.payload,state.categories)
      state.brands = MountBrands(action.payload,state.brands)
    },
    setBorderPrices: (state,action) => {
      state.greaterValue = action.payload.greaterValue
      state.lowerValue = action.payload.lowerValue
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategory.fulfilled, (state, action) => {
      state.categories = state.categories.length === 0 ? MountCategories(action.payload.items,state.categories) :state.categories
      state.brands = state.brands.length === 0 ? MountBrands(action.payload.items,state.brands) : state.brands
      state.greaterValue = action.payload.greaterValue
      state.lowerValue = action.payload.lowerValue
      state.greaterValuePoints = action.payload.greaterValuePoints
      state.lowerValuePoints = action.payload.lowerValuePoints
      state.factor = action.payload.factor
    }),
    builder.addCase(fetchBrands.fulfilled, (state, action) => {
      state.categories = state.categories.length === 0 ? MountCategories(action.payload.items,state.categories) :state.categories
      state.brands = state.brands.length === 0 ? MountBrands(action.payload.items,state.brands) : state.brands
      state.greaterValue = action.payload.greaterValue
      state.lowerValue = action.payload.lowerValue
      state.greaterValuePoints = action.payload.greaterValuePoints
      state.lowerValuePoints = action.payload.lowerValuePoints
      state.factor = action.payload.factor
    }),
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.categories = state.categories.length === 0 ? MountCategories(action.payload.items,state.categories) :state.categories
      state.brands = state.brands.length === 0 ? MountBrands(action.payload.items,state.brands) : state.brands
      state.greaterValue = action.payload.greaterValue
      state.lowerValue = action.payload.lowerValue
      state.greaterValuePoints = action.payload.greaterValuePoints
      state.lowerValuePoints = action.payload.lowerValuePoints
      state.factor = action.payload.factor
    })
  }
});

export const { addBrand, delBrand, toggleCheckBrand,toggleCheckCategory,setOrder,clearCheckBrand,clearCheckCategories,setRangeSlide,setBrandsCategory,setBorderPrices } = filterSlice.actions;

export const selectBrands  = state => state.filterSlice.brands
export const selectCategories  = state => state.filterSlice.categories
export const selectOrder  = state => state.filterSlice.order
export const selectLowerValue  = state => state.filterSlice.lowerValue
export const selectGreaterValue  = state => state.filterSlice.greaterValue
export const selectGreaterValuePoints  = state => state.filterSlice.greaterValuePoints
export const selectLowerValuePoints  = state => state.filterSlice.lowerValuePoints

export const factor  = state => state.filterSlice.factor
export const selectRangeSlide  = state => state.filterSlice.rangeSlide
export const counterCheckBrands = state => state.filterSlice.brands.filter((item) => item.check === true).length
export const counterCheckCategories = state => state.filterSlice.categories.filter((item) => item.check === true).length
export const UuidsCheckBrands = state => state.filterSlice.brands.filter((item) => item.check === true).map((it) => it.id).toString()
export const UuidsCheckCategories = state => state.filterSlice.categories.filter((item) => item.check === true).map((it) => it.id).toString()

export default filterSlice.reducer;