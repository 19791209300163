import Styled from 'styled-components'

export const Container = Styled.div`
 margin: 5%;
`
export const Banner = Styled.div`

.carousel-control-prev-icon{
    background-image: none !important;
}

.carousel-control-next-icon{
    background-image: none !important;
}

button{
width: 8px !important;
height: 8px !important; 
background-color: #FFFFFF !important;
border-radius: 100% !important;
}

`
export const ContainerTicketData = Styled.section`
margin-top: 35px;
`


export const SearchTicket = Styled.h3`
font-size: 20px;
color: black;
font-weight: bold;
`

export const CheckboxContainer = Styled.div`
margin-top: 20px;
display: flex;
gap: 80px;


label{
padding-left: 5px;
cursor: pointer;
color: #474646;
font-weight: bold;
font-size: 17px;
@media(max-width: 800px) {
  font-size: 14px;
}
&:active{
    color: #9DA1A6;
}
}

@media(max-width: 800px) {
    line-height: 20px;
}
`

export const AlignRadio = Styled.div`
display: flex;
`

export const Radio = Styled.input`
cursor: pointer;
appearance: none;
border: 1px solid  #263238;
width: 25px;
height: 25px;
border-radius: 50%;
position: relative;
display:flex;
align-items: center;
justify-content: center;


&:before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    background: #263238; 
    border-radius: 50%;
    opacity: 0;
}
&:checked:before {
    opacity: 1;
}

@media(max-width: 800px) {
    width: 20px;
    height: 20px;

    &:before {
        width: 14px;
        height: 14px;
    }
}

`
export const ContainerContent = Styled.form`
margin: 0 auto;
width: 100%;
height: auto;
padding: 25px;
background-color: white;
position: relative;

@media(max-width: 800px) {
width: 96%;
height: auto;
}
`

export const Label = Styled.h4`
font-size: 20px;
font-weight: bold;
margin-top: 25px;
margin-bottom: 20px;
`
