import Styled from 'styled-components'
import {IoIosInformationCircle} from 'react-icons/io'

export const CheckboxContainer = Styled.div`
margin-top: 15px;
display: flex;
align-items: center;

label{
padding-left: 10px;
cursor: pointer;
line-height: 20px;
&:active{
    color: #9DA1A6;
}
}

`

export const InformationIcon = Styled(IoIosInformationCircle)`
margin-right: 5px;
height: 25px;
width: 25px;
color: #40545E;
`

export const TitleParagraph = Styled.p`
padding-top: 38px;
color: #40545E;
font-weight: 600;
font-size: 14px;
`

export const DivAlign = Styled.div`
display: flex;
`


export const TextRegulation = Styled.p`
color: #40545E;
font-size: 12px;
`

export const CheckboxDiv = Styled.div`
margin-top: 30px;
display: flex;
`
export const LabelCheckbox = Styled.label`
color:#263238;
font-size: 14px;
font-weight: 400;
display: flex;
cursor: pointer;

.paragraph{
color: #000000;
font-weight: 700;
font-size: 12px;
padding-top: 3px;

@media(max-width: 800px) {
    padding-top: 0;
}
}

.css-dk24d0-MuiButtonBase-root-MuiCheckbox-root{
    padding:0;
    color: black;
    margin-right: 4px;
}
`
