import Styled from 'styled-components'

export const BoxPlace = Styled.div`
    position: relative;
    bottom: 200px;
    left: 50%;
    transform: translateX(-50%);
    width: 330px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 14px;
    z-index: 9999;
    
    .box-content {
        display: flex;
        gap: 10px;
        text-align: left;
    }
    
    .box-content .image {
        width: 40%;
        height: 220px;
        background-color: #d6d6d6;
    }
    
    .box-content .image img {
        object-fit: cover;
        width: 100%;
        height: 220px;
    }
    
    .icon-close {
        position: absolute;
        right: 3px;
        top: 3px;
    }
   
    .place-name {
        font-size: 14px;
        font-weight: bold;
        max-width: 190px;
        margin: 0 0 5px 0;
    }
        
    .rating {
        margin: 5px 0;
    }
        
    .box-info p {
        padding: 2px 5px; 
        margin-bottom: 5px;
        background-color: #f0f0f0;
        font-size: 12px; 
        display: inline-block;
    }
    
    .box-price p {
        font-size: 26px;
        margin: 0;
    }
    
    .box-price p span {
        font-size: 19px;
    }
    
    .box-price p:first-child {
        font-size: 12px;
        margin: 8px 0 0 0;
    }
    
    .box-price p:last-child {
        margin: 0 0 8px 0;
        font-size: 12px;
        color: #26323890;
    }
    
    .box-button {
        position: absolute;
        bottom: 12px;
    }
    
`

export const BoxPlaceSm = Styled.div`
    position: relative;
    bottom: 100px;

    left: 50%;
    transform: translateX(-50%);
    width: 330px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.3);
    padding: 10px;
    font-size: 14px;
    z-index: 9999;
    
    padding-top: 12px;
    text-align: start;
    
    .box-content {
        display: flex;
        gap: 5px;
        text-align: left;
    }
        
    .icon-close {
        position: absolute;
        right: 5px;
        top: 5px;
    }
    
    .address {
        color: #6C6C6C; 
    }
    
    .place-name {
        font-size: 14px;
        align-self: center;
        font-weight: bold;
        width: 180px;
        margin-bottom: 0;
        margin-top: -3px;
    }
    
    .rating {
        margin: 0;
    }
`
