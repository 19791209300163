import React, { useState } from "react";

import format from "date-fns/format";
import { FiChevronDown } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";

import { ModalApp } from "components/Modal";
import DateRangePicker from "components/SearchComponents/DateRangePicker";
import Button from "components/Button";
import TimePicker from "components/SearchComponents/TimePicker";

import { useApp } from "contexts/AppContext";
import { changeDatesCarAction, changeTimeCarAction } from "contexts/reducers/cars/actions";
import {useEffect} from "react";
import moment from "date-fns/format";

export default function ReserveDateTime() {

    const [open, setOpen] = useState(false);
    const [focused, setFocused] = useState([0,0])
    const [disabled, setDisabled] = useState(false)

    const { carState, carDispatch } = useApp()
    const { datesSearchCar, timeSearchCar } = carState

    useEffect(() => {
        if(!datesSearchCar.startDate && !datesSearchCar.endDate) {
            setDisabled(true)
        }
    },[])

    const handleModal = () => {
        setOpen(!open);
    };

    const validateDates = (dates) => {
        let start = dates.startDate != null && moment(dates.startDate, "dd/MM/yyyy");
        let end = dates.endDate != null && moment(dates.endDate, "dd/MM/yyyy");

        return start !== end;
    }

    const handleChange = (dates) => {
        const date = dates[0];

        const formatData = {
            startDate: date?.startDate,
            endDate: date?.endDate,
        }

        const validate = validateDates(formatData)

        if (!validate) {
            formatData.endDate = null
            setFocused([0,1])
            setDisabled(true)

            const formatStartDate = format(date?.startDate, "yyyy-MM-dd")
            const start = new Date(formatStartDate + 'T' + timeSearchCar.startDate +':00')
            const end = null;

            const datesFormat = {
                startDate: start,
                endDate: end,
                days: 0
            }

            carDispatch(changeDatesCarAction(datesFormat))
            return false
        }

        setFocused([0,0])
        setDisabled(false)

        const formatStartDate = format(date?.startDate, "yyyy-MM-dd")
        const formatEndDate = format(date?.endDate, "yyyy-MM-dd")

        const start = new Date(formatStartDate + 'T' + timeSearchCar.startDate +':00')
        const end = new Date(formatEndDate + 'T' + timeSearchCar.endDate +':00')

        var timeDiff = Math.abs(start.getTime() - end.getTime());
        var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

        const datesFormat = {
            startDate: start,
            endDate: end,
            days: diffDays
        }

        carDispatch(changeDatesCarAction(datesFormat))
    }

    const handleChangeTime = (time, position) => {
        let newDate = {...datesSearchCar}
        let newTime = {...timeSearchCar}

        newTime[position] = time

        const formatDate = format(newDate[position], "yyyy-MM-dd")
        newDate[position] = new Date(formatDate + 'T' + time +':00')

        carDispatch(changeTimeCarAction(newDate, newTime))
    }

    return (
        <div className="content-custom">
            <div className="row">
                <div className="col-12">
                    <div className="input-group" onClick={handleModal}>
                        <label className="label-custom">Data de retirada</label>
                        <span className="input-group-custom">
                            {  datesSearchCar.startDate ? format(datesSearchCar.startDate, "dd/MM/yyyy") : '' } <IoCalendarOutline/>
                        </span>
                        <span className="divider"/>
                        <span className="input-group-custom">
                            { timeSearchCar.startDate } <FiChevronDown />
                        </span>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <div className="input-group" onClick={handleModal}>
                        <label className="label-custom">Data de entrega</label>
                        <span className="input-group-custom">
                           {  datesSearchCar.startDate ? format(datesSearchCar.startDate, "dd/MM/yyyy") : '' }<IoCalendarOutline/>
                        </span>
                        <span className="divider"/>
                        <span className="input-group-custom">
                            { timeSearchCar.endDate } <FiChevronDown />
                        </span>
                    </div>

                </div>
            </div>

            <ModalApp show={open} handleModal={handleModal}>
                <p className="label-modal">
                    Selecione as datas de Check-in e Check-out
                </p>

                <div className="row shadow-sm mb-1 mt-3">
                    <div className="col-6">
                        <div className={`d-flex flex-column gap-1 py-2 ${focused[1] === 0 && 'border-focused'}`}>
                            <span className="text-xs fw-bold" style={{color: "#263238"}}>Check-in</span>
                            <span className="text-sm">
                                { datesSearchCar.startDate
                                    ? format(datesSearchCar.startDate, "dd/MM/yyyy HH:mm") : '-'}
                            </span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={`d-flex flex-column gap-1 py-2 ${focused[1] === 1 && 'border-focused'}`}>
                            <span className="text-xs fw-bold" style={{color: "#263238"}}>Check-out</span>
                            <span className="text-sm">{datesSearchCar.endDate
                                ? format(datesSearchCar.endDate, "dd/MM/yyyy HH:mm") : '-' }</span>
                        </div>
                    </div>
                </div>

                <DateRangePicker
                    onChange={handleChange}
                    dates={datesSearchCar}
                />

                <TimePicker
                    onChange={handleChangeTime}
                    time={timeSearchCar}
                />

                <div className="mt-2 mb-4">
                    <Button
                        type="button"
                        width="200px"
                        disabled={disabled}
                        onClick={handleModal}
                    >Confirmar</Button>
                </div>
            </ModalApp>
        </div>
    )
}
