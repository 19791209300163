import React, {useState, useEffect} from 'react'
import {ContainerContent, BoxCategories} from './styles'
import Accordion from '@mui/material/Accordion'
import {Container} from 'react-bootstrap'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import {HiOutlineChevronDown} from 'react-icons/hi'
import {getCategories} from './services/categoriesServices'
import notify from 'components/UI/toast'
import SearchInput from "../../../SearchInput";
import {useNavigate} from "react-router";
import {useOpen} from "../../../../hooks/Open";
import Title from "../../../../components/Title";

export default function Categories() {

    const { OpenButton, setCategory } = useOpen()
    const navigate = useNavigate()

    const [search, setSearch] = useState('');
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    function groupByKey(array, prop) {
        return array.reduce(function (items, item) {
            const key = item[prop];
            items[key] = (items[key] || []).concat(item);
            return items;
        }, {});
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await getCategories()
                setData(response);

                const categorie = response.filter(category => !category.parent_uuid);
                const subcategorie = response.filter(item => item.parent_uuid);

                const group = categorie.map(item => ({
                    'category': item,
                    'subcategories': subcategorie.filter(sub => sub.parent_uuid === item.id)
                }))

                setCategories(group)

                setLoading(false)
            } catch (err) {
                setLoading(false)
                notify('Erro ao tentar carregar categorias', 'error')
            }
        }

        fetchData()

    }, []);

    const filter = () => {
        const searchInput = search.toLowerCase()

        const subCategoriesFilter = data.filter(sub => sub.parent_uuid);
        const categoriesFilter = data.filter(category => !category.parent_uuid);

        const filterSub = subCategoriesFilter.filter(item => item.label.toLowerCase().includes(searchInput));
        const filterCat = categoriesFilter.filter(item => item.label.toLowerCase().includes(searchInput));

        const filterGroupSubKey = groupByKey(filterSub, 'parent_uuid');

        const getCategory = (param) => {
            const item = categoriesFilter.filter(i => i.id === param)
            return item[0]
        }

        const cat = filterCat.map(item => ({
            'category': item,
            'subcategories': []
        }))

        const sub = filterSub.map(item => ({
            'category': getCategory(item.parent_uuid),
            'subcategories': filterGroupSubKey[item.parent_uuid] ? filterGroupSubKey[item.parent_uuid] : []
        }))

        return cat.concat(sub)
    }

    const redirectPage = (id) => {
        setCategory(false)
        OpenButton()
        navigate(`/loja/category/${id}`)
        window.location.reload();
    }

    const filterCategories = search && search.length >= 2 ? filter() : categories;

    return (
        <div className="genial-page mt-4">
            <Container>
                <Title titleClass="text-center width-100" label="Categorias" />
                <SearchInput
                    value={search}
                    onChange={(e) => setSearch(e)}
                    placeholder="Pesquisar categorias"
                />

                {loading  ?
                    <div className="text-center">
                        <p className="fs-6">Carregando categorias </p>
                    </div>

                    : !filterCategories.length ?
                        <div className="text-center">
                            <p className="fs-6">Nenhuma categoria encontrada </p>
                        </div>
                    :
                    <BoxCategories className="overflow-none">
                        <ContainerContent component="ul">
                            {filterCategories.map((item, index) =>
                                item.subcategories.length > 0 ?
                                    <>
                                        <div className="border-bottom-genial" key={`${item.category.id}-${index}`}>
                                            <Accordion sx={{boxShadow: 0}} className="accordion">
                                                <AccordionSummary
                                                    expandIcon={<HiOutlineChevronDown size={25}/>}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="p-0"
                                                >
                                                    <span className="font-genial-700 color-genial-black text-mg" onClick={() => redirectPage(item.category.id)}>{item.category.label}</span>
                                                </AccordionSummary>
                                                <AccordionDetails key={item.category.id} className="pt-0 mr-3 subcategories">
                                                    {item.subcategories.map(subcategorie =>
                                                        <li key={subcategorie.id} className="label">
                                                            <span className="font-genial-400 color-genial-black text-lg" onClick={() => redirectPage(subcategorie.id)} role="button">{subcategorie.label}</span>
                                                        </li>
                                                    )}
                                                </AccordionDetails>
                                            </Accordion>
                                        </div>
                                        <hr className="m-0"/>
                                    </>
                                    :
                                    <>
                                        <li className="label border-bottom-genial"
                                            key={`${item.category.id}-${index}`}>
                                            <span className="font-genial-700 color-genial-black text-mg" onClick={() => redirectPage(item.category.id)} role="button">{item.category.label}</span>
                                        </li>
                                        <hr className="m-0"/>
                                     </>
                            )}
                        </ContainerContent>
                    </BoxCategories>
                }
            </Container>
        </div>
    )
}
