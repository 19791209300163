import format from "date-fns/format";
import {addDays} from "date-fns";
import {useLocation} from "react-router-dom";

export const classes = [
    {name: "economic", label: "Econômica"},
    {name: "premium_economy", label: "Premium economy"},
    {name: "executive", label: "Executiva"},
    {name: "first_class", label: "Primeira classe"}
]

export const initial_dates = {
    startDate: addDays(new Date(), 1),
    endDate: addDays(new Date(), 2),
    startDateFormat: format(new Date(), "dd/MM/yyyy"),
    endDateFormat: format(new Date(),"dd/MM/yyyy"),
    startDateInfo: format(new Date(), 'dd/MM/yy'),
    endDateInfo: format(new Date(),  'dd/MM/yy')
}

export const initial_dates_hotel = {
    startDate: null,
    endDate: null,
    days: 0
}

export const initial_date = {
    startDate: null,
    endDate: null,
    days: 0
}

export const initial_time = {
    startDate: '09:00',
    endDate: '09:00',
}


export let search_option = [

    {value: "compra_passagem", label: "Passagem"},
    {value: "compra_hotel", label: "Hotel"}

]

export const initial_passengers = {
    'adults': 1,
    'children': 0,
    'class': 'economic',
    'class_label': 'Econômica'
}

export const initial_room = [{
    'adults': 1,
    'children': 0,
    'children_age': []
}]

export const initial_guests = {
    'adults': 1,
    'children': 0,
    'childrenInputAge': []
}

export const data_travellers = [
    {
        "type": "adults",
        "firstName": "",
        "lastName": "",
        "birthDate": "",
        "gender": "",
        "document": "",
    }
];

export const initial_filter_cars = {
    "filterParameters": {
        "price": {
            "min": 0,
            "max": 150000
        }
    }
}

export const initial_filter_hotels = {
    "filterParameters": {
        "price": {
            "min": 0,
            "max": 350000
        }, 
        "hotelStars": [
            "4",
            "5"
        ]
    }
}

export const payments = [
    { name: "points", label: "Pontos" },
    { name: "cash_points", label: "Pontos + Cartão" }
];

export const category_car = [
    { name: "compact", label: "Compacto", value: "Compact"},
    { name: "luxury_compact", label: "Compacto Luxo", value: "CompactElite"},
    { name: "economic", label: "Econômico", value: "Economy" },
    { name: "luxury_economic", label: "Econômico Luxo", value: "EconomyElite" },
    { name: "sedan", label: "Sedan", value: "Intermediate" },
    { name: "minivan", label: "Mini Van", value: "Mini" },
    { name: "suc", label: "SUV", value: "Fullsize" },
    { name: "luxury_car", label: "Carro Luxo", value: "IntermediateElite" },
]

export const transmission_car = [
    { name: "manual_gear", label: "Câmbio Manual", value: "Manual" },
    { name: "automatic_exchange", label: "Câmbio Automático", value: "Automatic" },
]

export const air_conditioning_car = [
    { name: "air_conditioning", label: "Com Ar Condicionado", value: "true" },
]

export const meal_plan = [
    { name: "no_breakfast", label: "Sem café da manhã", value: '' },
    { name: "breakfast", label: "Café da manhã incluso", value: 'breakfast' },
]

export const images_hotel = [
    {image: "https://swiperjs.com/demos/images/nature-1.jpg"},
    {image: "https://swiperjs.com/demos/images/nature-2.jpg"},
    {image: "https://swiperjs.com/demos/images/nature-3.jpg"},
    {image: "https://swiperjs.com/demos/images/nature-4.jpg"},
    {image: "https://swiperjs.com/demos/images/nature-5.jpg"},
    {image: "https://swiperjs.com/demos/images/nature-6.jpg"},
    {image: "https://swiperjs.com/demos/images/nature-7.jpg"}
]

export let urlChannel = function () {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    let channel = params.get("channel");

    if (! channel) {
        return ;
    }

    if (localStorage.getItem('channel') !== channel) {
        localStorage.setItem('channel', channel)
    }
}

export let isChannel = function (channel) {
    return localStorage.getItem('channel') === channel; 
}

