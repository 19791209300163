import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import $ from "jquery";
import axios from "axios";

import { createContext, useContext, useReducer } from "react";
import { HotelsReducer } from "./reducers/hotels/reducers";
import { CarsReducer } from "./reducers/cars/reducers";
import { AirfareReducer } from "./reducers/airfare/reducers";

import {initial_time, initial_guests, data_travellers, initial_dates, initial_date} from "utils";

const INITIAL_STATE_HOTEL = {
    locationSearchHotel: {},
    datesSearchHotel: initial_date,
    guestsSearchHotel: initial_guests,
    offersHotel: 'points',
    hotelsList: [],
    hotel: {},
    room: {},
    travellers: data_travellers
}

 const INITIAL_STATE_CAR = {
    locationPickupCar: {},
    locationDropOffCar: {},
    returnSameLocalCar: true,
    datesSearchCar: initial_date,
    timeSearchCar: initial_time,
    offersCar: 'points',
    car: {},
    conductor: {}
}

const INITIAL_STATE_AIRFARE = {
    locationDeparture: {},
    locationArrival: {},
    onlyDeparture: false,
    datesSearchAirfare: initial_date,
    offersAirfare: 'points',
    classAirfare: 'economic',
    numberOfPassengers: {
        adults: 1,
        children: 0,
        baby: 0
    }
}

export const GenialAppContext = createContext('')

export const GenialAppProvider = ({ children }) => {

    const [hotelState, hotelDispatch] = useReducer(
        HotelsReducer,
        INITIAL_STATE_HOTEL
    )

    const [carState, carDispatch] = useReducer(
        CarsReducer,
        INITIAL_STATE_CAR
    )

    const [airfareState, airfareDispatch] = useReducer(
        AirfareReducer,
        INITIAL_STATE_AIRFARE
    )

    const data = $.param({
        'client_id':  process.env.REACT_APP_CLIENT_ID,
        'client_secret':  process.env.REACT_APP_CLIENT_SECRET,
        'grant_type': 'client_credentials'
    })

    useEffect(() => {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
        };

        axios(config).then(response => {
            if (response.status === 200) {
                const token =  response.data.access_token;
                localStorage.setItem("token_travel_genial_v1", token);
            }
        }).catch(error => console.log('ERROR', error))
    },[])

    useEffect(() => {
        var config = {
            method: 'post',
            url: process.env.REACT_APP_API_URL,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data : data
        };

        axios(config).then(response => {
            if (response.status === 200) {
                const token =  response.data.access_token;
                localStorage.setItem("token", token);
            }
        }).catch(error => console.log('ERROR', error))
    },[])

    return (
        <GenialAppContext.Provider value={{
            hotelState,
            hotelDispatch,
            carState,
            carDispatch,
            airfareState,
            airfareDispatch
        }}>
            {children}
        </GenialAppContext.Provider>
    )
}

export const useGenialApp = () => useContext(GenialAppContext);

GenialAppProvider.propTypes = {
    children: PropTypes.node.isRequired,
};