import React from 'react'

import PropTypes from 'prop-types'

import { Menu } from './styles'

export default function Title({label,noMargin,titleClass = ''}) {
    return (
      <div className={`d-flex ${noMargin ? 'mt-0' : 'mt-3 mb-4'}`}>
        <Menu className={titleClass}>{label}</Menu>
      </div>
    );
}

Title.propTypes = {
    label: PropTypes.string,
    noMargin: PropTypes.bool,
    titleClass: PropTypes.string
}
