import React from 'react';
import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import "./styles.css"
import { styled } from '@mui/material/styles';


export function Star() {
  const [value, setValue] = React.useState(2);

  return (
    <Box
      sx={{
        '& > legend': { mt: 2 },
      }}
    >
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    </Box>
  );
}

export function FixedRating({value, size = ''}) {
    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#40545E',
        }
    });
    return (
            <StyledRating
                name="customized-color"  value={value} precision={0.5} readOnly size={size}
            />
    );
}
