import ReserveDate from "components/SearchComponents/ReserveDate";
import RoomQuantity from "components/SearchComponents/RoomQuantity";
import Offers from "components/SearchComponents/Offers"
import SearchLocation from "components/SearchComponents/SearchLocation";

import {changeLocationsHotelAction, changeOfferHotelAction} from "contexts/reducers/hotels/actions";
import { useApp } from "contexts/AppContext";
import { useGenialApp } from "contexts/GenialAppContext";
import {isChannel} from "../../utils";


export default function () {

    const { hotelState, hotelDispatch } = !isChannel('genial') ? useApp() : useGenialApp()
    const { offersHotel, locationSearchHotel } = hotelState

    const searchLocation = (local) => {
        hotelDispatch(changeLocationsHotelAction(local))
    }

    const changeOffers = (offers) => {
        hotelDispatch(changeOfferHotelAction(offers))
    }

    return (
        <div className="box-search">
            <SearchLocation
                placeholder="Digite aqui uma cidade"
                location={ locationSearchHotel }
                onChange={searchLocation}
            />

            <ReserveDate />

            <RoomQuantity />

            <Offers
                offers={offersHotel}
                changeOffers={changeOffers}
            />
        </div>
    )
}
