import Styled from 'styled-components'


export const Container = Styled.div`
text-align: center;
margin: 5%;
`

export const ContainerImgText = Styled.div`

.img-aprovado{
    margin-top:  40px;
    margin-bottom:  40px;
}

.resgate{
    color: #5F5F5F;
    font-size: 20px;
    font-weight: 700;
}

.infor{
    color: #5F5F5F;
    font-weight: 400;
    font-size: 16px;
}

.protocolo{
   background-color: #F0F0F0;
   margin-top: 20px;
p{
        margin-bottom: 0;
        color: #5F5F5F;
        font-weight: 400;
        font-size: 17px;
}
    
span{
    color: #5F5F5F;
    font-weight: 700;
    font-size: 20px;
}
}
`

export const ContainerCarousel = Styled.div`
margin-top: 50px;

.option{
text-align: left;
font-size: 20px;
font-weight: 700;
color: #5F5F5F;
}

.btn-home{
    margin-top: 60px;
}

.swiper-horizontal>.swiper-pagination-bullets, 
.swiper-pagination-bullets.swiper-pagination-horizontal{
    display: none;
}
`

export const DivContentHotelInfor = Styled.div`
display: flex;
background-color:#F0F0F0;
padding: 10px;
box-shadow: 4px 7px 10px rgba(0, 0, 0, 0.28);
margin-bottom: 20px;
p{
    margin-bottom: 0;
}

@media(max-width: 800px) {
    width: 96%;
}
`

export const HotelInformations = Styled.div`
padding-left: 13px;
text-align: left;
width: 100%;

.icon{
    float:right;
    color: black;
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.title-hotel{
    color: #40545E;
    font-weight: 700;
    font-size: 17px;
}

.stars{
    .css-iljtu2-MuiRating-root{
       color: #40545E;
    }
}

.sem-rembolso{
 background-color:#E6E6E6;
 width: 70%;
 text-align: center;
 color: #474646;
 font-weight: 400;
 font-size: 13px;
}

.cafe-da-mnha{
 margin-top: 5px;
 background-color:#E6E6E6;
 color: #474646;
 width: 70%;
 text-align: center;
 font-weight: 400;
 font-size: 13px;
}

.diaria{
margin-top: 5px;
color: #263238;
font-weight: 400;
font-size: 12px;
}

.price{
color: #263238;
font-weight: 700;
font-size: 26px;

span{
font-size: 19px;
}
}

.taxa{
color: rgba(38, 50, 56, 0.5);
font-weight: 400;
font-size: 10px;
margin-top: -5px;
margin-bottom: 10px
}
`

export const ButtonHotel = Styled.button`
height: 22px;
width: 145px;
border-radius: 9px;
background-color: #40545E;
border-radius: 9px;
border: 0;
color: white;

`