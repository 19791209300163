import Styled from 'styled-components'
import { IoIosInformationCircle } from 'react-icons/io'
import { RiCloseFill } from "react-icons/ri";

export const Information = Styled.div`
padding: 10px 15px;
background: #EEEEEE;
align-items: center;
margin-top: 114px;

display: flex;
    p {
        color: #474646;
        font-weight: 400;
        font-size: 13px;
        margin-bottom: 0;
    
    button{
        text-decoration: none;
        font-weight: bold;
        color: #40545E;
        background: none;
        border: none;
    }
}
`
export const InfromationIcon = Styled(IoIosInformationCircle)`
margin-right: 10px;
height: 30px;
width: 30px;
color: #006C8E;
`

export const InfromationIconOpen = Styled(IoIosInformationCircle)`
margin-right: 10px;
height: 38px;
width: 38px;
color: #006C8E;
`

export const Close = Styled(RiCloseFill)`
float: right;
color: black;
width: 25px;
height: 25px;
margin-top: 10px;
margin-right: 10px;
cursor: pointer;
`
export const ContainerRegulation = Styled.div`
background: #FFFFFF;
text-align: center;


.text{
margin: 6%;
color: #474646;    
font-weight: 400;
font-size: 12px;
text-align: left;
}
`
export const DivParagraph = Styled.div`
padding-top: 5px;
margin: 0 6%;
display: flex;
align-items: center;
text-align: left;
p{
    margin-bottom: 0;
}

.att{
color: #263238;
font-weight: 700;
font-size: 17px;
}

.location{
color: rgba(71, 70, 70, 0.5);
font-weight: 400;
font-size: 14px;
}

`
export const ButtonConfirm = Styled.button`
height: 30px;
width: 187px;
border-radius: 9px;
background: #40545E;
border: none;
color: white;
margin-bottom: 18px;
`
