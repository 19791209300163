import {data_travellers, initial_dates_hotel, initial_guests} from "../../../utils";

export function HotelsReducer(state, action){
    switch (action.type) {
        case 'SEARCH_LOCATION_HOTEL':
            return {
                ...state,
                locationSearchHotel: action.payload.locationSearchHotel
            }
        case 'SEARCH_DATES_HOTEL':
            return {
                ...state,
                datesSearchHotel: action.payload.datesSearchHotel
            }
        case 'SEARCH_GUESTS_HOTEL':
            return {
                ...state,
                guestsSearchHotel: action.payload.guestsSearchHotel,
            }
        case 'SELECTED_HOTEL':
            return {
                ...state,
                hotel: [...state.hotel, action.payload.hotel]
            }
        case 'SEARCH_OFFER_HOTEL':
            return {
                ...state,
                offersHotel: action.payload.offersHotel,
            }
        case 'SEARCH_SELECTED_HOTEL':
            return {
                ...state,
                hotel: action.payload.hotel,
            }
        case 'SEARCH_SELECTED_ROOM_HOTEL':
            return {
                ...state,
                room: action.payload.room,
            }
        case 'SET_TRAVELLERS_HOTEL':
            return {
                ...state,
                travellers: action.payload.travellers,
            }
        case 'SET_HOTELS_LIST':
            return {
                ...state,
                hotelsList: action.payload.hotelsList,
            }
        case 'CLEAR_SEARCH':
            return {
                ...state,
                locationSearchHotel: {},
                datesSearchHotel: initial_dates_hotel,
                guestsSearchHotel: initial_guests,
                offersHotel: 'points',
                hotelsList: [],
                hotel: {},
                room: {},
                travellers: data_travellers
            }
        default:
            return state;
    }
}
