import Styled from 'styled-components'


export const Menu = Styled.h1`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: ${props => props.theme.primary};
  width: 100%;
`