import Styled from 'styled-components'

export const Container = Styled.div`
  .MuiSlider-thumb {
    color: #263238;
  }
  .MuiSlider-track: {
    border-radius: 0;
    color: #263238;
  }
  .MuiSlider-root {
    margin: 0;
    color: #263238;;
  }
  display: block;
  .slider-filter {
    .bol-left,
    .bol-right {
        width: 25px;
        height: 22px;
        border-radius: 50%;
        background: #FFFFFF;
        box-shadow: 4px 10px 21px rgba(0, 0, 0, 0.16);
        border: 2px solid #263238;
    }
    .bol-left {
        margin:0;
        position: relative;
        //position: absolute;
       // left: calc(90% / 15)
    }
    .bol-right {
        margin:0;
        position: relative;
        //right: 0;
       // right: calc(90% / 7.5)
    }
    .bol-left::after {
      content: "Pontos";
      position: absolute;
      top: -30px;
      left: -10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: #263238;
    }
    .bol-right::after {
        content: "Valor";
        position: absolute;
        top: -30px;
        
        right: -5px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #263238;
    }
`
