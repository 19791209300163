import api from "../../../_config/http";

export function changePickupAction(location) {
    return {
        type: 'SEARCH_PICKUP_CAR',
        payload: {
            locationPickupCar: location
        }
    }
}

export function changeDropOffAction(location) {
    return {
        type: 'SEARCH_DROP_OFF_CAR',
        payload: {
            locationDropOffCar: location
        }
    }
}

export function changeReturnSameLocalAction(boolean) {
    return {
        type: 'SEARCH_RETURN_SAME_LOCAL_CAR',
        payload: {
            returnSameLocalCar: boolean
        }
    }
}

export function changeDatesCarAction(dates) {
    return {
        type: 'SEARCH_DATES_CAR',
        payload: {
            datesSearchCar: dates
        }
    }
}

export const changeTimeCarAction = (dateTime, time) => {
    return {
        type: 'SEARCH_TIME_CAR',
        payload: {
            timeSearchCar: time,
            dateTimeSearchCar: dateTime
        }
    }
}

export const changeOfferCarAction = (data) => {
    return {
        type: 'SEARCH_OFFER_CAR',
        payload: {
            offersCar: data
        }
    }
}

export const changeSelectedCarAction = (data) => {
    return {
        type: 'SELECTED_CAR',
        payload: {
            car: data
        }
    }
}

export const setConductorCarAction = (data) => {
    return {
        type: 'SET_CONDUCTOR_CAR',
        payload: {
            conductor: data
        }
    }
}

export const clearSearchCar = () => {
    return {
        type: 'CLEAR_SEARCH'
    }
}

export const checkoutCarAction = (props) => {
    const { customerId, car, cardSelected, totalPayment, installmentSelected, conductor } = props

    const customer = localStorage.getItem("customer_envision_v1")

    const [day, month, year] = conductor.birthDate.split('/');
    const date = year + '-' + month + '-' + day;

    let document = conductor.document.replaceAll('.', '').replace('-', '')

    const formatConductor = {
        "firstName": conductor.firstName,
        "middleName": "",
        "lastName": conductor.lastName,
        "birthDate": date,
        "gender": conductor.gender,
        "documents": [
            {
                "number": document,
                "documenType": "cpf"
            }
        ]
    }

    const checkout = {
        "customerId": customerId,
        "payment": [
            {
                "type": "points",
                "amount": totalPayment.points
            },
            {
                "type": "CREDIT",
                "gateway_card_key": cardSelected.gateway_card_key,
                "amount": totalPayment.cash,
                "installments": installmentSelected,
                "save": false
            }
        ],
        "contactInformations": JSON.parse(customer),
        "fromChannel": "ll",
        "travelReason": {
            "description": "Descricao da viagem"
        },
        "travellers": [ formatConductor ],
        "cars": car
    }

    const data = JSON.stringify(checkout)

    return  api.post('/cars/order', data)
}