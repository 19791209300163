export function AirfareReducer (state, action){
    switch (action.type) {
        case 'SEARCH_LOCATION_DEPARTURE_AIRFARE':
            return {
                ...state,
                locationDeparture: action.payload.locationDeparture
            }
        case 'SEARCH_LOCATION_ARRIVAL_AIRFARE':
            return {
                ...state,
                locationArrival: action.payload.locationArrival
            }
        case 'SEARCH_ONLY_DEPARTURE_AIRFARE':
            return {
                ...state,
                onlyDeparture: action.payload.onlyDeparture,
                datesSearchAirfare: {
                    startDate: null,
                    endDate: null,
                    days: 0
                }
            }
        case 'SEARCH_DATES_AIRFARE':
            return {
                ...state,
                datesSearchAirfare: action.payload.datesSearchAirfare
            }
        case 'SEARCH_OFFER_AIRFARE':
            return {
                ...state,
                offersAirfare: action.payload.offersAirfare,
            }
        case 'SEARCH_PASSENGERS_AIRFARE':
            return {
                ...state,
                numberOfPassengers: action.payload.numberOfPassengers,
            }
        case 'SEARCH_CLASS_AIRFARE':
            return {
                ...state,
                classAirfare: action.payload.classAirfare,
            }
        default:
            return state;
    }
}
