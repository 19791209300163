import React from "react";

import Checkbox from "components/CheckboxCustom";

import { useApp } from "contexts/AppContext";
import {changeDropOffAction, changeReturnSameLocalAction} from "contexts/reducers/cars/actions";

export const SameLocal = () => {

    const { carState, carDispatch } = useApp()
    const { returnSameLocalCar } = carState

    const handleChange = (e) => {
        const { checked } = e.target
        carDispatch(changeReturnSameLocalAction(checked))
        carDispatch(changeDropOffAction({}))
    }

    return <Checkbox
                onChange={(e) => handleChange(e)}
                checked={returnSameLocalCar}
                label="Devolução no mesmo local"
            />
}
