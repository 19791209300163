import React  from "react";

import ReactDOM from "react-dom/client";
import { BrowserRouter } from 'react-router-dom';
import App from './App';

import "swiper/css/bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>

);
