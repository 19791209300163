import { LoaderSearch } from './styles'
import imageLoader from 'assets/img/loader.gif'
import Lottie from "lottie-react";
import loadingAnimation from "../../../assets/loadingAnimation.json";

export default function Loader() {
  return (
        <LoaderSearch>
            <div id="preloader-active">
                <div className="preloader">
                    <div className="preloader-inner">
                        <div className="preloader-circle"/>
                    </div>
                </div>
            </div>
        </LoaderSearch>
  );
}

export function LoaderSearchImage() {

    if (localStorage.getItem('channel') === 'genial'){
        return (
            <div id="preloader-active">
                <div className="preloader">
                    <div className="preloader-inner">
                        <Lottie style={{
                            height: '64px',
                            width: '64px',
                            position: 'fixed',
                            top: "50%",
                            left: "50%",
                            marginTop: "-32px",
                            marginLeft: "-32px"
                        }} animationData={loadingAnimation} loop={true}/>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <LoaderSearch>
                <div id="preloader-active" >

                    <div className="preloader">
                        <div className="preloader-inner">
                            <div className="preloader-img">
                                <img src={imageLoader} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </LoaderSearch>
        )
    }
}

