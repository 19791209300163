import React, {useEffect, useState} from "react";

import format from "date-fns/format";
import moment from "date-fns/format";
import { IoCalendarOutline } from "react-icons/io5";

import { ModalApp } from "components/Modal";
import DateRangePicker from "components/SearchComponents/DateRangePicker";
import Button from "components/Button";

import { useApp } from "contexts/AppContext";
import { changeDatesHotelAction } from "contexts/reducers/hotels/actions";
import {isChannel} from "../../../utils";
import {useGenialApp} from "../../../contexts/GenialAppContext";

export default function ReserveDate() {

    const { hotelState, hotelDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { datesSearchHotel } = hotelState

    const [open, setOpen] = useState(false);
    const [dates, setDates] = useState(datesSearchHotel)
    const [focused, setFocused] = useState([0,0])
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        if(!dates.startDate && !dates.endDate) {
            setDisabled(true)
        }
    },[])

    const handleModal = () => {
        setOpen(!open);
    };

    const validateDates = (dates) => {
        let start = dates.startDate != null && moment(dates.startDate, "dd/MM/yyyy");
        let end = dates.endDate != null && moment(dates.endDate, "dd/MM/yyyy");

        return start !== end;
    }

    const handleChangeDates = (data) => {
        const date = data[0];

        const formatData = {
            startDate: date?.startDate,
            endDate: date?.endDate,
        }

        const validate = validateDates(formatData)

        if (!validate) {
            formatData.endDate = null
            setDates(formatData)
            setFocused([0,1])
            setDisabled(true)
            return false
        }

        setFocused([0,0])
        setDisabled(false)

        setDates(formatData)
    }

    const handleConfirm = () => {
        hotelDispatch(changeDatesHotelAction([dates]))
        handleModal()
    }

    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="input-group">
                        <span className="input-group-custom">
                            <IoCalendarOutline/>
                        </span>
                        <input
                            className="form-control form-control-custom border-custom"
                            value={ dates?.startDate ? format(dates?.startDate, "dd/MM/yyyy") : '' }
                            readOnly
                            onClick={handleModal}
                            placeholder="Check-in"
                        />
                    </div>
                </div>
                <div className="col">
                    <div className="input-group">
                        <span className="input-group-custom">
                            <IoCalendarOutline/>
                        </span>
                        <input
                            className="form-control form-control-custom border-custom"
                            value={ dates?.endDate ? format(dates?.endDate, "dd/MM/yyyy") : '' }
                            readOnly
                            onClick={handleModal}
                            placeholder="Check-out"
                        />
                    </div>
                </div>
            </div>

            <ModalApp show={open} handleModal={handleModal} label="Selecione as datas para o seu hotel">
                <div className="row shadow-sm mb-1 mt-4">
                    <div className="col-6">
                        <div className={`d-flex flex-column gap-1 py-2 ${focused[1] === 0 && 'border-focused'}`}>
                            <span className="text-xs fw-bold" style={{color: "#263238"}}>Check-in</span>
                            <span className="text-sm">
                                { dates?.startDate
                                    ? format(dates?.startDate, "dd/MM/yyyy") : '-'}
                            </span>
                        </div>
                    </div>
                    <div className="col-6">
                        <div className={`d-flex flex-column gap-1 py-2 ${focused[1] === 1 && 'border-focused'}`}>
                            <span className="text-xs fw-bold" style={{color: "#263238"}}>Check-out</span>
                            <span className="text-sm">{dates?.endDate
                                ? format(dates?.endDate, "dd/MM/yyyy") : '-' }</span>
                        </div>
                    </div>
                </div>

                <DateRangePicker
                    onChange={handleChangeDates}
                    dates={datesSearchHotel}
                />

                <div className="mt-2 mb-4">
                    <Button
                        type="button"
                        width="200px"
                        onClick={handleConfirm}
                        disabled={disabled}
                    >
                        Confirmar
                    </Button>
                </div>
            </ModalApp>
        </>
    )
}
