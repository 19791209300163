import Styled from 'styled-components'

export const ContentNotFound = Styled.div`
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: -webkit-center;
    
    h5 {
        margin-bottom: 2;
    }
    
    p {
        width: 240px;
    }
    
    .label {
        color: #5F5F5F;
    }


`
