import React, { useState } from "react";
import { Information, InfromationIcon, InfromationIconOpen, ContainerRegulation, DivParagraph } from './styles'
import { ModalApp } from "../Modal";
import Button from "../Button";

export default function DriverRegulation() {
    const [open, setOpen] = useState(false);

    const handleModal = () => {
        setOpen(!open);
    };
    return (
        <>
            <Information>
                <InfromationIcon />
                <p>Revise as condições necessárias para a locação do seu carro <button type="button" onClick={handleModal}>Saber mais.</button></p>
            </Information>
            <ModalApp show={open} handleModal={handleModal}>
                <ContainerRegulation>

                    <DivParagraph>
                        <InfromationIconOpen />
                        <div>
                            <p className="att">Atenção</p>
                            <p className="location">Algumas informações importantes para sua locação</p>
                        </div>
                    </DivParagraph>
                    <p className="text">
                        Ter idade mínima de 21 anos
                    </p>

                    <p className="text">
                        Apresentar Carteira de Habilitação (CNH) definitiva,<br/> original e válida
                    </p>

                    <p className="text">
                        Apresentar cartão de crédito em nome do locatário <br/>ou responsável financeiro
                    </p>

                    <p className="text">
                        O responsável financeiro deverá estar presente no <br/>momento da abertura do contrato com o CPF e RG <br/>original
                    </p>
                    <p className="text">
                        Para resgate de locações de veículos em território<br/> internacional, o condutor deve possuir habilitação <br/>válida há mais de dois anos
                    </p>
                    <Button width="200px" onClick={handleModal}>Continuar</Button>
                </ContainerRegulation>
            </ModalApp>
        </>
    )
}
