import Styled from 'styled-components'

export const SearchContent = Styled.div`

`

export const Nav = Styled.nav`
  background-color:  rgba(102, 99, 98, 0.96);;
  width: 100%;
  height: 100%;
  left: 0px;
  position: fixed;
  z-index: 9999;
  display: flex;
  margin-top: 25px;
  margin-bottom: 10px;
  .container{
    ul{
      padding: 0 !important;
      
    
    }
  }
    > svg {

      color: white;
      width: 30px;
      height: 30px;
      margin-top: 32px;
      margin-left: 32px;
      cursor: pointer;
    }
  
  `
export const Content = Styled.div`

`
export const Menu = Styled.p`

`

export const AccordionContent = Styled.div`
 
`

export const Li = Styled.li`

`

export const A = Styled.a`

`

export const Regulation = Styled.li`

`
export const ContentMenu = Styled.span`

`

export const Title = Styled.p`

`

export const SubTitle = Styled.p`

`

