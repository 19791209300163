import React from 'react';

import {IoMdCar} from "react-icons/io";
import {FaMapMarkerAlt} from "react-icons/fa";
import {Navigate} from "react-router-dom";

import {useApp} from "../../contexts/AppContext";
import {FormConductor} from "./components/FormConductor";

import {
    Container,
    Resume,
    Details,
    Text,
} from './styles'
import format from "date-fns/format";

export default function CarResume() {

    const { carState } = useApp()
    const { car, datesSearchCar } = carState

    if(!car) return <Navigate to="/cars-search-result" />

    return (
        <Container>
            <Resume>Resumo da Reserva</Resume>
            <Details>
                <IoMdCar size={24}/> Detalhes do Carro
            </Details>
            <Text>
                <p>Locadora</p>
                <p>{car?.company?.name}</p>
            </Text>

            <Text>
                <p>Categoria</p>
                <p>{car?.car?.category}</p>
            </Text>

            <Text>
                <p>Adicionais</p>
                <p>
                    {car.car?.hasAirConditioning && <span>AC</span>}
                    {car.car?.transmission && <span>{car.car?.transmission}</span>}
                    {car.car?.numberOfDoors && <span>{car.car?.numberOfDoors} Portas</span>}
                    {car.car?.numberOfBags && <span>{car.car?.numberOfBags} malas</span>}
                </p>
            </Text>

            <Text>
                <p>Inclusos</p>
                <p>
                    {
                        car.fareSummary?.carRateIncludedItems.map((item, index) =>
                            <span key={index}>{item.description}</span>
                        )
                    }
                </p>
            </Text>

            <Details>
                <FaMapMarkerAlt size="20"/> Retirada
            </Details>

            <Text>
                <p>Data</p>
                <p>{datesSearchCar.startDate && format(datesSearchCar.startDate, "dd/MM/yyyy HH:mm")}</p>
            </Text>

            <Text>
                <p>Local</p>
                <p>
                    {car?.pickupLocation?.company?.name} <br/>
                    {car?.pickupLocation?.address?.street}
                </p>
            </Text>

            <Details>
                <FaMapMarkerAlt size="20"/>
                Devolução
            </Details>

            <Text>
                <p>Data </p>
                <p>{datesSearchCar.endDate && format(datesSearchCar.endDate, "dd/MM/yyyy HH:mm")}</p>
            </Text>

            <Text>
                <p>Local</p>
                <p>
                    {car?.dropoffLocation?.company?.name} <br/>
                    {car?.dropoffLocation?.address?.street}
                </p>
            </Text>

            <FormConductor />

        </Container>
    )
}
