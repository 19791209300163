import React, { useState} from "react";

import {ContainerContentFilter} from "./styles";
import {FaFilter} from "react-icons/fa";
import Button from 'components/Button'
import {ModalApp} from "components/Modal";
import Checkbox from "components/CheckboxCustom"
import {category_car, transmission_car, air_conditioning_car} from 'utils'

export default function FilterResult({filter, order, applyFilter, handleApplyOrder}) {
    const [open, setOpen] = useState(false);
    const [price, setPrice] = useState(filter.filterParameters.price.max)
    const [categories, setCategories] = useState(filter?.filterParameters?.category ?? [])
    const [transmission, setTransmission] = useState(filter?.filterParameters?.transmission)
    const [airConditioning, setAirConditioning] = useState(filter?.filterParameters?.hasAirConditioning)

    const doFilter = () => {
        const filter = {
            "filterParameters": {
                "category": categories,
                "price": {
                    "min": 20,
                    "max": price
                },
                "transmission": transmission,
                "hasAirConditioning": airConditioning
            }
        }

        applyFilter(filter)
        setOpen(false)
    }

    const handleModal = () => {
        setOpen(!open)
    };

    const handleChangeOrder = (order) => {
        handleApplyOrder(order)
    }

    const handleCategories = (e) => {
        const { value, checked } = e.target;

        const cats = [...categories]

        if (checked) {
            setCategories( state => [...state, value]);
        } else {
            setCategories( cats.filter((e) => e !== value));
        }
    }

    const handleTransmission = (e) => {
        const { value } = e.target;

        setTransmission(value)
    }

    const handleAirConditioning = (e) => {
        const { checked } = e.target;
        setAirConditioning(checked)
    }

    return (
        <div className="row align-items-end">
            <div className="col-7 d-flex gap-2">
                <Button outline={true} width="85px" onClick={handleModal}>
                    <FaFilter/> Filtrar
                </Button>
            </div>

            <div className="col-5">
                <select
                    className="form-select form-select-custom border-custom"
                    defaultValue={order}
                    onChange={(e) => handleChangeOrder(e.target.value)}
                >
                    <option value="">Ordenar por</option>
                    <option value="az">A-Z</option>
                    <option value="za">Z-A</option>
                    <option value="asc_price">Menor preço</option>
                    <option value="desc_price">Maior Preço</option>
                </select>
            </div>

            <ModalApp show={open} handleModal={handleModal}>
                <ContainerContentFilter className="px-4">

                    <span className="text-center fw-bolder fs-17">Filtrar os resultado</span>

                    <p className="label">Valor</p>

                    <div className="input-group">
                        <label className="d-flex flex-grow-1 label-custom">Valor de diária de até</label>
                        <input
                            className="form-control form-control-custom border-custom text-end"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                           // onBlur={(e) => changePrice(value)}
                        />
                    </div>
                    {/*<div className="d-flex mt-1">*/}
                    {/*    <div className="text-start label-custom border-0">Valor total de</div>*/}
                    {/*    <div className="text-end d-block label-custom border-0">8000</div>*/}
                    {/*</div>*/}

                    <p className="label">Categoria</p>

                    <div className="d-grid gap-2 text-start">
                        { category_car.map((item, index) =>
                            <div key={index}>
                                <Checkbox
                                    checked={categories?.find(category => category === item.value)}
                                    name="categories"
                                    label={item.label}
                                    value={item.value}
                                    onChange={(e) => handleCategories(e)}
                                />
                            </div>
                        )}
                    </div>

                    <p className="label">Transmissão</p>

                    <div className="d-grid gap-2 text-start">
                        {transmission_car.map((item, index) =>
                            <div key={index}>
                                <Checkbox
                                    name="transmission"
                                    type="radio"
                                    checked={transmission === item.value}
                                    label={item.label}
                                    value={item.value}
                                    onChange={(e) => handleTransmission(e)}
                                />
                            </div>
                        )}
                    </div>

                    <p className="label">Adicionais</p>

                    <div className="d-grid gap-2 text-start">
                        {air_conditioning_car.map((item, index) =>
                            <div key={index}>
                                <Checkbox
                                    checked={airConditioning === true}
                                    name="air_conditioning"
                                    label={item.label}
                                    onChange={(e) => handleAirConditioning(e)}
                                />
                            </div>
                        )}
                    </div>


                    <div className="my-4">
                        <Button width="200px" onClick={() => doFilter()}>Filtrar</Button>
                    </div>

                </ContainerContentFilter>
            </ModalApp>
        </div>
    );
}
