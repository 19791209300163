import React from "react";
import ReactPaginate from "react-paginate";
import {Pagination} from './styles';

const perPaginas = 3;

const PaginationTable = ({
                             total,
                             handlePageClick,
                             currentPage
                         }) => {

    const totalPages = total > 0 ? Math.ceil(total / perPaginas) : 0;

    return (
        <div className="row mb-5">
            <div className="col-12">

                <Pagination>
                    <ReactPaginate
                        previousLabel="Anterior"
                        nextLabel="Próximo"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        pageCount={totalPages}
                        marginPagesDisplayed={1}
                        pageRangeDisplayed={3}
                        forcePage={currentPage - 1}
                        onPageChange={handlePageClick}
                        containerClassName="pagination"
                        activeClassName="active"
                    />
                </Pagination>
            </div>
        </div>
    )
}

export default PaginationTable