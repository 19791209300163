import React, {useState} from "react";

import {DivCarousel} from "./styles";
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Navigation, Pagination, Thumbs} from "swiper";
import {Cidade, ContainerCarousel, Img} from "./styles";
import ImgTemporaria from "../../assets/img/Rectangle 41.svg";

export const CarouselHotels = ({images}) => {

    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    return(
        <DivCarousel>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                    "height": 300
                }}
                loop={true}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper2"
            >
                {images.map((item,index) =>
                    <SwiperSlide key={index}>
                        <img src={item.url} />
                    </SwiperSlide>
                )}

            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="mySwiper"
            >
                {images.map((item,index) =>
                    <SwiperSlide key={index} style={{height: "60px"}}>
                        <img src={item.url} />
                    </SwiperSlide>
                )}

            </Swiper>
        </DivCarousel>
    )
}

export const CarouselDefault = ({slidesPerView, images}) => {
    return (
        <ContainerCarousel>
            <Swiper
                slidesPerView={slidesPerView}
                breakpoints={{
                    568: { slidesPerView: 4 },
                }}
                spaceBetween={30}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
            >
                {images.map((item, index) => {
                    const { id, label } = item;
                    return (
                        <SwiperSlide key={index}>
                            <Img src={ImgTemporaria} alt={label} />
                            <Cidade>Rio De Janeiro</Cidade>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
        </ContainerCarousel>
    )
}
