
import banner from '../../assets/img/Banner.png'
import bannerGenial from '../../assets/img/Banner-Genial.png'

export const carouselApi = [
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
]

export const carouselGenialApi = [
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19",
        "label": "BannerGenial",
        "image": bannerGenial,
        "location": ""
    }
]
