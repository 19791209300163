import Button from "components/Button";
import {AiFillExclamationCircle} from "react-icons/ai";
import {Error} from './styles'
import {useNavigate} from "react-router-dom";

export const Error404 = () => {

    const navigate = useNavigate()

    return (
        <Error>
            <div className="container pt-5">
                <div className="row mt-5">
                    <div className="col align-items-center text-center">
                        <AiFillExclamationCircle />
                        <p className="color-custom fw-bold fs-2 mt-2 mb-0">Oops! 404</p>
                        <p className="color-disabled mb-4">Página não encontrada</p>
                        <Button width="200px" onClick={() => navigate('/')}>Voltar ao início</Button>
                    </div>
                </div>
            </div>
        </Error>
    )
}
