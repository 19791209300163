import React from 'react'

import Menu from '../Menu'
import Logo from '../../assets/img/logo-ll-loyalty.png'
import Accounts from '../Account'
import { Container, Aside, LogoDiv } from './styles'
import {useNavigate} from "react-router-dom";

export default function Header() {

  const navigate = useNavigate();

  return (
    <Container>
      <div>
        <Aside>
          <Menu />
          <LogoDiv>
            <span onClick={() => navigate('/')}>
              <img src={Logo} alt="img logo" />
            </span>
            <p>1.500<span>Pts</span></p>
          </LogoDiv>
          <Accounts />
        </Aside>
      </div>
    </Container>
  )
}
