export const addAction = (type) => {
    return {
        type: 'INCREASE',
        payload: {
            type
        }
    }
}

export const removeAction = (type) => {
    return {
        type: 'DECREASE',
        payload: {
            type
        }
    }
}

export const increaseAgeAction = (position) => {
    return {
        type: 'INCREASE_AGE',
        payload: {
            position
        }
    }
}

export const decreaseAgeAction = (position) => {
    return {
        type: 'DECREASE_AGE',
        payload: {
            position
        }
    }
}