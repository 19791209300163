import React, {useState} from 'react'
import {GrMenu} from 'react-icons/gr'
import {Container} from './styles'
import ContentMenu from "../BurgerMenuContents/MenuContainer";

export default function Menu() {
    const [open, setOpen] = useState(false);

    const toogleMenu = () => {
        setOpen(!open);
    }

    return (
        <Container>
            <GrMenu
            onClick={toogleMenu}
            size={20}
            />
            { open === true &&
                <ContentMenu />
            }
        </Container>
    )
}
