import * as Yup from "yup";
import {cpf} from "cpf-cnpj-validator";
import {setConductorCarAction} from "../../../../contexts/reducers/cars/actions";
import {
    CheckboxContainer,
    CheckboxDiv,
    DivAlign,
    InformationIcon,
    LabelCheckbox,
    TextRegulation,
    TitleParagraph
} from "./styles";
import {Field, Form, Formik} from "formik";
import InputMask from "react-input-mask";
import React from "react";
import {Checkbox} from "@mui/material";
import {useApp} from "../../../../contexts/AppContext";
import {useNavigate} from "react-router-dom";

import Button from 'components/Button'


export const FormConductor = () => {

    const { carState, carDispatch } = useApp()
    const { car } = carState

    const navigate = useNavigate()

    const SignupSchema = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        document: Yup.string().required().test(function (value) {
            if (value) {
                return cpf.isValid(value);
            } else {
                return false
            }
        }),
        birthDate: Yup.string().required().min(10),
        gender: Yup.string().required(),
        phone: Yup.string().required(),
        checkTerms: Yup.string().required()
    });


    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    firstName: "",
                    lastName: "",
                    document: "",
                    birthDate: "",
                    gender: "",
                    phone: "",
                    checkTerms: "off"
                }}
                validationSchema={SignupSchema}
                onSubmit={async (values) => {
                    await new Promise((r) => setTimeout(r, 500));
                    carDispatch(setConductorCarAction(values))
                    navigate("/checkout/car");
                }}
            >
                {({values, setFieldValue, errors, touched, setFieldTouched}) => (
                    <Form>
                        <>
                            <label>Dados do Condutor</label>
                            <div  className="row gy-3 mt-1 mb-3 ">
                                <div className="col-12">
                                    <Field
                                        name="firstName"
                                        placeholder="Primeiro nome"
                                        className={`form-control form-control-custom border-custom shadow-none
                                        ${ errors.firstName ? 'is-invalid' : ''}`}
                                    />
                                </div>
                                <div className="col-12">
                                    <Field
                                        name="lastName"
                                        placeholder="Último sobrenome"
                                        className={`form-control form-control-custom border-custom shadow-none
                                        ${ errors.lastName ? 'is-invalid' : ''}`}
                                    />
                                </div>
                                <div className="col-6">
                                    <InputMask
                                        mask="999.999.999-99"
                                        onChange={(e) => setFieldValue(`document`, e.target.value)}
                                        onClick={() => setFieldTouched(`document`, true)}
                                        maskChar={null}
                                        placeholder="CPF"
                                        value={values.document}
                                        inputMode="numeric"
                                        className={`form-control form-control-custom border-custom shadow-none
                                        ${ errors.document ? 'is-invalid' : ''}`}
                                    />
                                </div>

                                <div className="col-6">
                                    <InputMask
                                        mask="99/99/9999"
                                        onChange={(e) => setFieldValue(`birthDate`, e.target.value)}
                                        onClick={() => setFieldTouched(`birthDate`, true)}
                                        className={`form-control form-control-custom border-custom shadow-none
                                        ${ errors.birthDate ? 'is-invalid' : ''}`}
                                        maskChar={null}
                                        placeholder="Data de nascimento"
                                        inputMode="numeric"
                                        value={values.birthDate}
                                    />
                                </div>

                                <div className="col-4">
                                    <Field
                                        as="select"
                                        name="typeNumber"
                                        className="form-select form-select-custom border-custom"
                                    >
                                        <option value="fixo">Fixo</option>
                                        <option value="celular">Celular</option>
                                    </Field>
                                </div>
                                <div className="col-8">
                                    <InputMask
                                        mask={values.typeNumber === 'celular' ? "(99) 99999-9999" : "(99) 9999-9999"}
                                        onChange={(e) => setFieldValue(`phone`, e.target.value)}
                                        onClick={() => setFieldTouched(`phone`, true)}
                                        className={`form-control form-control-custom border-custom shadow-none
                                        ${ errors.phone ? 'is-invalid' : ''}`}
                                        placeholder="Telefone"
                                        value={values.phone}
                                    />
                                </div>
                            </div>
                        </>

                        <CheckboxContainer>
                            <Field
                                type="radio"
                                name="gender"
                                id="male"
                                value="male"
                                className={errors.document ? 'border-danger' : ''}
                            />
                            <label htmlFor="male">Masculino</label>
                        </CheckboxContainer>
                        <CheckboxContainer>
                            <Field
                                type="radio"
                                name="gender"
                                id="female"
                                value="female"
                                className={errors.document ? 'border-danger' : ''}
                            />
                            <label htmlFor="female">Feminino</label>
                        </CheckboxContainer>

                        <div>
                            <TitleParagraph>
                                Veja abaixo os requisitos necessários para a locação do veículo. A veracidade destas
                                informações
                                será conferida no momento da retirada do automóvel.
                            </TitleParagraph>
                            <DivAlign>
                                <div>
                                    <InformationIcon/>
                                </div>
                                <TextRegulation>
                                    <strong>Para resgate de locações de veículos em território nacional, o condutor deve
                                        possuir
                                        habilitação válida há mais de dois anos.</strong> É obrigatório a apresentação
                                    da Carteira Nacional de Habilitação
                                    (CNH) válida, no nome do condutor. A locação é permitida apenas para motoristas
                                    maiores de 21 (vinte e um)
                                    anos. Poderá ser necessário que o condutor apresente cartão de crédito com limite
                                    mínimo exigido pela locadora,
                                    para fins de pré-autorização de débito em garantia a eventuais danos, multas ou
                                    avarias decorrentes da utilização do
                                    veículo. A locadora será a única responsável por tal pré-autorização. Eventuais
                                    problemas relacionados à pré-autorização
                                    deverão ser solucionados diretamente com a locadora.
                                </TextRegulation>
                            </DivAlign>
                            <DivAlign>
                                <div>
                                    <InformationIcon/>
                                </div>
                                <TextRegulation>
                                    <strong>Para resgate de locações de veículos em território internacional, o condutor
                                        deve possuir habilitação válida há mais de dois anos.</strong>
                                    Além da carteira de habilitação deverá ser apresentado passaporte válido, ou outro
                                    documento de identificação aceito de acordo com a
                                    legislação local. Para locações na Europa, é exigida a apresentação da Permissão
                                    Internacional para Dirigir (PID). A idade mínima para locação
                                    de carro no exterior varia de 21 (vinte e um) a 25 (vinte e cinco) anos de idade. As
                                    locadoras poderão cobrar taxas adicionais para
                                    condutores menores de 25 (vinte e cinco) anos de idade. Referidas taxas deverão ser
                                    pagas diretamente às locadoras. Poderá ser
                                    necessário que o condutor apresente cartão de crédito com limite mínimo exigido pela
                                    locadora, para fins de pré-autorização de
                                    débito em garantia a eventuais danos, multas ou avarias decorrentes da utilização do
                                    veículo. A locadora será a única responsável
                                    por tal pré-autorização. Eventuais problemas
                                    relacionados à pré-autorização deverão ser solucionados diretamente com a locadora.
                                </TextRegulation>
                            </DivAlign>
                            <DivAlign>
                                <div>
                                    <InformationIcon/>
                                </div>
                                <TextRegulation>
                                    <strong>Equipamentos adicionais (cadeirinha de bebê, GPS, entre outros) e Taxas One
                                        Way (local de retirada do veículo diferente do local
                                        de devolução) podem ser solicitados às locadoras e serão pagos no local da
                                        retirada do veículo em moeda – nacional ou
                                        estrangeira, conforme o caso.</strong> O veículo alugado deve ser devolvido com
                                    a mesma quantidade de combustível recebida quando
                                    retirado da locadora (exceto quando esse item estiver incluído no plano e constar em
                                    seu voucher).
                                </TextRegulation>
                            </DivAlign>
                            <DivAlign>
                                <div>
                                    <InformationIcon/>
                                </div>
                                <TextRegulation>
                                    <strong> A empresa locadora, o tipo e categoria do veículo e o nome do condutor
                                        constarão no voucher que será enviado por e-mail pelo
                                        Programa e deverá ser apresentado no respectivo estabelecimento comercial no
                                        momento da retirada do veículo.</strong>
                                </TextRegulation>
                            </DivAlign>
                        </div>

                        <CheckboxDiv>
                            <LabelCheckbox>
                                <div>
                                    <Checkbox
                                        color="default"
                                        required
                                        onChange={(e) => setFieldValue('checkTerms', e.target.value)}
                                    />

                                </div>
                                <div className="paragraph">
                                    Li e estou ciente das condições e termos para a locação e contratação de serviços
                                    adicionais da locadora.
                                </div>
                            </LabelCheckbox>
                        </CheckboxDiv>

                        <div className="mt-4 mb-5">
                            <Button type="submit">Continuar</Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    )
}