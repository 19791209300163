export default function TimePicker({ onChange, time }) {
    return (
        <div className="d-grid gap-4 px-4 mb-4">
            <div className="row">
                <div className="col-12">
                    <div className="input-group">
                        <label className="label-custom">Horário de retirada</label>
                        <span className="input-group-custom">
                            <input
                                type="time"
                                className="form-control form-control-custom text-sm"
                                value={time.startDate}
                                onChange={(e) => onChange(e.target.value, 'startDate')}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className="input-group">
                        <label className="label-custom">Horário de entrega</label>
                        <span className="input-group-custom">
                            <input
                                type="time"
                                className="form-control form-control-custom text-sm"
                                value={time.endDate}
                                onChange={(e) => onChange(e.target.value, 'endDate')}
                            />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
