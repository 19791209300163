import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_DEFAULT_ENDPOINT,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_TOKEN}`
  },
  params: {
    fromChannel: `genial`
  }
})


api.interceptors.response.use(
  (response) => {
    const tokenExpiration = localStorage.getItem('token_expiration')
    const dateExpiration = tokenExpiration.substring(0, 4) + '-' + tokenExpiration.substring(4, 6) + '-' + tokenExpiration.substring(6, 8) + 'T' + tokenExpiration.substring(8, 10) + ':' + tokenExpiration.substring(10, 12) + ':' + tokenExpiration.substring(12, 14)
    const expirationDate = new Date(dateExpiration);
    const dateNow = new Date();
    if (expirationDate < dateNow) {      
      window.location  = 'llgenial://fail?reason=jwt_invalido'    
    };
    return response;
  },

  async function (error) {
    return Promise.reject(error);
  }
);






export default api
