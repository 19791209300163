/* eslint-disable react/prop-types */
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import api from "../_services/api";
import apiGenial from '../_config/http';

const UserContext = createContext('')

export const UserProvider = ({children}) => {
    const [userData, setUserData] = useState([])
    const [user, setUser] = useState([])
    const [addressesData, setAddressesData] = useState([])
    const [addressShipping, setAddressShipping] = useState([])
    const [updateAddresses, setUpdateAddresses] = useState()
    const [updateUser, setUpdateUser] = useState()

    function updateUserData() {
        const clientUserData = localStorage.getItem('customer')
        const user = JSON.parse(clientUserData)

        setUserData(user)
        setUser(user)
        return user
    }

    const changeAddress = useCallback((address) => {
        setAddressShipping(address)
    }, []);


    async function getAddresses(uuid)  {
        try {
            const response = await api.get('/api/list-address?customerUuid=' + uuid)
            return response.data?.data
        } catch (e) {
            console.log('Error get addresses', e)
            return false
        }
    }
    async function getBalance(cpf)  {
        try {
            const response = await apiGenial.get('/balance?partner_customer_id=' + cpf)
            return response.data?.status !== 'fail' && response.data?.balance
        } catch (e) {
            console.log('Error get balance', e)
            return false
        }
    }

    useEffect(() => {
        const refreshUser = async () => {
            
            let clientUserData = localStorage.getItem('customer')
            clientUserData = JSON.parse(clientUserData)
            const balance = await getBalance(user.cpf)
            if (balance) {
                clientUserData.data.balance = balance
            }
            localStorage.setItem('customer', JSON.stringify(clientUserData));
            updateUserData();
        }

        if (updateUser) {
            refreshUser()
        }

    }, [updateUser])

    return (
        <UserContext.Provider value={{
                userData,
                user,
                updateUserData,
                changeAddress,
                addressShipping,
                addressesData,

                setUpdateAddresses,
                setUpdateUser
        }}>
            {children}
        </UserContext.Provider>
    )
}

export function useUserData() {
    return useContext(UserContext)
}

UserProvider.propTypes = {
    children: PropTypes.node
}
