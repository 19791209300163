import React from 'react'

import { IoSearch } from "react-icons/io5";
import format from "date-fns/format";

import { Collapse } from "components/Collapse";
import Button from "components/Button";
import SearchCar from "components/SearchCar";
import { DivContentCar } from "./styles";

import { useApp } from "contexts/AppContext";
import {isChannel} from "../../utils";
import {useGenialApp} from "../../contexts/GenialAppContext";

export default function SearchBarCars({info, handleInfo, searchCars}) {

    const { carState } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { locationPickupCar, locationDropOffCar, datesSearchCar } = carState

    return (
        <div className="container-search-bar">
            {info && (
                <>
                    <DivContentCar>
                        <p className="label">Retirada</p>

                        <div className="info">
                            <div className="date d-flex gap-2">
                                <span>{ datesSearchCar.startDate ? format(datesSearchCar.startDate, 'dd/MM/yy') : ''}</span>
                                <span>{ datesSearchCar.startDate && format(datesSearchCar.startDate, 'HH:mm') }</span>
                            </div>
                            <span className="local">{locationPickupCar?.id ?? '-'}</span>
                        </div>

                        <p className="label">Devolução</p>

                        <div className="info">
                            <div className="date d-flex gap-2">
                                <span>{ datesSearchCar.endDate ? format(datesSearchCar.endDate, 'dd/MM/yy') : '' } </span>
                                <span>{ datesSearchCar.endDate && format(datesSearchCar.endDate, 'HH:mm') }</span>
                            </div>
                            <span className="local">{locationDropOffCar?.id ?? '-'}</span>
                        </div>
                    </DivContentCar>

                    <div className="mb-3">
                        <Button width="200px" onClick={handleInfo} data-bs-toggle="collapse"
                                data-bs-target="#filter-collapse" aria-expanded="false" aria-controls="filter-collapse">
                            <IoSearch/> Refazer Busca
                        </Button>
                    </div>
                </>
            )}

            <Collapse handleClick={handleInfo}>
                <p className="fw-bolder pt-3">Locação de Carros</p>

                    <SearchCar />

                    <div className="text-center mb-4">
                        <Button onClick={() => searchCars(true)} width="200px">
                            <IoSearch/> Refazer Busca
                        </Button>
                    </div>
            </Collapse>
        </div>
    )
}
