import {Line, Points, Resume, Title, ValuePoints, Values} from "./styles";
import {formatCurrency} from "../../utils/functions";
import React, {useEffect,useState} from "react";
import {useApp} from "../../contexts/AppContext";

import Promise from 'axios';

import {getPaymentConditions} from "../../_services/payment.service";
import {isChannel} from "../../utils";
import {useGenialApp} from "../../contexts/GenialAppContext";

export const ResumeCheckoutCar = () => {

    const { carState } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { car, datesSearchCar, offersCar } = carState

    const [pointsAmount, setPointsAmount] = useState( {
        taxesAmount: 0,
        totalAmount: 0
    })

    useEffect(() => {
        const request = async () => {
            if (offersCar === 'points') {
                let bodyTax = {
                    "amount": car.fareSummary.taxesAmount
                }

                let bodyAmount = {
                    "amount": car.fareSummary.originalDisplayTotalAmount
                }

                const [
                    tax,
                    amount
                ] = await Promise.all([
                    getPaymentConditions(bodyTax),
                    getPaymentConditions(bodyAmount)
                ])

                setPointsAmount({
                    taxesAmount: tax.ENVISION,
                    totalAmount: amount.ENVISION
                })
            }
        }

        request()
    }, [])


    return (
        <Resume>
            <p className="title-resume">Resumo da Reserva</p>
            <p className="title">Locação de carro</p>
            <p className="value">{ car?.car?.category}</p>
            <p className="title">Díarias</p>

            <Points>
                <span>{ datesSearchCar?.days } diária(s)</span>
                <div className="dots"/>
                <div className="daily">
                    <ValuePoints className="value">
                        {offersCar === 'cash' && formatCurrency(car.amount.BRL)}
                        {offersCar === 'points' && parseFloat(car.amount.ENVISION).toLocaleString() + ' pontos'}
                    </ValuePoints>
                </div>
            </Points>

            <Points>
                <Title>Taxas</Title>
                <div className="dots"/>
                <ValuePoints className="value">
                    {offersCar === 'cash' && formatCurrency(car.fareSummary.taxesAmount)}
                    {offersCar === 'points' && parseFloat(pointsAmount.taxesAmount).toLocaleString() + ' pontos'}

                </ValuePoints>
            </Points>

            <Line/>

            <Values>
                <span>Valor total</span>
                <div className="dots"/>
                <div className="number-value">
                    {offersCar === 'cash' && formatCurrency(car.fareSummary.originalDisplayTotalAmount)}
                    {offersCar === 'points' && parseFloat(pointsAmount.totalAmount).toLocaleString() + ' pontos'}
                </div>
            </Values>
        </Resume>
    )
}

export const ResumeCheckoutHotel = () => {

    const { hotelState } =  !isChannel('genial') ? useApp() : useGenialApp()

    const { datesSearchHotel, room, offersHotel } = hotelState

    return (
        <Resume>
            <p className="title-resume">Resumo da Reserva</p>
            <p className="title">Quarto</p>
            <p className="value">{ room?.name }</p>
            <p className="title">Díarias</p>

            <Points>
                <span>{datesSearchHotel?.days} diária(s)</span>
                <div className="dots"/>
                <div className="daily">
                    <ValuePoints className="value">
                        {offersHotel === 'cash' && formatCurrency(room.amount.BRL)}
                        {offersHotel === 'points' && parseFloat(room.amount.ENVISION).toLocaleString() + ' pontos'}
                    </ValuePoints>
                    <span>(por diária)</span>
                </div>
            </Points>

            <Points>
                <Title>Taxas</Title>
                <div className="dots"/>
                <ValuePoints className="value">
                    {offersHotel === 'cash' && 'R$ 0,00'}
                    {offersHotel === 'points' && 0 + ' pontos'}
                </ValuePoints>
            </Points>

            <Line/>

            <Values>
                <span>Valor total</span>
                <div className="dots"/>
                <div className="number-value">
                    {offersHotel === 'cash' && formatCurrency(room.amount.BRL * datesSearchHotel?.days)}
                    {offersHotel === 'points' && parseFloat(room.amount.ENVISION * datesSearchHotel?.days).toLocaleString()+ ' pontos'}
                </div>
            </Values>
        </Resume>
    )
}