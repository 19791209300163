import Styled from 'styled-components'

export const Container = Styled.header`
background-color: #fff;
width: 100%;
height: 90px;

`
export const Aside = Styled.div`
padding: 20px 0px;
display: flex; 
justify-content: space-around;
position: fixed;
z-index: 1000;
background-color: #FFFFFF;
width: 100%;
height: 90px;
gap: 200px;
border-bottom: 1px solid #C6C6C5; 
@media(max-width: 800px) {
    gap: 40px;
}
`



export const LogoDiv = Styled.div`
text-align: center;
margin-top: 5px;

img{
    width: 100px;
    height: x;
    cursor: pointer;
}

p{
    margin-top: 5px;
    color: black;
    font-weight: bold;
    span{
        font-family: 'PT Sans', sans-serif;
        line-height: 16px;
        font-size: 12px;
        color: #757575;
    }
}
`
