import Styled from 'styled-components'

export const HeaderDiv = Styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background-color: #333;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

export const HeaderMenu = Styled.div`
`

export const ContainerMenuHamburguer = Styled.aside`
 display: inline-block;
 svg{
        height: 25px;
        width: 25px;
        color: black;
        cursor: pointer;
    }

  button{
      border: none;
      background: none;
      
  .btn-left{
    height: 25px;
    width: 25px;
  }
}
`

export const Space = Styled.div`
`

export const Container = Styled.div`
    align-items: center;
`
export const Logo = Styled.img`
`

export const Balance = Styled.div`
`
export const H5 = Styled.h6`
`

export const Block = Styled.div`
`

export const P = Styled.p`
`