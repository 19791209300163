import Styled from 'styled-components'

export const ContainerContentFilter = Styled.div`
display: flex;
flex-direction: column;
background-color: #fff;

.label {
    font-size: 14px;
    color: #474646;
    font-weight: bold;
    text-align: start;
    margin-top: 20px;
}
`
