import React, { createContext, useContext, useState } from 'react'

import PropTypes from 'prop-types'
import {useNavigate} from "react-router";

const OpenContext = createContext({})

export const OpenProvider = ({ children }) => {
    const [open, setOpen] = useState(false)
    const [category, setCategory] = useState()
    const [brands, setBrands] = useState()
    const [categorySeeAll, setCategorySeeAll] = useState()
    const [brandsSeeAll, setBrandsSeeAll] = useState()
    const [openSearch, setOpenSearch] = useState(false)


    function returnDivMenu() {
        setCategory(false)
        setBrands(false)
        setCategorySeeAll(false)
        setBrandsSeeAll(false)
    }

    function OpenButton() {
        category && setCategory(false)
        brands && setBrands(false)
        setOpen(!open)
        setOpenSearch(false)
    }

    function closeAllMenu() {
        category && setCategory(false)
        brands && setBrands(false)
        open && setOpen(false)
        openSearch && setOpenSearch(false)
    }

    function forceCloseAllMenu() {
        setCategory(false)
        setBrands(false)
        setOpen(false)
        setOpenSearch(false)
    }

    function OpenSearch() {
        setOpenSearch(!openSearch)
        setOpen(false)
    }
    const navigate = useNavigate()

    function openCart() {
        setOpen(false)
        navigate('/loja/orderresume/')
    }
    return (
        <OpenContext.Provider value={{ open, openSearch, OpenButton, OpenSearch, openCart, category, setCategory, brands, setBrands,
        returnDivMenu, categorySeeAll, brandsSeeAll, setCategorySeeAll, setBrandsSeeAll, closeAllMenu,forceCloseAllMenu  }}>
            {children}
        </OpenContext.Provider>
    )
}

export const useOpen = () => {
    const context = useContext(OpenContext)
    if (!context) {
        throw new Error('useOpen must be used within a OpenProvider')
    }
    return context
}

OpenProvider.propTypes = {
    children: PropTypes.node
}
