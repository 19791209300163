import React, {useState, useEffect} from 'react';

import {Container} from 'react-bootstrap'

import Information from 'components/DriverRegulation'
import SearchBarCars from "components/SearchBarCars"
import FilterCar from 'components/FilterCar'
import { BoxCar } from "components/BoxCar";
import { NotFoundResultsCars } from "components/NotFoundResults";
import Pagination from "components/Pagination";
import { LoaderSearchImage } from "components/Loader";

import { initial_filter_cars } from 'utils'

import { getCars } from "_services/cars.service";
import { useApp } from "contexts/AppContext";


export default function CarSearchResult() {
    const [info, setInfo] = useState(true)
    const [loading, setLoading] = useState(false)
    const [listCars, setListCars] = useState([])
    const [totalItems, seTotalItems] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);

    const [filter, setFilter] = useState(initial_filter_cars)
    const [order, setOrder] = useState('')


    const { carState } = useApp()
    const { locationPickupCar, locationDropOffCar, datesSearchCar } = carState

    async function searchCars(firstPage = false) {
        if (locationPickupCar?.id) {

            setLoading(true)

            let page = currentPage
            if (firstPage) {
                page = 1
                setCurrentPage(1)
            }

            const response = await getCars(
                page,
                locationPickupCar,
                locationDropOffCar,
                datesSearchCar,
                filter,
                order
            );

            if (!response.status) {
                setListCars([])
                setInfo(true)
                setLoading(false)
                return
            }

            if (response.status) {
                console.log(response)
                setListCars(response?.data?.quotes)
                seTotalItems(50)
            }
            setLoading(false)
            setInfo(true)
        }
    }

    useEffect(() => {
        const Request = async () => {
            searchCars()
        }
        Request();
    }, [currentPage, filter, order])

    const applyFilter = (filters) => {
          setFilter(filters)
    }

    const handleApplyOrder = (orderFilter) => {
        setOrder(orderFilter)
    }

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
    }

    function handleInfo() {  setInfo(!info) }


    if (loading) return <LoaderSearchImage />

    return (
        <>
            <SearchBarCars
                info={info}
                handleInfo={handleInfo}
                searchCars={searchCars}
            />

            <Information/>

            <Container>
                <div className="filter-content-cars">
                    <FilterCar
                        filter={filter}
                        order={order}
                        applyFilter={applyFilter}
                        handleApplyOrder={handleApplyOrder}
                    />
                </div>

                { !listCars.length
                    && <NotFoundResultsCars handleInfo={handleInfo} />
                }

                <div className="result-content">
                    { listCars?.map((item, index) =>
                        <div key={index}>
                            <BoxCar data={item} index={index} />
                        </div>
                    )}
                </div>

                { listCars.length > 0 &&
                    <Pagination
                        currentPage={currentPage}
                        total={totalItems}
                        handlePageClick={handlePageClick}
                    />
                }

            </Container>
        </>
    )
}
