import React, {useEffect,useState} from "react";

import {IoLocationSharp} from "react-icons/io5";
import {getLocation} from "../../../_services/location.service";
import useDebounce from "../../../contexts/useDebounce";

export default function SearchLocation({placeholder, disabled = false, location, onChange}) {

    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [displayValue, setDisplayValue] = useState('')
    const [open, setOpen] = useState(false)
    const [list, setList] = useState([])

    const debouncedChange = useDebounce(setSearch, 500, setLoading);

    const handleChange = (e) => {
        setLoading(true)
        e.target.value.length >= 3 && setOpen(true)
        setDisplayValue(e.target.value);
        debouncedChange(e.target.value);
    }

    useEffect(() => {
        const searchLocation = async () => {
            setLoading(true)
            const res = await getLocation(search, 5);
            setList(res.data.locations)
        }

        if (search.length >= 3) {
            searchLocation().then(() => {
                setLoading(false)
            })
        }

    }, [search])

    const changeLocation = (params) => {
        onChange(params)
        setDisplayValue(params.name)
        setOpen(false)
    }

    return (
        <div className="dropdown">
            <div className="input-group">
                    <span className="input-group-custom">
                        <IoLocationSharp/>
                    </span>
                <input
                    className="form-control form-control-custom border-custom"
                    value={displayValue}
                    defaultValue={location?.name}
                    onChange={handleChange}
                    onClick={() => search.length >= 3 && setOpen(!open)}
                    placeholder={placeholder}
                    disabled={disabled}
                />
            </div>

            <ul className={`dropdown-menu w-100 ${open && 'show'}`}>
                {
                    loading ? <li className="text-center">Buscando...</li> :

                        !list.length
                            ? <li className="text-center">Nenhum local encontrado</li>

                            : list?.map(item =>
                                <li key={item.id}>
                                    <a className="dropdown-item text-wrap" href="#"
                                       onClick={() => changeLocation({id: item.id, name: item.name})}>{item.name}</a>
                                </li>
                            )
                }
            </ul>
        </div>
    )
}
