import { useRef } from 'react';

export default function useDebounce(fn, delay, loading) {
    const timeoutRef = useRef(null);

    function debouncedFn(...args) {
        window.clearTimeout(timeoutRef.current);
        timeoutRef.current = window.setTimeout(() => {
            fn(...args);
            loading(false)
        }, delay);
    }

    return debouncedFn;
}
