import React from "react";

import {IoIosArrowUp} from "react-icons/io";
import Button from "../Button";

export const Collapse = ({children, handleClick = {}}) => {
    return (
        <div className="collapse" id="filter-collapse">
            <div className="card card-body border-0 py-0 my-0">
                <div className="px-4 py-2 text-start">

                    { children }

                    <div className="text-center mb-3">
                        <Button  outline={true} width="50px" data-bs-toggle="collapse" onClick={handleClick}
                                 data-bs-target="#filter-collapse" aria-expanded="false" aria-controls="filter-collapse">
                            <IoIosArrowUp />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
