import React from 'react'

import { AiOutlineUser } from 'react-icons/ai'
import { Container } from './styles'

export default function Accounts () {
  return (
    <Container>
      <AiOutlineUser />
    </Container>
  )
}
