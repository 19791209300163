/* eslint-disable react/prop-types */
import React from 'react'
import { IconContext } from "react-icons";
import { IoMdClose } from 'react-icons/io'
import { HiOutlineMenuAlt3 } from 'react-icons/hi'
import { useNavigate } from 'react-router'
import { HiChevronLeft } from 'react-icons/hi'

import { ContainerMenuHamburguer, Block, HeaderDiv } from './styles'
import Logo from '../../../assets/logo-white@2x.png'
import Search from '../Search'

import Cart from '../Cart'
import { useOpen } from '../hooks/Open'
import { useUserData } from '../hooks/UserContext'
import { numberWithCommas } from '../lib/FormatCurrency'

function HeaderGenial(props) {
    const {category, brands, closeAllMenu} = useOpen()
    const navigate = useNavigate()
    const {user} = useUserData()

    const redirectHome = () => {
        closeAllMenu();
        parent.window.location = `${process.env.REACT_APP_URL_GENIAL_LOJA}`
    }
    const userBalance = localStorage.getItem('customer_balance')
    const userName = localStorage.getItem('customer_name')
    const userLastName = localStorage.getItem('customer_lastname')
    return (
        <>
            <HeaderDiv>
                <IconContext.Provider value={{ color: "#24283E"}}>
                    <div className="bg-white header-genial border-bottom" id="headerHome">
                        <Block className="d-flex align-items-center justify-content-between border-bottom p-3">
                            <img src={Logo} alt="Logo" className="logo-genial" onClick={() => redirectHome()} />
                            <div className="d-flex justify-content-end align-items-center">
                                <Search onClick={props.search}/>
                                <Cart/>
                                <ContainerMenuHamburguer>
                                    <button type="button" className=''>


                                        {props.open ?
                                            props.open && category || props.open && brands
                                                ? <HiChevronLeft className="return" onClick={props.returnDiv}/>
                                                : <IoMdClose onClick={props.sidebar}/>
                                            : <HiOutlineMenuAlt3 onClick={props.sidebar}/>
                                        }
                                    </button>
                                </ContainerMenuHamburguer>
                            </div>
                        </Block>

                        <Block className="d-flex justify-content-between align-items-center p-3 px-4">
                            <span className="color-text-dark font-genial-400 text-mg">Olá, {`${userName + ' ' + userLastName}`}</span>
                            <span className="color-genial-tertiary font-genial-700 text-mg">{userBalance && numberWithCommas(userBalance)} pts</span>
                        </Block>
                    </div>
                </IconContext.Provider>
            </HeaderDiv>
        </>
    )
}

export default HeaderGenial
