import Styled from 'styled-components'

export const Container = Styled.nav`
margin-bottom: 40px;
`

export const ContainerHotel = Styled.div`
height: 100%;
border-bottom: 2px solid rgba(117, 117, 117, 0.32);
padding-bottom: 25px;
margin-bottom: 25px;

.hotel {
    padding: 3px;
    background-color: #6C6C6C;
    text-align: center;
    color: white;
    margin-bottom: 0;
    font-size: 13px;
}

.image {
    width: 80%;
}

`

export const HotelImages = Styled.img`
width: 100%;
height: 100%;
object-fit: cover;  
`


export const InformationHotel = Styled.div`

width: 100%;

p{
    margin-bottom: 0;
}

`

export const ContainerPrice = Styled.div`
background: #F0F0F0;
padding: 3px 16px;


.diaria{
    color: #263238;
    font-weight: 400;
    font-size: 13px;
}

.price{
    color: #263238;
    font-weight: 700;
    font-size: 26px;

    span{
        font-size: 19px;
    }
}

.taxa{
    color: rgba(38, 50, 56, 0.5);
    font-weight: 400;
    font-size: 12px;
    margin-top: -5px;
    margin-bottom: 0px
}
`

export const ContentHotel = Styled.div`
margin-left: 15px;

.title-hotel {
    margin: 5px 0;
    color: #40545E;
    font-weight: 700;
    font-size: 17px;
    text-transform: lowercase;
    line-height: 16px;
}

.title-hotel{ 
    text-transform: capitalize;
}

.stars{
    position: relative;
    z-index: -1;

    .css-iljtu2-MuiRating-root{
       color: #40545E;
    }
}

.info {
     background-color:#E6E6E6;
     width: auto;
     padding: 0 10px;
     color: #474646;
     font-weight: 400;
     font-size: 13px;
     display: inline-flex;   
}

.mapa{
    color: #6C6C6C;
    font-weight: 400;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 15px;

    svg {
        width: 20px;
        height: 20px;
        margin-right: 2px;
    }
}
`

export const Line = Styled.div`
border-bottom: 2px solid #C6C6C5; 
margin-top: 30px;
`
