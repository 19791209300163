import React from 'react'

import Header from '../Header'
import ContentMenu from '../../BurgerMenuContents/MenuContainer'
import Search from '../Search/Content'
import { useOpen } from '../hooks/Open'

export default function HeaderMenu() {
  const { OpenButton, open, OpenSearch, openSearch, returnDivMenu} = useOpen();

  return (
    <>
      <Header search={OpenSearch} sidebar={OpenButton} open={open}  returnDiv={returnDivMenu}/>

      {open && (
        <ContentMenu />
      )}

      {openSearch && (
        <Search closeSearch={OpenSearch} />
      )}
    </>
  )
}
