import Styled from 'styled-components'

export const Search = Styled.div`

button{
    background: none;
    border: none;
}

svg{
    height: 25px;
    width: 25px;
    cursor: pointer;
}
`
