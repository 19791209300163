export function GuestsReducer(state, action) {

    const type = action.payload.type
    let position = action.payload.position

    switch (action.type) {
        case 'INCREASE': {
            if (type === 'children') {
                return {
                    ...state,
                    [type]: state[type] + 1,
                    childrenInputAge: [...state.childrenInputAge, { age: 0 }]
                }
            }

            return {
                ...state,
                [type]: state[type] + 1,
            }
        }
        case 'DECREASE': {
            if(type === 'adults') {
                if (state[type] === 1) {
                    return state
                }
            }

            if (state[type] === 0) {
                return state
            }
            if (type === 'children') {
                return {
                    ...state,
                    [type]: state[type] - 1,
                    childrenInputAge: state.childrenInputAge.slice(0, -1)
                }
            }

            return {
                ...state,
                [type]: state[type] - 1,
            }
        }
        case 'INCREASE_AGE': {
            return  {
                ...state,
                childrenInputAge: state.childrenInputAge.map((children,index) => {
                    if (index === position) {
                        return { ...children, age: children.age +1 }
                    } else {
                        return { ...children }
                    }
                }),
            }
        }
        case 'DECREASE_AGE': {

            if (state.childrenInputAge[position].age === 0) {
                return state
            }

            return  {
                ...state,
                childrenInputAge: state.childrenInputAge.map((children,index) => {
                    if (index === position) {
                        return { ...children, age: children.age -1 }
                    } else {
                        return { ...children }
                    }
                }),
            }
        }
        default:
            return state;
    }
}
