import React from "react";

import {MdLocationPin} from "react-icons/md";
import {useNavigate} from "react-router-dom";
import {BoxPlace, BoxPlaceSm} from "./styles";
import {IoClose} from "react-icons/io5";
import {FixedRating} from "components/Rating";
import {formatCurrency} from "utils/functions";
import Button from "components/Button";
import {useApp} from "../../contexts/AppContext";
import {changeSelectedHotelAction, changeSelectedRoomHotelAction} from "../../contexts/reducers/hotels/actions";
import {isChannel} from "../../utils";
import {useGenialApp} from "../../contexts/GenialAppContext";

export const InfoWindow = (props) => {
    const { place, setPlaceSelected,  } = props;

    const { hotelState, hotelDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { offersHotel } = hotelState

    const price = place.rooms[0]?.amount?.BRL
    const points = place.rooms[0]?.amount?.ENVISION

    const externalProperties = JSON.parse(place?.rooms[0]?.externalProperties)

    const navigate = useNavigate()

    const handleSelectHotel = () => {
        hotelDispatch(changeSelectedHotelAction(place))
        hotelDispatch(changeSelectedRoomHotelAction({}))

        navigate(`/hotel-page`)
    }

    return (
        <BoxPlace>
            <div className="box-content">
                <div className="image">
                    {  place?.images[0]?.url &&
                        <img src={ place.images[0].url} alt="" />
                    }
                </div>

                <div>
                    <span className="icon-close" role="button"  onClick={() => setPlaceSelected({})}>
                        <IoClose />
                    </span>

                    <p className="place-name">{ place.name }</p>

                    <div className="rating">
                        <FixedRating value={ place.stars } size="small" />
                    </div>

                    <div className="box-info">
                        <p>
                            { externalProperties["Refundable"] === "True"
                                ?  'Com Reembolso' : 'Sem Reembolso'
                            }
                        </p>
                    </div>

                    <div className="box-price">
                        <p>diárias a partir de</p>
                        <p>
                            { offersHotel === "cash" &&  formatCurrency(price) }
                            { offersHotel === "points" &&  <> {parseFloat(points).toLocaleString()} <span>Pts</span> </> }
                        </p>
                        <p>taxas inclusas</p>
                    </div>

                    <div className="box-button">
                        <Button width="175px" onClick={() => handleSelectHotel()}>Continuar</Button>
                    </div>
                </div>
            </div>
        </BoxPlace>
    );
};

export const InfoWindowName = (props) => {
    const { place, setPlaceSelected } = props;

    return (
        <BoxPlaceSm>
            <div className="box-content">
                <span className="icon-close fs-5" role="button"  onClick={() => setPlaceSelected({})}>
                    <IoClose />
                </span>

                <p className="place-name">{ place.name }</p>

                <div className="rating">
                    <FixedRating value={ place.stars } size="small" />
                </div>
            </div>
        </BoxPlaceSm>
    );
};


export const Marker = ({ show, place, setPlaceSelected, id, boxHotel }) => {
    const markerStyle = {
        height: 40,
        width: 40,
        color: `${show ? '#409939' : '#333333'}`,
        cursor: 'pointer',
        zIndex: 10,
    };

    if(boxHotel === 'name') {
       return (
           <>
                <MdLocationPin style={markerStyle} />
                { show && <InfoWindowName place={place} setPlaceSelected={setPlaceSelected} id={id} />}
           </>
       );
    }

    return (
        <>
            <MdLocationPin style={markerStyle} />
            {show && boxHotel && <InfoWindow place={place} setPlaceSelected={setPlaceSelected} id={id} />}
        </>
    );
};
