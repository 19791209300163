import { createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import api from '../components/Genial/_services/api'
import axios from 'axios';

let cancelToken;

export const getCart = createAsyncThunk('/getCart', async ({cpf,cep}) => {
    const response = await api.get('/api/cart?cep='+cep+'&partner_customer_id=' + cpf)
    return response.data
});

export const removeItemCart = createAsyncThunk('/removeItemCart', async ({ idItem,cpf, cep }, thunkAPI) => {
  return await api({
      method: 'DELETE',
      url: '/api/cart/' + idItem + '/remove?partner_customer_id=' + cpf,
      headers: {'Content-Type': 'application/json'}
  }).then(res => {
    thunkAPI.dispatch(getCart({cpf,cep}))
  })
});

export const changeQuantityItemCart = createAsyncThunk(
  '/changeQuantityItemCart',
  async ({ jsonCart,cpf, cep }, {dispatch, rejectWithValue }) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }
      cancelToken = axios.CancelToken.source();
      const response = await api({
        method: 'PUT',
        url: '/api/cart',
        data: JSON.stringify(jsonCart, null, 2),
        headers: {'Content-Type': 'application/json'},
        cancelToken: cancelToken.token,
      })
      dispatch(getCart({cpf,cep}))
      return true
    } catch (error) {
      if (error.code === "ERR_CANCELED") {
        return
      }
      return rejectWithValue({"message":error.message,"name":"error","code":"ERR_COMMON","status":1});
    }
  }
);

const assembleSlider = (items) =>{
  let pontos_variavel = 0;
  let dinheiro_variavel = 0;
  let pontos_fixo = 0;
  let dinheiro_fixo = 0;
  items.map((i) => {
    const { item,quantity } = i
    const dinheiro = item.price?.BRL?.final || 0
    const pontos =  item.price?.GENIAL?.final || 0
    if(dinheiro > 0 && pontos > 0) {
      pontos_variavel+= pontos * quantity
      dinheiro_variavel+= dinheiro * quantity
    }
    if(dinheiro === 0 && pontos > 0) {
      pontos_fixo+= pontos * quantity
    }
    if(dinheiro > 0 && pontos === 0) {
      dinheiro_fixo+= dinheiro * quantity
    }
  })
  return {
    'pontos_variavel': pontos_variavel,
    'dinheiro_variavel': dinheiro_variavel,
    'pontos_fixo': pontos_fixo,
    'dinheiro_fixo': dinheiro_fixo
  }
}

const initialState = {
  cart: {},
  loading : true,
  payment_method: 'dinheiro',
  slider : {
    'pontos_variavel': 0,
    'dinheiro_variavel': 0,
    'pontos_fixo': 0,
    'dinheiro_fixo': 0
  }
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addCart: (state, action) => {
      state.cart = action.payload;
    },
    delCart: (state, action) => {
      state.cart = {};
    },
    setLoading: (state, action) => {
      state.loading = action.payload.value;
    },
    setPaymentMethod: (state, action) => {
      state.payment_method = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCart.fulfilled, (state, action) => {
        state.cart = action.payload
        state.slider = assembleSlider(state.cart.items)
        state.loading = false
    })
    builder.addCase(getCart.rejected, (state, action) => {
      state.loading = false;
  })
  }
});

export const { addCart, delCart,setLoading,setPaymentMethod,setShippingAmount } = cartSlice.actions;

export const selectCart = state => state.cartSlice.cart
export const totalItens = state => state.cartSlice.cart?.items?.length || null
export const getSlider = state => state.cartSlice.slider

export const getSliderHotel = {
  'pontos_variavel': 0,
  'dinheiro_variavel': 0,
  'pontos_fixo': 0,
  'dinheiro_fixo': 0
}


export default cartSlice.reducer;