import { configureStore } from "@reduxjs/toolkit";

import changeSlice from "./Redux/changeSlice";
import filterSlice from "./Redux/filterSlice";
import cartSlice from "./Redux/cartSlice";

const rootReducer = {
  changeSlice,
  filterSlice,
  cartSlice
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV!== 'production',
});

export default store;