import React, {useEffect, useState} from "react";

import {FiChevronDown} from "react-icons/fi"
import Checkbox from "components/CheckboxCustom";
import {ModalApp} from "components/Modal";
import Button from "components/Button";
import {payments} from 'utils'
import api from "../../../_config/http";

export default function RoomQuantity({offers, changeOffers}) {

    const [open, setOpen] = useState(false);
    const [offersData, setOffersData] = useState(offers)
    const [typeOffer, setTypeOffer] = useState();

    const handleModal = () => {
        changeOffers(offersData)
        setOpen(!open);
    };

    const handleChange = (event) => {
        setOffersData(event)
    };

    useEffect(() => {
        api.get('/configuration/moeda_viagem/ll')
            .then(response => {
                var tipoMoeda = response.data.modules[0].value;

                if (tipoMoeda === '0') {
                    setOffersData('cash');
                    changeOffers('cash')
                }

                setTypeOffer(tipoMoeda)
            })
    }, []);

    return (
        <>
            {typeOffer === '2' &&
                <>
                    <div className="input-group" id="offersCtrl" onClick={handleModal}>
                        <label className="d-flex flex-grow-1 label-custom">Ver ofertas em</label>
                        <span className="input-group-custom">
                    <span className="color-custom text-xs">
                        {offers === 'points' && 'Pontos'}
                        {offers === 'cash' && 'Dinheiro'}
                        <FiChevronDown className="svg-sm"/></span>
                </span>
                    </div>
                    <ModalApp show={open} handleModal={handleModal} label="Selecione as formas de pagamento">

                        <div className="px-4 pt-5 pb-4 d-grid gap-4 ">
                            {payments.map((item) =>
                                    <div className="input-group" key={item.name}>
                                        <label className="label-custom">{item.label}</label>
                                        <span className="input-group-custom">
                               <Checkbox
                                   type="radio"
                                   value={item.name}
                                   name="offers"
                                   checked={offers === item.name}
                                   onChange={(e) => handleChange(e.target.value)}
                               />
                            </span>
                                    </div>
                            )}

                            <div className="mt-3">
                                <Button type="button" width="200px" onClick={handleModal}>Continuar</Button>
                            </div>
                        </div>
                    </ModalApp>
                </>
            }
        </>
    );
}
