import React, {useState} from "react";

import {FaUserAlt} from 'react-icons/fa'
import {HiMinusSm, HiPlusSm} from "react-icons/hi";
import {ModalApp} from "components/Modal";
import Button from "components/Button";
import Radio from "components/CheckboxCustom";
import {classes, initial_passengers as passengers} from 'utils'

export default function Passengers() {
    // const { pass } = useSearch()
    // const {
    //     passengers,
    //     changePassengers,
    // } = pass

    const [open, setOpen] = useState(false)

    const handleModal = () => {
        setOpen(!open);
    };

    // const totalPeople = passengers.adults + passengers.children;
    //
    // const handleChange = (e) => {
    //     const newPassengers = {...passengers}
    //     newPassengers['class'] = e.target.name;
    //     newPassengers['class_label'] = e.target.value;
    //     changePassengers(newPassengers)
    // };

    const handlePeople = (type, action) => {
        // let newPassenger = {...passengers}
        //
        // if (action === 'add') {
        //     //aumenta qtde adultos e criancas
        //     newPassenger[type] = newPassenger[type] + 1;
        //     changePassengers(newPassenger);
        //     return
        // }
        //
        // //diminui qtde adultos e criancas
        // if (newPassenger[type] === 0) return
        //
        // newPassenger[type] = newPassenger[type] - 1;
        // changePassengers(newPassenger);
    }

    const InputControl = ({type, placeholder, value}) => {
        return (
            <div className="input-group">
                <label className="label-custom">{placeholder}</label>
                <span className="input-group-custom">
                    <span className="px-1" role="button"
                          onClick={() => handlePeople(type, 'remove')}>
                        <HiMinusSm/>
                    </span>
                </span>
                <span className="input-group-custom px-0">{value}</span>
                <span className="input-group-custom">
                   <span className="px-1" onClick={() => handlePeople(type, 'add')}>
                        <HiPlusSm/>
                    </span>
                </span>
            </div>
        )
    }

    return (
        <div className="content-custom">
            <div className="input-group" onClick={handleModal}>
                <label className="d-flex flex-grow-1 label-custom">Passageiros/Classe</label>
                <span className="input-group-custom svg-custom">
                    0 {/*{totalPeople} <FaUserAlt/>*/}
                    <span className="divider"/>
                    {/*{passengers.class_label}*/}
                </span>
            </div>

            <ModalApp show={open} handleModal={handleModal}>
                <div className="p-4 bg-content">
                    <div className="row">
                        <div className="col d-grid gap-4">
                            <span className="label-modal">
                                Passageiros
                            </span>

                            <div className="text-start">
                                <InputControl placeholder="Adultos" type="adults" value={passengers.adults}/>
                                <span className="text-xs">A partir de 12 anos</span>
                            </div>

                            <div className="text-start">
                                <InputControl placeholder="Crianças" type="children" value={passengers.children} />
                                <span className="text-xs">2 a 11 anos</span>
                            </div>

                            <div className="text-start">
                                <InputControl placeholder="Bebê" type="baby" value={passengers.baby} />
                                <span className="text-xs">0 a 23 meses</span>
                            </div>

                            <div className="d-grid gap-2">
                                <span className="label-modal mb-3">
                                    Classe
                                </span>
                                {classes.map((item, index) =>
                                    <div className="" key={item.name}>
                                        <div className="input-group">
                                            <span className="input-group-custom border-0">
                                               <Radio
                                                   name={item.name}
                                                   type="radio"
                                                   value={item.label}
                                                   // checked={passengers.class === item.name}
                                                   // onChange={handleChange}
                                               />
                                            </span>
                                            <label className="label-custom color-custom border-0">{item.label}</label>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="mt-4">
                    <Button type="button" width="200px" onClick={handleModal}>Continuar</Button>
                </div>
            </ModalApp>
        </div>
    );
}
