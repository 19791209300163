import api from '_config/http'

export async function getPaymentConditions(body) {
    return await  api.post('/payment-conditions', JSON.stringify(body))
        .then(response => {
            if (response.status === 200) {
                return response.data.data;
            }
        })
        .catch(error => console.log(error))
}
