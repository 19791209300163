import React, {useEffect, useState} from "react";
import CardData from "../../../components/CardData";
import Button from "../../../components/Button";
import {useNavigate, useParams} from 'react-router-dom'
import { formatCurrency } from "../../../utils/functions";

import {
    Container,
    Label,
    ContainerPointsSlider,
    ContentDiv
} from "./styles";

import api from "../../../_config/http";
import {ResumeCheckoutCar, ResumeCheckoutHotel} from "../../../components/ResumeCheckout";
import {useApp} from "../../../contexts/AppContext";
import {useAuth} from "../../../contexts/AuthProvider";
import {checkoutHotelAction, clearSearch} from "../../../contexts/reducers/hotels/actions";
import {checkoutCarAction, clearSearchCar} from "../../../contexts/reducers/cars/actions";
import {LoaderSearchImage} from "../../../components/Loader";
import {SliderCheckout} from "./components/SliderCheckout";
import {getInstallments} from "../../../_services/installments.service";
import {ModalApp} from "../../../components/Modal";
import {isChannel} from "../../../utils";
import {useGenialApp} from "../../../contexts/GenialAppContext";



export default function Checkout() {

    const [loading, setLoading] = useState(false)
    const [rules, setRules] = useState(false);
    const [installments, setInstallments] = useState([])

    const [installmentSelected, setInstallmentSelected] = useState('')
    const [cardSelected, setCardSelected] = useState({})

    const [cards, setCards] = useState([])

    const { product } = useParams()
    const navigate = useNavigate()

    const { hotelState, hotelDispatch, carState, carDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { customerId } = useAuth()

    const { hotel, room, travellers, datesSearchHotel, offersHotel } = hotelState;

    const { car, conductor, offersCar  } = carState;

    const isPoints = product === 'hotel' && offersHotel === 'points'
        ? true : product === 'car' && offersCar === 'points' && true

    const [totalPayment, setTotalPayment] = useState({
        points: 0,
        cash: product === 'car'
            ? car.fareSummary.originalDisplayTotalAmount
            : (room.amount.BRL * datesSearchHotel.days)
    })

    const [open, setOpen] = useState(false);

    const totalPurchase = product === 'car'
        ? car.fareSummary.originalDisplayTotalAmount / 100
        : (room.amount.BRL * datesSearchHotel.days)

    useEffect(() => {
        loadCardsCustomer()
    },[])

    function changeTotal(values) {
        setTotalPayment(values)
        cardSelected?.last_four && loadInstallments(cardSelected, values.cash)
    }

    const loadCardsCustomer = async () => {
         api.get(`/customers/${customerId}/cards`)
            .then(res => {
                if(res.status === 200) {
                    setCards(res.data.data)
                }
            })
            .catch(e => console.log('error card', e))
    }

    const handleModal = () => {
        setOpen(!open);
    };


    const loadInstallments = async (card, value = totalPayment.cash) => {

        value = value * 100

        if (value) {
            const result = await getInstallments(customerId, value, card?.last_four, null, 'envision')
            setInstallments(Object.values(result.data.data))
        }
    }

    async function changeCard(hash) {
        setCardSelected(cards[hash])
        await loadInstallments(cards[hash])
    }

    function handleSetLoading(boolean) {
        setLoading(boolean)
    }

    async function handleCheckout() {

        if (!rules) {
            console.log('Marcar regras e condições')
            return
        }

        if (!cardSelected) {
            console.log('Selecione o cartão')
            return
        }

        if (!installmentSelected) {
            console.log('Selecione a parcela')
            return
        }

        handleSetLoading(true)

        let checkout;

        try {
            product === 'hotel'
                ? checkout = await checkoutHotelAction({ customerId, hotel, room, cardSelected, totalPayment, installmentSelected, travellers })
                : checkout = await checkoutCarAction({ customerId, car, cardSelected, totalPayment, installmentSelected, conductor })

            if (checkout.status === 201) {

                product === 'hotel' && hotelDispatch(clearSearch())
                product === 'car' && carDispatch(clearSearchCar())

                let order_uuid = checkout.data.data.order_uuid
                order_uuid = order_uuid.substr(0, 8)

                navigate('/success-checkout/'+order_uuid)
            } else {
                navigate('/error-checkout')
            }

            handleSetLoading(false)
        } catch (e) {
            console.log('error checkout', e)
            handleSetLoading(false)
            navigate('/error-checkout')
        }
    }

    return (
        <>
            { loading && <LoaderSearchImage /> }

            <Container>
                <Label>Detalhes do Resgate</Label>
                { product === 'hotel' && <ResumeCheckoutHotel /> }
                { product === 'car' && <ResumeCheckoutCar /> }
            </Container>

            { isPoints &&
                <ContainerPointsSlider>
                    <ContentDiv>
                        <Label>Ajuste o valor de seu resgate</Label>
                        <div className="m-4 mt-5">
                            <SliderCheckout
                                changeTotal={changeTotal}
                                totalPurchase={product === 'hotel' ? room.amount.BRL : totalPurchase}
                                product={product}
                                daily={datesSearchHotel?.days}
                            />
                        </div>
                    </ContentDiv>
                </ContainerPointsSlider>
            }

            <Container>
                <div className="my-4">
                    <Label>Formas de Pagamento</Label>
                    <CardData
                        card={cardSelected}
                        changeCard={changeCard}
                        cards={cards}
                        setCards={setCards}
                    />
                </div>

                <Label>Parcelamento</Label>

                <select
                    className="form-select form-select-custom border-custom"
                    onChange={(e) => setInstallmentSelected(e.target.value)}
                >
                    <option value="">Selecione a parcela</option>
                    {installments?.map(installment => {
                        return (
                            <option key={installment.number} value={installment.number}>
                                { installment.number }x de {formatCurrency(installment.installmentPrice)}
                                { installment.interest > 0 ? ` (juros ${installment.interest}%)` : ' (sem juros)' }
                            </option>
                        )
                    })}
                </select>

                <div className="form-check mt-4">
                    <input type="checkbox" name="rules" checked={rules}
                           onChange={(e) => setRules(e.target.checked)}
                           className="form-check-input"/>

                    <label className="form-check-label">
                        Declaro que li as <span className="text-danger fw-bolder">
                        <a href="#" onClick={handleModal} className="text-danger"> regras e condições</a> e estou de acordo com os termos</span> das tarifas e serviços oferecidos.
                    </label>
                </div>

                <div className="my-3">
                    <Button onClick={handleCheckout}>Finalizar o Resgate</Button>
                </div>

                <ModalApp show={open} handleModal={handleModal} label="Regras e condições">
                    <div>
                        <div className="container" style={{ overflow:'auto', height:'35em', textAlign:'left' }}>
                            <h6 className="text-center" style={{ margin:'10px' }}>HOSPEDAGEM</h6>
                            <p>
                                1. O nome do hotel e o tipo de acomodação adquirida constam da respectiva ordem de serviço (voucher), a qual deverá ser apresentada no estabelecimento comercial no momento do check-in.
                            </p>
                            <p>
                                2. O hotel poderá oferecer diferentes acomodações: o apartamento duplo poderá ter 02 (duas) camas separadas ou 01 (uma) de casal; os apartamentos triplos ou quádruplos poderão ter (i) 02 (duas) camas de casal ou (ii) 02 (duas) camas de solteiro e 02 (duas) camas articuladas, ou, ainda, um sofá-cama.
                            </p>
                            <p>
                                3. A entrada (check-in) geralmente é autorizada a partir das 15 (quinze) horas. A entrada em horário anterior às 15 (quinze) horas estará condicionada à disponibilidade e aprovação do hotel, cabendo ao Cliente consultar o estabelecimento, não podendo o Programa ser responsabilizado em caso de impossibilidade de acomodação em horário anterior ao mencionado neste item.
                            </p>
                            <p>
                                4. A saída (check-out) normalmente é realizada às 10 (dez) horas, cabendo ao Cliente verificar com o estabelecimento a possibilidade de extensão da estadia.
                            </p>
                            <p>
                                5. Os horários de check-in e check-out podem variar dependendo do regulamento de cada hotel, cabendo ao Cliente pagar diretamente ao estabelecimento eventuais valores referentes à utilização de diárias extras.
                            </p>
                            <p>
                                6. Os hotéis poderão, a seu critério e sob sua responsabilidade, solicitar o cartão de crédito do Cliente para caucionar eventuais despesas. Caberá ao Cliente verificar junto ao hotel acerca de eventual valor a ser caucionado durante sua estadia, bem como a disponibilidade de limite no seu cartão de crédito.
                            </p>
                            <p>
                                7. Na hipótese do Cliente não realizar o check-in até às 18 (dezoito) horas, horário local do hotel, este poderá cancelar integralmente a reserva pré-existente. O apartamento reservado será devolvido ao inventário do hotel automaticamente, podendo ser revendido a critério do hotel ou fornecedor sem crédito para o Cliente. Havendo necessidade, o cliente pode informar-se sobre a chegada ao hotel após às 18h, diretamente com o estabelecimento.
                            </p>
                            <p>
                                8. Devido à política de alguns setores de reservas de hotéis, os nomes indicados na reserva somente são enviados para o estabelecimento hoteleiro na semana de seu check-in. Por isso, é possível que o quarto esteja reservado, porém o nome ainda não esteja inserido no inventário do hotel.
                            </p>
                            <p>
                                9. Se o Cliente quiser reservar mais de um quarto, deverá utilizar nomes diferentes para cada quarto, pois reservas duplicadas, ou seja, com o mesmo nome, poderão ser canceladas pelo hotel.
                            </p>
                            <p>
                                9.1 A idade mínima do hóspede titular e responsável pela reserva de cada acomodação/quarto é de 18 (dezoito) anos. O Programa não se responsabiliza por informações divergentes de idade e categoria dos hóspedes informados pelo Cliente no ato da reserva.
                            </p>
                            <p>
                                10. Em hotéis, o café da manhã e/ou as refeições, quando inclusas nas diárias, são contratados com os hotéis e/ou restaurante locais, sendo o menu fixo, com bebidas não inclusas e horários pré- estabelecidos. Se incluso, o regime de alimentação estará indicado no voucher. Crianças não pagantes que compartilham o apartamento com adultos, utilizando o plano familiar do hotel, não têm direito a café-da-manhã e/ou refeições na forma de cortesia.
                            </p>
                            <p>
                                11. As informações específicas da hospedagem, como nome, localização, categoria, fotos e serviços incluídos são fornecidas pelos próprios estabelecimentos de hospedagem, sendo de inteira responsabilidade destes. O Programa não poderá ser responsabilizado por eventuais discrepâncias havidas entre os dados informados pelo estabelecimento, imagens e a situação real e localização dos hotéis.
                            </p>
                            <p>
                                11.1 É recomendado que, em caso de dúvidas, o Cliente tome conhecimento dos comentários e avaliações de outros hóspedes para verificar se a hospedagem se ajusta às suas preferências e necessidades. O Programa não é e não deve ser interpretado como um promotor da qualidade, do nível de serviço e/ou da qualificação ou classificação de qualquer hospedagem.
                            </p>
                            <p>
                                12. No momento da saída do hotel, o Cliente deverá verificar com atenção a fatura apresentada. O voucher do Programa deverá ter sido aplicado para pagamento dos serviços nele discriminados. E, no cartão de crédito do Cliente, deverá ser debitado somente o valor de despesas extras e adicionais.
                            </p>
                            <p>
                                13. É de inteira responsabilidade do Cliente verificar a ocorrência de débitos indevidos em seu cartão de crédito ou qualquer outra situação adversa, cuja resolução deverá ser resolvida diretamente com a hospedagem e/ou com a administradora do cartão de crédito.
                            </p>
                            <p>
                                14. Para o reembolso, quando aplicável, serão observadas as regras do estabelecimento e o cancelamento da reserva deverá ser solicitado com uma antecedência mínima de 07 dias da data do check-in. 14.1 Em caso de check-in não realizado, ou alteração e cancelamento de reserva com uma antecedência menor do que a exigida pela política do estabelecimento, ou mesmo após o check-in, você estará sujeito a multa de até 100% do valor da reserva.
                            </p>
                        </div>
                    </div>

                    <div className="my-4">
                        <Button type="button" width="200px" onClick={ handleModal }>Fechar</Button>
                    </div>
                </ModalApp>
            </Container>
        </>
    );
}
