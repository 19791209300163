import api from '../../../../../_services/api';

export async function getCategories() {
  const response = await api.get('/api/product/category')
  return response.data
}

export async function getCategory(id,params) {
  const response = await api.get('/api/product/category')
  const category = response.data.filter(item => item.id === id)
  const responseCategories = await api.get(category[0].location, {params: params})
  return responseCategories.data
}