import React, {useState} from "react";

import { FaDharmachakra } from "react-icons/fa";
import { TiWaves } from "react-icons/ti";
import { IoMdCar } from "react-icons/io";
import Button from 'components/Button'
import Radio from "components/CheckboxCustom";
import {
    ContainerCar, ContainerDad, ContentTextRadioButton, DivDaughter,
    DivImagem, DivPriced, DivRadio,HiddenInformation, InformationCar, TitleSelection, VehicleInformation
} from "./styles";
import {useNavigate} from "react-router-dom";
import {useApp} from "../../contexts/AppContext";
import {changeSelectedCarAction} from "../../contexts/reducers/cars/actions";
import {formatCurrency} from "../../utils/functions";
import {isChannel} from "../../utils";
import {useGenialApp} from "../../contexts/GenialAppContext";

export const BoxCar = ({data}) => {

    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const { carState, carDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { offersCar } = carState

    const price = data.amount.BRL
    const points = data.amount.ENVISION

    function showOpen() {
        setOpen(!open);
    }

    const handleChangeCar = (car) => {
        carDispatch(changeSelectedCarAction(car))
        navigate('/car-resume')
    }

    return (
        <ContainerDad>
            <ContainerCar>
                <DivImagem>
                    <img src={ data.car?.imageUrl } alt="imagem-de-carro"/>
                </DivImagem>
                <InformationCar>
                    <p className="type-car">{ data.car?.category }</p>

                    <p className="info">
                        <TiWaves size={20} />
                        {data.car?.hasAirConditioning ? 'Ar Condicionado' : 'Sem ar condicionado' }
                    </p>

                    <p className="info">
                        <FaDharmachakra size={15} />
                        { data.car?.transmission }
                    </p>

                    <p className="info">
                        <IoMdCar size={18} />
                        { data.car?.numberOfDoors } Portas
                    </p>
                </InformationCar>
            </ContainerCar>

            <VehicleInformation>
                <p className="label-info">Modelos</p>
                <p className="desc-info">{ data.car?.description }</p>
                <p className="label-info">Incluso</p>
                <p className="desc-info">
                    {
                        data.fareSummary?.carRateIncludedItems.map(item =>
                            item.description + ' | '
                        )
                    }
                </p>

                {open && (
                    <HiddenInformation>

                        <DivDaughter>
                            <TitleSelection>Selecione o local de retirada e entrega</TitleSelection>

                            <p className="label">Retirada</p>

                            <DivRadio>
                                <Radio
                                    name="Pickup"
                                    type="radio"
                                    value=""
                                    checked={true}
                                    onChange={() => {}}
                                />
                                <ContentTextRadioButton>
                                    <p className="location">{ data?.pickupLocation?.company?.name }</p>
                                    <p className="address">{ data?.pickupLocation?.address?.street }</p>
                                    <p className="time">Horário de funcionamento</p>
                                    <p className="days">
                                        { data?.pickupLocation?.operationTimes?.dayOfWeek }
                                        das { data?.pickupLocation?.operationTimes?.openingTime?.hour }:00
                                        ás  { data?.pickupLocation?.operationTimes?.closingTime?.hour }:00
                                    </p>
                                </ContentTextRadioButton>
                            </DivRadio>

                            <p className="label">Entrega</p>

                            <DivRadio>
                                <Radio
                                    name="DropOff"
                                    type="radio"
                                    value=""
                                    checked={true}
                                    onChange={() => {}}
                                />
                                <ContentTextRadioButton>
                                    <p className="location">{ data?.dropoffLocation?.company?.name }</p>
                                    <p className="address">{ data?.dropoffLocation?.address?.street }</p>
                                    <p className="time">Horário de funcionamento</p>
                                    <p className="days">
                                        { data?.dropoffLocation?.operationTimes?.dayOfWeek }
                                        das { data?.dropoffLocation?.operationTimes?.openingTime?.hour }:00
                                        ás  { data?.dropoffLocation?.operationTimes?.closingTime?.hour }:00
                                    </p>
                                </ContentTextRadioButton>

                            </DivRadio>
                        </DivDaughter>
                    </HiddenInformation>
                )}
                <DivPriced>
                    <div>
                        <p className="diaria">Valor da diária</p>
                        { offersCar === "cash" && <p className="price"> { formatCurrency(price) } </p> }
                        { offersCar === "points" && <p className="price">{ parseFloat(points).toLocaleString() }Pts</p> }
                        <p className="rate">taxas inclusas</p>
                    </div>
                    {
                        !open
                            ?   <Button width="160px" onClick={showOpen}>Selecionar</Button>

                            :   <Button width="160px" onClick={() => handleChangeCar(data)}>Continuar</Button>
                    }

                </DivPriced>
            </VehicleInformation>
        </ContainerDad>
    )
}
