export function FormatCurrency (number) { 
    if (number) {   
        let strValue = ''
        const value = number
        strValue = (value).toString()
        strValue = strValue.substring(0, strValue.length - 2) + '.' + strValue.substring(strValue.length - 2, 6);
        const numberAux = parseFloat(strValue)
        const num =  numberAux.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}); 
        return num
    } else return 0
}

export function FormatPoints (number) {
    return parseFloat(number).toLocaleString('pt-BR');
}

export function FormatTotalCurrency (number) { 
    if (number) {   
        let strValue = ''
        const value = number
        strValue = (value).toString()
        const numberAux = parseFloat(strValue)
        const num =  numberAux.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}); 
        return num
    } else return 0
}

export function GetPriceRealAndPoints (item) {
    const money_de = item?.BRL?.list ? FormatCurrency(item?.BRL?.list) : false
    const money = FormatCurrency(item?.BRL?.final)
    const points_de = item?.GENIAL?.list || 0
    const points = item?.GENIAL?.final || 0
    return {'money_de': money_de, 'points_de': points_de, 'money': money, 'points': points}
}

export function numberWithCommas(x) {
    return new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(x)
}