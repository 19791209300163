/* eslint-disable react/prop-types */
import React, {useState} from 'react'

import {useNavigate} from 'react-router'
import {Button} from 'react-bootstrap'
import notify from '../../../../components/UI/toast'
import ModalApp from '../../../../components/ModalApp'
import TextField from '@mui/material/TextField';

export default function Content(props) {
    const [query, setQuery] = useState('')
    const navigate = useNavigate()

    return (

        <ModalApp show={true} handleModal={props.closeSearch}>
                <div className="d-flex flex-column align-items-center gap-4">
                    <h3 className="color-text-dark fw-bolder font-genial-600">
                        O que você quer procurar?
                    </h3>
                    <TextField
                        onChange={(e) => setQuery(e.target.value)}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                                navigate('/loja/products/' + query)
                                window.location.reload();
                            }
                        }}
                        className="form-control"
                        placeholder="Escreva aqui"
                        sx={{
                            ".MuiInput-root": {
                                borderBottom: '1px solid black'
                            },
                            ".css-1x51dt5-MuiInputBase-input-MuiInput-input::placeholder": {
                                  color: 'black',
                                  opacity: 1,
                            }
                        }}
                            
                        variant="standard"
                        type="text"
                    />
   
                    <Button
                        onClick={() => {
                            if (query.length > 2) {
                                parent.window.location = process.env.REACT_APP_URL_GENIAL_LOJA + '/products/' + query
                            } else {
                                notify('Digite pelo menos 3 caracteres no campo de busca', 'error')
                            }
                        }}
                        className="btn-lg btn-default-genial font-genial-600 text-xs w-50 mb-5"
                    >
                        BUSCAR
                    </Button>
                </div>
            </ModalApp>

    )
}
