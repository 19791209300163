import React, {Fragment}  from 'react'

import { Nav } from './styles'
import { Container } from '@mui/material'

import { HiOutlineChevronRight } from 'react-icons/hi'
import Category from './Categories'
import Brand from './Brands'
import { useOpen } from '../../../hooks/Open'
import {useNavigate} from "react-router";
import { useUserData } from "../../../hooks/UserContext";
import Title from '../../../components/Title'

export default function SidebarContainer() {
    const { setCategory, setBrands, brands, category, OpenButton } = useOpen()
    const navigate = useNavigate()

    const handleClickCategory = () => {
        setCategory(!category)
    }

    const handleClickBrand = () => {
        setBrands(!brands)
    }

    const redirectPage = (page) => {
        OpenButton()
        navigate(page)
    }

    const redirectPageAereo = (page) => {
        parent.window.location = `${process.env.REACT_APP_URL_GENIAL_LOJA}` + page
    }

    const { userData } = useUserData()
    

    const AllMenus = {
       "Categorias": "/categories/",

       "Marcas": "/brands/",

        "Viagens" : "/search-travel",

        "Milhas": "/points-transfer/",

        "Minha Conta": "/myaccount/",

        "Meus Pedidos": "/myrequests/",

        "Favoritos": "/favorites/",

        "Termos & Condições": "/policy/",
    }

    return (
        <>
            <Nav>
                <Container className="container-custom"
                sx={{
                    borderTop: "1px solid #dee2e6",
                    marginTop: 1
                }}>
                    <div className="menu-genial menu">
                        <Title titleClass="text-center width-100" label="Menu"/>
                            <ul className="mt-4 padding-left-9">
                                {Object.keys(AllMenus).map((menu, index) => (
                                    <Fragment key={index}>
                                        <li className="d-flex justify-content-between align-items-center my-4" onClick={() => redirectPageAereo(AllMenus[menu])}>
                                            <a href="#">{menu}</a>
                                            <HiOutlineChevronRight />
                                        </li>
                                        <hr />
                                    </Fragment>
                                ))}
                            </ul>
                    </div>

                </Container>
                {category && <Category /> }
                {brands && <Brand /> }
            </Nav>
        </>
    )
}
