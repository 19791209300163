import Styled from 'styled-components'

export const Container = Styled.nav`


svg{
width: 30px;
height: 30px;
color: black;
cursor: pointer;
}
`
