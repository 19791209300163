import Styled from 'styled-components'

export const SliderValues = Styled.div`
  display:flex;
  justify-content: center;
  gap: 1em;
  line-height: 35px;
  color: #263238;
  
  p {
    line-height: 35px;
    margin-bottom: 0;
    color: #263238;
  }
  
  .border-bottom {
    border-color: #263238 !important;
  }
  
`