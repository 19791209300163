import axios from 'axios';
import $ from "jquery";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    params: {
        fromChannel: `envision`
    }
});

api.interceptors.request.use(async (config) => {
    try {
        const token = localStorage.getItem('token')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    } catch (err) {
        console.log('err', err)
    }

})


api.interceptors.response.use(
    async (config) => {
        return config;
    },
    async function (error) {

        console.log('erro', error)
        const access_token = localStorage.getItem("token");

        if (error.response.status === 401 && access_token) {

            console.log('erro')
            return await refreshToken(error);

        }
        return Promise.reject(error);
    }
);

export default api

async function refreshToken(error) {
    return new Promise((resolve, reject) => {
        try {
           // const refresh_token = localStorage.getItem("refresh_token");
            const header = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            };
            const parameters = {
                method: "POST",
                headers: header,
            };

            const body = $.param({
                'client_id':  process.env.REACT_APP_CLIENT_ID,
                'client_secret':  process.env.REACT_APP_CLIENT_SECRET,
                'grant_type': 'client_credentials'
            });

            axios
                .post(
                    process.env.REACT_APP_API_URL,
                    body,
                    parameters
                )
                .then(async (res) => {
                    localStorage.setItem("token", res.data.access_token);
                   // localStorage.setItem("refresh_token", res.data.refresh_token);
                    // Fazer algo caso seja feito o refresh token
                    return resolve(res);
                })
                .catch((err) => {
                    // Fazer algo caso não seja feito o refresh token
                    return reject(error);
                });
        } catch (err) {
            return reject(err);
        }
    });
}


// async function refreshToken(error) {
//     const parameters = {
//         method: "POST",
//         headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/x-www-form-urlencoded'
//         },
//     };
//
//     const body = $.param({
//         'client_id': '1c2be7be4a29135f717728f376ac5a04',
//         'client_secret': 'd3ffc47b66f0aa0eb2812e03e295c910',
//         'grant_type': 'client_credentials'
//     });
//
//     return axios
//         .post(
//             'http://localhost:8080/api',
//             body,
//             parameters
//         )
//         .then(async (res) => {
//
//             console.log('res', res.data)
//
//             return res.data;
//
//         })
//         .catch((err) => {
//             console.log('err token', err)
//         });
// }

