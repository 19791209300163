import Styled from 'styled-components'

export const Container = Styled.div`
margin: 5%;
`

export const Resume = Styled.p`
color: #263238;
font-weight: 700;
font-size: 17px;
`

export const Details = Styled.p`
    color: #40545E;
    font-weight: 700;
    font-size: 17px;
    display: flex;
    gap: 7px;
`

export const Text = Styled.div`
color: #40545E;
font-weight: 500;
font-size: 16px;
display: flex;


p:nth-child(1) {
    width: 110px;
    min-width: 110px;
}

p:nth-child(2) {
    color: rgba(38, 50, 56, 0.5);
    font-weight: 400;
    font-size: 14px;
    text-transform: capitalize;
    
    span:after {
        content: ' | ';
    }
    
    span:last-child:after {
        content: '';
    }
}
`

