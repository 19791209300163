import Styled from 'styled-components'

export const Stored = Styled.nav`
position: absolute;
width: 100%;
height: 100%;
z-index: 1032;
background-color: white;
animation: fadeInRight .8s ease-in-out;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50rem);
  }
  to {
    opacity: 1;
  }
}
`

export const ContainerBar = Styled.div`
text-align: center;
 
.alignment{
 display: flex;
 background-color: #F0F2F5;
 border-radius: 8px;
 padding: 10px 0;
 margin-bottom: 30px;
 
 svg{
    margin-right: 30px;
    color: ${props => props.theme.primary};
    width: 25px;
    height: 25px;
  }
}
`

export const Category = Styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${props => props.theme.primary};
`

export const SearchBar = Styled.input`

    width: 100%;
    background-color: #F0F2F5;
    text-align: center;
    outline: none;
    border: 0;

    ::Placeholder{
      color: #93979D;
      padding-left: 60px;
    }

`

export const ContainerContent = Styled.ul`

svg{
  color: ${props => props.theme.primary} !important;  
}
.title{
  color: black !important;
  font-weight: bold !important;
  font-size: 1.2rem !important;
  list-style-type: none;
  font-family: 'Montserrat',sans-serif;
}

.label {
  margin-top: 0px !important;
  cursor:pointer;
  padding: 0 40px; 
  display: flex;
  padding: 10px 0;
}

.label a {
  text-decoration: none;
}

.label .sub-item {
    color: #979AA0;
    font-size: 1rem !important;
}
`

export const BoxCategories = Styled.div`

 height: 100vh;
 overflow:scroll;
 padding-bottom: 250px;
padding-left: 15px;
padding-right: 15px;
`
