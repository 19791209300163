import React from "react";

import {Modal} from "react-bootstrap";
import Button from "components/Button";

export const ModalApp = ({show, handleModal, isMap = false, children, label = ''}) => {
    return  <Modal
        show={show}
        onHide={ handleModal }
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`rounded-0 ${isMap && 'p-0'} `}
    >
        <Modal.Header closeButton className={`border-0 pb-0 ${isMap && 'close-button-custom'}`}>
           <span style={{color: "#263238"}} className="fw-bold text-center"> {label}</span>
        </Modal.Header>
        <Modal.Body className={`text-center p-0 m-0`}>
            {children}

            {isMap &&
                <div className="mx-3 position-relative">
                    <Button onClick={handleModal} className="close-button-bottom">Fechar</Button>
                </div>
            }
        </Modal.Body>
    </Modal>
}
