import { createGlobalStyle } from 'styled-components'
import "@fontsource/source-sans-pro";


const color = '#40545E';
const color_active = '#4076c0';

export default createGlobalStyle`

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Source Sans Pro';
    font-style: normal;
}

.btn-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: ${color};
  --bs-btn-border-color: var(--bd-violet);
  --bs-btn-border-radius: 9px;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: ${color_active};
  --bs-btn-hover-border-color: ${color_active};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: ${color_active};
  --bs-btn-active-border-color: ${color_active};
}

.modal-content {
    border-radius: 0;
}

.label-custom {
    border-bottom: 1px solid #474646;
    font-size: 16px;
    color: #47464680;
    align-items: center;
    display: flex;
    flex-grow: 1;
}


.css-dk24d0-MuiButtonBase-root-MuiCheckbox-root{
    color: ${color};
}

.form-select-custom {
    border: 0;
    border-radius: 0;
    font-size: 16px;
    box-shadow: none;
    background-color: transparent;
    padding: 0.175rem 0.175rem;
    color: #47464690;
}

.form-select-custom::option{
    padding: 3px;
}

.form-select-custom:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    border-color: #707070;
}

.form-select-custom:disabled {
    background-color: transparent;
}

.form-control-custom {
    border: 0;
    border-radius: 0;
    font-size: 16px;
    box-shadow: none;
    background-color: transparent;
    padding: 0.175rem 0;
    color: #47464690;
}

.border-custom {
    border-bottom: 1px solid #707070;
}

.form-control-custom:focus {
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 0%);
    border-color: #707070;
}

.form-control-custom:disabled {
    background-color: transparent;
}

.disabled-input {
   opacity: 0.6;
}

.form-control-custom::placeholder {
    color: #47464680;
}

.input-group-custom {
    z-index: 555;
    margin-right: 0;
    display: flex;
    align-items: self-end;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: center;
    white-space: nowrap;
    background-color: none;
    border: none;
    border-bottom:1px solid #333;
    border-radius: 0;
    color: #47464695;
    padding: 0.175rem 0.375rem 0.175rem 0.375rem;
}

.input-group-custom svg {
    width: 19px;
    height:19px;
}

.svg-custom svg {
    width: 12px;
    height: 20px;
}

.input-group-custom .checkbox-custom {
    padding: 0;
}

.checkbox-custom {
    height: 20px;
    width: 20px;
    background-color: #eee;
}

.form-check-input[type=checkbox] {
    border-radius: 0;
}

input[type=radio] {
    cursor: pointer;
appearance: none;
border: 1px solid  #263238;
width: 25px;
height: 25px;
border-radius: 50%;
display:flex;
align-items: center;
justify-content: center;

&:before {
    content: "";
    position: absolute;
    width: 17px;
    height: 17px;
    background: #263238; 
    border-radius: 50%;
    opacity: 0;
}
&:checked:before {
    opacity: 1;
}

@media(max-width: 800px) {
    width: 20px;
    height: 20px;

    &:before {
        width: 14px;
        height: 14px;
    }
}
}

.form-check-input {
    border-radius: 0;
    width: 1.3em;
    height: 1.3em;
}

.form-check-label {
    color: #474646;
    font-size: 14px;
    padding: 5px;
}

.form-control-custom-time {
    border: 0;
}

.form-check-input:checked {
    background-color: ${color};
    border-color: ${color};
}

@media(max-width: 767.98px) {
    .carousel-inner > .carousel-item > img {
        height: 290px;
    }
}

@media(min-width: 768px) {
    .carousel-inner > .carousel-item > img {
        min-width: 100%;
    }
}

.content-custom {
    display: grid;
    gap: 28px;
}

.header-genial{
    height:126px;
    display: block;
    top: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    position: fixed;
    z-index: 999;
    color: #000;
}

.content-custom .input-group-custom {
    font-size: 16px;
    color: #474646;
    gap: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.box-search {
    display: flex;
    margin-top: 28px;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 30px;
}
.text-sm {
    font-size: 14px;
}

.text-xs {
    font-size: 16px;
}

.divider {
    border-right: 1px solid #474646;
    height: 18px;
    margin-top: 4px;
}

.color-custom {
   color: #474646;
}

.color-disabled {
   color: #47464690;
}

.bg-content {
    background-color: #F0F0F0;
}

.filter-content-cars,
 .filter-content{
    position: relative;
    padding: 25px 10px;
}

.filter-content, .container-page {
    margin-top: 206px;
}

.result-content {
    padding: 0 10px 25px;
}

.container-search-bar {
    border-bottom: 1px solid #C6C6C5; 
    background-color: white;  
    position: fixed;
    top: 125px;
    z-index: 1000;
    width: 100%;
    text-align: center;
    display: inline-block;
}

.modal-body {
    padding-top: 0;
}

.menu-genial {
    padding-bottom: 200px;
    margin-bottom: 80px;
    overflow: scroll;
    height: 100vh;
    padding-left: 0;
}
.menu-genial ul li a {
    text-decoration: none;
    color: #000000;
    font-size: 1.0em;
    font-weight: bold;
}

.menu-safra {
    padding-bottom: 200px;
    margin-bottom: 80px;
    overflow: scroll;
    height: 100vh;
    padding-left: 0;
}

.menu-safra ul {
    padding-left: 0;
}

.menu-safra ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 24px;
    font-family: 'Montserrat',sans-serif;
}

.dropdown-item {
    font-size: 14px;
}

.label-modal {
    width: 252px;
    line-height: 20px;
    display: inline-flex;
    justify-self: center;
    text-align: center;
    font-weight: bold;
    font-size: 17px;
    color: #263238;
}

.logo-genial {
    margin-left: 8px;
    max-width: 110px;
    align-self: center;
}

.color-text-dark {
    color: rgba(5, 9, 34, 0.6);
}

.color-genial-tertiary {
    color: #1E6ACD;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
}

.close-button-custom {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.close-button-bottom {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none;
}

.border-checked {
    border: 1px solid #40545e;
}

.border-focused {
    border: 1px solid #40545e;
    border-radius: 8px;
}

.border-danger {
    border: 1px solid red;
}

`
