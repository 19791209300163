import { createContext, useContext, useReducer } from "react";
import {data_travellers, initial_date, initial_dates_hotel, initial_guests, initial_time} from "utils";
import { HotelsReducer } from "./reducers/hotels/reducers";
import { CarsReducer } from "./reducers/cars/reducers";

export const AppContext = createContext('')

const INITIAL_STATE_HOTEL = {
    locationSearchHotel: {},
    datesSearchHotel: initial_dates_hotel,
    guestsSearchHotel: initial_guests,
    offersHotel: 'points',
    hotelsList: [],
    hotel: {},
    room: {},
    travellers: data_travellers
}

const INITIAL_STATE_CAR = {
    locationPickupCar: {},
    locationDropOffCar: {},
    returnSameLocalCar: true,
    datesSearchCar: initial_date,
    timeSearchCar: initial_time,
    offersCar: 'points',
    car: {},
    conductor: {}
}

export const AppProvider = ({children}) => {
    const [hotelState, hotelDispatch] = useReducer(
        HotelsReducer,
        INITIAL_STATE_HOTEL
    )

    const [carState, carDispatch] = useReducer(
        CarsReducer,
        INITIAL_STATE_CAR
    )

   // console.log('carState', carState)
   // console.log('hotelState', hotelState)

    return (
        <AppContext.Provider value={{
            hotelState,
            hotelDispatch,
            carState,
            carDispatch
        }}>
            {children}
        </AppContext.Provider>
    )
}

export const useApp = () => useContext(AppContext);
