import Styled from 'styled-components'

export const DivContentCar = Styled.div`
display: flex;
margin: 20px 0 10px;
justify-content: space-between;
margin-right: 20px;
margin-left: 20px;
    
    .label {
        display: flex;
        color: #47464690;
        font-weight: 400;
        font-size: 14px;
        align-items: center;
        margin: 0;
    }
    
    .info {
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    
    .date {
        color: #474646;
        font-weight: 400;
        font-size: 13px;
    }
    
    .local{
            color: #47464690;
            font-weight: 400;
            font-size: 11px;
    }
`
