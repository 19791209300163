import React from "react";

export default function Checkbox({type = "checkbox", name, checked = false, onChange, label = '', value = ''}) {
    return (
        <div className={`d-flex ${type === 'radio' ? 'align-items-center' : ''}  `}>
            <input type={type} name={name} defaultChecked={checked} onChange={onChange} value={value}
                   className={`${type === 'checkbox' && 'form-check-input'}`}/>
            {label &&
                <label className="form-check-label ms-2 pt-1">
                    {label}
                </label>
            }
        </div>
    )
}
