// export const formatCurrency = (value) => {
//     return new Intl.NumberFormat('pt-BR', { currency: 'BRL' }).format(value);
// }

export const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
}

// const formattedValue = new Intl.NumberFormat('pt-BR', {
//     style: 'currency',
//     currency: 'BRL'
// }).format(amount);

export function FormatCurrency (number) {
    if (number) {
        let strValue = ''
        const value = number
        strValue = (value).toString()
        strValue = strValue.substring(0, strValue.length - 2) + '.' + strValue.substring(strValue.length - 2, 6);
        const numberAux = parseFloat(strValue)
        const num =  numberAux.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
        return num
    } else return 0
}
