import Styled from 'styled-components'

export const Container = Styled.header`
background-color: #fff;
width: 100%;
height: 70px;

`
export const Aside = Styled.div`
padding: 20px 0px;
color: #fff;
display: flex; 
justify-content: space-around;
position: fixed;
z-index: 1000;
background-color: #00003C;
width: 100%;
height: 70px;
gap: 200px;
border-bottom: 1px solid #C6C6C5; 
@media(max-width: 800px) {
    gap: 40px;
}
`
export const TitleLogo = Styled.span`
color: #fff;
font-family: 'Montserrat', sans-serif;
font-size: 20px;
font-weight: 700;
text-align: center
`

export const NamePoints = Styled.span`
font-family: "PT Sans", sans-serif;
font-weight: 200;
font-style: normal;
font-size: 11px;
color: #CCCFE0;
text-align: center;
`


export const LogoDiv = Styled.div`
text-align: center;
margin-top: 5px;

img{
    width: 100px;
    height: x;
    cursor: pointer;
}

p{
    margin-top: 5px;
    color: black;
    font-weight: bold;
    span{
        font-family: 'PT Sans', sans-serif;
        line-height: 16px;
        font-size: 12px;
        color: #757575;
    }
}
`
