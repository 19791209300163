import {initial_date, initial_time} from "../../../utils";

export function CarsReducer(state, action){
    switch (action.type) {
        case 'SEARCH_PICKUP_CAR':
            return {
                ...state,
                locationPickupCar: action.payload.locationPickupCar
            }
        case 'SEARCH_DROP_OFF_CAR':
            return {
                ...state,
                locationDropOffCar: action.payload.locationDropOffCar
            }
        case 'SEARCH_RETURN_SAME_LOCAL_CAR':
            return {
                ...state,
                returnSameLocalCar: action.payload.returnSameLocalCar,
                locationDropOffCar: ''
            }
        case 'SEARCH_DATES_CAR':
            return {
                ...state,
                datesSearchCar: action.payload.datesSearchCar
            }
        case 'SEARCH_TIME_CAR':
            return {
                ...state,
                datesSearchCar: action.payload.dateTimeSearchCar,
                timeSearchCar: action.payload.timeSearchCar
            }
        case 'SEARCH_OFFER_CAR':
            return {
                ...state,
                offersCar: action.payload.offersCar,
            }
        case 'SELECTED_CAR':
            return {
                ...state,
                car: action.payload.car,
            }
        case 'SET_CONDUCTOR_CAR':
            return {
                ...state,
                conductor: action.payload.conductor,
            }
        case 'CLEAR_SEARCH':
            return {
                locationPickupCar: {},
                locationDropOffCar: {},
                returnSameLocalCar: true,
                datesSearchCar: initial_date,
                timeSearchCar: initial_time,
                offersCar: 'points',
                car: {},
                conductor: {}
            }
        default:
            return state;
    }
}
