import Styled from 'styled-components'

export const Container = Styled.nav`


svg{
width: 18px;
height: 14px;
color: white;
cursor: pointer;
}
`
