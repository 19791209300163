import React, {useState} from "react";

import {IoMdCard} from "react-icons/io";
import {ModalApp} from "../Modal";

import Button from "../Button";
import {Formik, Field, Form} from 'formik';
import pagarme from 'pagarme/browser';

export default function CardData({card, changeCard, cards, setCards}) {
    const [open, setOpen] = useState(false);

    const handleModal = () => {
        setOpen(!open);
    };

    const saveNewCard = (values) => {
        const card_pagarme = {
            card_number: values.card_number,
            card_holder_name: values.name,
            card_expiration_date: values.valid?.replace("/", ""),
            card_cvv: values.cvv,
        }

      return pagarme.client.connect({encryption_key: 'ek_test_gZduqWm5rAhMMiT58rlFZCocdcecxd'})
            .then(client => client.security.encrypt(card_pagarme))
            .then(card_hash => {
                const last_digits = values.card_number?.substr(-4);

                setCards([{
                    last_digits: last_digits,
                    valid: values.valid,
                    hash: card_hash,
                    save: true
                }, ...cards]);

                changeCard({last_digits: last_digits, hash: card_hash})

                return true
            })
            .catch(error => {
                console.error('error hash pagarme', error);
                return false
            })
    }

    const initialValues = {
        card_number: '',
        valid: '',
        cvv: '',
        name: '',
        hash: '',
    };

    return (
        <>
            <div className="input-group">
                <span className="input-group-custom">
                    <IoMdCard/>
                </span>
                <select className="form-select form-select-custom border-custom" onChange={(e) => changeCard(e.target.value)}>
                    <option value="">Selecione o cartão</option>

                    { cards?.map((item, index) =>
                        <option key={index} value={index}>
                            { item.last_four }
                        </option>
                    )}

                </select>
            </div>


            {/*<div className="dropdown">*/}
            {/*    <div className="input-group" role="button" data-bs-toggle="dropdown" aria-expanded="false">*/}
            {/*            <span className="input-group-custom">*/}
            {/*                <IoMdCard/>*/}
            {/*            </span>*/}
            {/*        <label className="label-custom">{card.last_digits}</label>*/}
            {/*        <span className="input-group-custom">*/}
            {/*                <FiChevronDown/>*/}
            {/*            </span>*/}
            {/*    </div>*/}

            {/*    <ul className="dropdown-menu w-100">*/}
            {/*        {*/}
            {/*            cards?.map((item, index) =>*/}
            {/*                <li key={index} onClick={() => changeCard(item)}>*/}
            {/*                    <span className="dropdown-item text-wrap">{item.last_digits}</span>*/}
            {/*                </li>*/}
            {/*            )*/}
            {/*        }*/}
            {/*        <li>*/}
            {/*            <span className="dropdown-item" onClick={() => setOpen(true)}>*/}
            {/*                 Cadastrar novo cartão*/}
            {/*            </span>*/}
            {/*        </li>*/}
            {/*    </ul>*/}
            {/*</div>*/}

            <ModalApp show={open} handleModal={handleModal}>

                <Formik
                    initialValues={initialValues}
                    onSubmit={async (values) => {
                        await new Promise((r) => setTimeout(r, 500));
                        //console.log(JSON.stringify(values, null, 2));

                        const save = saveNewCard(values)
                        if(save) {
                            handleModal()
                        }
                    }}
                >
                    {({values}) => (
                        <Form>
                            <div className="d-grid gap-3 px-3">
                                <p className="label-modal">Preencha os dados para adicionar seu cartão</p>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="input-group">
                                            <Field className="form-control form-control-custom border-custom"
                                                   name="card_number" placeholder="Número do Cartão" required/>
                                            <span className="input-group-custom">
                                                <IoMdCard/>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-6">
                                        <Field className="form-control form-control-custom border-custom"
                                               name="valid" placeholder="Validade" required/>
                                    </div>
                                    <div className="col-6">
                                        <Field className="form-control form-control-custom border-custom"
                                               name="cvv" placeholder="CVV" required/>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <Field className="form-control form-control-custom border-custom"
                                               name="name" placeholder="Nome do titular do Cartão" required/>
                                    </div>
                                </div>

                                <div className="text-center my-3">
                                    <Button type="submit" width="200px">Adicionar</Button>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </ModalApp>
        </>
    );
}
