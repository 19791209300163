import React from 'react';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';

import { Container } from './styles'

export default function RangeSlider({value, onChange, children}) {
  return (
    <Container>
        <div className="slider-filter">
              <Stack spacing={2} direction="row" sx={{ mb: 1 }} alignItems="center">
                <div className="bol-left"/>
                    <Slider
                      value={value}
                      onChangeCommitted={onChange}
                    />
                <div className="bol-right"/>
             </Stack>
        </div>
        {children}
    </Container>
  );
}
