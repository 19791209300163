import api from '_config/http'
import format from "date-fns/format";

export function getHotels(currentPage, guests, dates, location, filter, order) {

    const checkin = format(dates.startDate, "yyyy-MM-dd");
    const checkout = format(dates.endDate, "yyyy-MM-dd");

    const adults = guests.adults; //rooms.map(item => item.adults).reduce((soma, i) => soma + i)
    const children = guests.children; //rooms.map(item => item.children).reduce((soma, i) => soma + i)

    const mountData = () => {
        let items_adult = [];
        let items_children = [];

        for (let i = 1; i <= adults; i++) {
            items_adult.push({
                "ageGroup": "adult",
                "age": 35
            })
        }

        for (let i = 1; i <= children; i++) {
            items_children.push({
                "ageGroup": "children",
                "age": guests.childrenInputAge[i-1].age
            })
        }

        return items_adult.concat(items_children)
    }

    const filterParameters = filter.filterParameters

    let orderBy = "price"
    let ordering = "asc"

    if ( order === 'az' || order === 'za') {
        orderBy = 'name'
        ordering = order === 'az' ? 'asc' : 'desc'
    }

    if ( order === 'asc_price' || order === 'desc_price') {
        orderBy = 'price'
        ordering = order === 'asc_price' ? 'asc' : 'desc'
    }

    if (order === 'stars') {
        orderBy = 'stars'
    }

    const body = JSON.stringify({
        "locationId": location.id,
        "checkIn": checkin,
        "checkOut": checkout,
        "travellers": mountData(),
        "paginationParameters": {
            "pageNumber": currentPage,
            "itemsPerPage": 1000
        },
        filterParameters,
        "ordering": ordering,
        "orderBy": orderBy,
    });


   return api.post('/hotels/search', body)
        .then(response => {

            if ( typeof response.data != "undefined") {
                const {data} = response.data

                if (data?.hotelResponseData) {
                    return {data: data.hotelResponseData, status: true};
                }
            }

            return { status: false }
        })
        .catch(error => {
            console.log(error)
            return { status: false }
        })
}
