import React from "react";

import {IoSearch} from "react-icons/io5";
import Button from 'components/Button'

import {ContentNotFound} from './styles'

export const BoxNotFound = ({text1, text2, handleClick}) => {
    return (
        <ContentNotFound>
            <div className="row m-5">
                <div className="col-12">
                    <h5 className="fw-bolder fs-5 label">{text1}</h5>
                    <p className="m-0 color-disabled">{text2}</p>
                    <p className="color-disabled">Tente novamente.</p>

                    <div className="mt-3">
                        <Button
                            width="200px"
                            data-bs-toggle="collapse"
                            data-bs-target="#filter-collapse"
                            aria-expanded="false"
                            aria-controls="filter-collapse"
                            onClick={handleClick}
                        >
                            <IoSearch/> Refazer Busca
                        </Button>
                    </div>
                </div>
            </div>
        </ContentNotFound>
    )
}

export const NotFoundResultsPassage = ({handleInfo}) => {
    return (
        <BoxNotFound
            handleClick={handleInfo}
            text1="Passagem não encontrada"
            text2="Não encontramos nenhuma passagem de acordo com sua busca."
        />
    )
}

export const NotFoundResultsHotel = ({handleInfo}) => {
    return (
        <BoxNotFound
            handleClick={handleInfo}
            text1="Hotel não encontrado"
            text2="Não encontramos nenhum hotel de acordo com sua busca."
        />
    )
}

export const NotFoundResultsCars = ({handleInfo}) => {
    return (
        <BoxNotFound
            handleClick={handleInfo}
            text1="Carro não encontrado"
            text2="Não encontramos nenhum carro de acordo com sua busca."
        />
    )
}
