import React from "react";
import PropTypes from 'prop-types'
import {Modal} from "react-bootstrap";

export default function ModalApp({ show, handleModal, children,contentClassName }){
    return  <Modal
        show={show}
        onHide={ handleModal }
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="rounded-0"
        dialogClassName="custom-dialog"
        contentClassName={contentClassName}

    >
        <Modal.Header closeButton className="border-0 pb-0"/>
        <Modal.Body>
            {children}
        </Modal.Body>
    </Modal>;
}

ModalApp.propTypes = {
    show: PropTypes.bool,
    handleModal: PropTypes.func,
    children: PropTypes.node,
    contentClassName: PropTypes.string
  }
  