import axios from 'axios';
import $ from "jquery";

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
    params: {
        fromChannel: `genial`
    }
});

api.interceptors.request.use(async (config) => {
    try {
        const token = localStorage.getItem('token_travel_genial_v1')

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    } catch (err) {
        console.log('err', err)
    }

})


api.interceptors.response.use(
    async (config) => {
        return config;
    },
    async function (error) {

        const access_token = localStorage.getItem("token_travel_genial_v1");

        if (error.response.status === 401 && access_token) {
            return await refreshToken(error);
        }
        return Promise.reject(error);
    }
);

export default api

async function refreshToken(error) {
    return new Promise((resolve, reject) => {
        try {
            const header = {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            };
            const parameters = {
                method: "POST",
                headers: header,
            };

            const body = $.param({
                'client_id':  process.env.REACT_APP_CLIENT_ID,
                'client_secret':  process.env.REACT_APP_CLIENT_SECRET,
                'grant_type': 'client_credentials'
            });

            axios
                .post(
                    process.env.REACT_APP_API_URL,
                    body,
                    parameters
                )
                .then(async (res) => {
                    localStorage.setItem("token_travel_genial_v1", res.data.access_token);

                    return resolve(res);
                })
                .catch((err) => {

                    return reject(error);
                });
        } catch (err) {
            return reject(err);
        }
    });
}
