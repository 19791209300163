import React from "react";

import Right from "../../../assets/img/aprov.svg";
import Button from "../../../components/Safra/Button";
import {useNavigate, useParams} from "react-router-dom";

import {
    Container,
    ContainerImgText,
    ContainerCarousel,
    ErrorTitle
} from "./styles";
import Error from "../../../assets/img/error.svg";
import Warning from "../../../assets/img/warning.png";

export default function CheckoutMessage() {
    const navigation = useNavigate();

    const {id} = useParams()

    const SuccessMessage = () => {
        return (
            <>
                <Container>
                    <ContainerImgText>
                        <img className="img-aprovado" src={Right} alt="img-aprovado"/>
                        <p className="resgate">Sua reserva foi aprovada</p>
                        <p className="infor">
                            Você receberá os detalhes da sua reserva em seu email
                            cadastrado
                        </p>
                        <div className="protocolo">
                            <p>Nº do Pedido</p>
                            <span>{id}</span>
                        </div>

                        <ContainerCarousel>
                            <Button className="btn-home" onClick={() => navigation("/")}>Voltar para página
                                inicial</Button>
                        </ContainerCarousel>
                    </ContainerImgText>
                </Container>
            </>
        )
    }

    const ErrorMessage = () => {
        return (
            <>
                <Container>
                    <img src={Warning} alt="" style={{height: "130px", width: "130px", marginTop: "50px"}}/>
                    <ErrorTitle>Pagamento não autorizado</ErrorTitle>

                    <span>
                        Revise seus dados e/ou altere a sua forma de pagamento.
                        Caso não seja possível realizar sua reserva, tente novamente mais tarde.
                    </span>

                    <Button style={{marginTop: "200px"}} onClick={() => window.history.back()} className="top">Voltar ao pagamento</Button>
                </Container>
            </>
        )
    }

    return (
        <>
            {id ? <SuccessMessage/> : <ErrorMessage/>}
        </>
    );
}
