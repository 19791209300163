import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import {useApp} from "../contexts/AppContext";
import {isChannel} from "./index";
import {useGenialApp} from "../contexts/GenialAppContext";


const CurrencyInput = ({ maskOptions, ...inputProps }) => {

    const { hotelState } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { offersHotel } = hotelState

    const defaultMaskOptions = {
        prefix: offersHotel === 'cash' && ' R$ ',
        suffix: offersHotel === 'points' && ' pontos',
        thousandsSeparatorSymbol: '.',
        allowDecimal: true,
        decimalSymbol: ',',
        decimalLimit: 2, // how many digits allowed after the decimal
        integerLimit: 7, // limit length of integer numbers
        allowNegative: false,
        allowLeadingZeroes: false,
    }


    const currencyMask = createNumberMask({
        ...defaultMaskOptions,
        ...maskOptions,
    })

    return <MaskedInput mask={currencyMask} {...inputProps} />
}

CurrencyInput.defaultProps = {
    inputMode: 'numeric',
    maskOptions: {},
}

CurrencyInput.propTypes = {
    inputmode: PropTypes.string,
    maskOptions: PropTypes.shape({
        prefix: PropTypes.string,
        suffix: PropTypes.string,
        thousandsSeparatorSymbol: PropTypes.string,
        allowDecimal: PropTypes.boolean,
        decimalSymbol: PropTypes.string,
        decimalLimit: PropTypes.string,
        requireDecimal: PropTypes.boolean,
        allowNegative: PropTypes.boolean,
        allowLeadingZeroes: PropTypes.boolean,
        integerLimit: PropTypes.number,
    }),
}

export default CurrencyInput
