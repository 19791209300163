import Styled from 'styled-components'

export const DivNavigate = Styled.nav`

`

export const Container = Styled.div`
border-bottom: 1px solid #C6C6C5; 
background-color: white;  
position: fixed;
top: 90px;
z-index: 1000;
width: 100%;
text-align: center;
display: inline-block;
`

export const Informations = Styled.div`
display:flex;
justify-content: center;
gap: 14%;
margin: 15px 0 5px;

    p {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 14px;
       
    
        @media (max-width: 800px) {
        margin-right: -7px;
        margin-left: -7px;
        color: #474646;
    }

    span {
        color:rgba(71, 70, 70, 0.5);
        font-size: 12px;
   }
}

@media(max-width: 800px) {
    gap: 45px;
}
`

export const ContainerDate = Styled.div`
display: flex;
justify-content: space-between;
margin-top: -10px;

.test{
    width: 60px;
}
`
