/* eslint-disable react/prop-types */
import React/* , { useState } */ from 'react'
import { HiOutlineSearch } from 'react-icons/hi'
import { Search } from './styles'

export default function SearchComponent (props) {
  return (
    <>
      <Search>
        <button onClick={props.onClick}><HiOutlineSearch/></button>
      </Search>
    </>
  )
}
