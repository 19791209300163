import Styled from 'styled-components'


export const PaginationContent = Styled.ul`
display: flex;
justify-content: center;
`

export const Pagination = Styled.div`
    display: flex;
    justify-content: center;
   
    ul li a{
        border: 0;
        font-size: 16px;
        color: #47464690;
        background-color: transparent;
        padding: 0 8px;
    }
   
    .active>.page-link, .page-link.active {
        z-index: 3;
        color: #474646;
        background-color: transparent;
        border-color: transparent;
    }
    
    .page-link:hover {
        color: #474646;
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
    }

`
