/* eslint-disable react/prop-types */
import React, {createContext, useCallback, useContext, useEffect, useState} from 'react'

import PropTypes from 'prop-types'
import api from "../_services/api";
import {useLocation} from "react-router-dom";

const UserContext = createContext('')

export const UserProvider = ({children}) => {
    const [userData, setUserData] = useState([])
    const [user, setUser] = useState([])
    const [addressesData, setAddressesData] = useState([])
    const [addressShipping, setAddressShipping] = useState([])
    const [updateAddresses, setUpdateAddresses] = useState()
    const [updateUser, setUpdateUser] = useState()

    const location = useLocation();
    const currentUrl = location.pathname; //

    function updateUserData() {
        const clientUserData = localStorage.getItem('customer')
        const user = JSON.parse(clientUserData)

        if (!user) {
            let redirectUrl = process.env.REACT_APP_DEFAULT_ENDPOINT
            redirectUrl = redirectUrl.replace('/proxy', '') + '?returnTo=' + currentUrl
            window.location.href = redirectUrl
        }

        setUserData(user)
        setUser(user.data)
        return user.data
    }

    const changeAddress = useCallback((address) => {
        setAddressShipping(address)
    }, []);


    async function getAddresses(uuid)  {
        try {
            const response = await api.get('/api/list-address?customerUuid=' + uuid)
            return response.data?.data
        } catch (e) {
            console.log('Error get addresses', e)
            return false
        }
    }
    async function getBalance(cpf)  {
        try {
            const response = await api.get('/api/balance?partner_customer_id=' + cpf)
            return response.data?.status !== 'fail' && response.data?.balance
        } catch (e) {
            console.log('Error get balance', e)
            return false
        }
    }


    useEffect(() => {
        const loadUserdata = async () => {
            const getUser = !user.length ? updateUserData() : user

            const addresses = await getAddresses(getUser.uuid)
            setAddressesData(addresses)

            if(!addressShipping.length) {
                setAddressShipping(addresses[0])
            }

        }
        loadUserdata()
    }, [])

    useEffect(() => {
        const refreshAddresses = async () => {
            const addresses = await getAddresses(user.uuid)
            setAddressesData(addresses)
        }

        if(updateAddresses) refreshAddresses()

    }, [updateAddresses])

    useEffect(() => {
        const refreshUser = async () => {
            console.log('entrou aqui refreshUser')
            let clientUserData = localStorage.getItem('customer')
            clientUserData = JSON.parse(clientUserData)
            const balance = await getBalance(user.cpf)
            if(balance)
                clientUserData.data.balance = balance
            localStorage.setItem('customer', JSON.stringify(clientUserData));
            updateUserData();
        }

        if(updateUser) refreshUser()

    }, [updateUser])



    return (
        <UserContext.Provider value={{
                userData,
                user,
                updateUserData,
                changeAddress,
                addressShipping,
                addressesData,

                setUpdateAddresses,
                setUpdateUser
        }}>
            {children}
        </UserContext.Provider>
    )
}


export function useUserData() {
    return useContext(UserContext)
}

UserProvider.propTypes = {
    children: PropTypes.node
}
