import React, {useState, useEffect} from "react";

import {SliderValues} from "./styles";
import {formatCurrency} from "utils/functions";
import RangeSlider from "../RangeSlider";

import {getPaymentConditions} from "_services/payment.service";

export function SliderCheckout({ totalPurchase, changeTotal, product, daily = 0}) {
    const [sliderValue, setSliderValue] = useState(50);
    const [cashValue, setCashValue] = useState(0);
    const [pointsValue, setPointsValue] = useState(0);

    const [rulesPayment, setRulesPayment] = useState({})

    useEffect(() => {
        const request = async () => {

            let body;

            if (product === 'hotel') {
                body = {
                    "totals": [
                        {
                            "diary_value": totalPurchase,
                            "diary_quantity": daily
                        }
                    ]
                }
            } else {
                body = {
                    "amount": totalPurchase * 100,
                }
            }

            const response = await getPaymentConditions(body)
            const minPoints = response.minimum_payments.ENVISION

            const calc = minPoints * response.coefficient

            setRulesPayment({
                greaterValue: response.amount,
                greaterValuePoints: response.ENVISION,
                lowerValuePoints: minPoints,
                lowerValue: parseInt(calc.toFixed(2))
            })
        }
        request()
    },[])

    useEffect(() => {
        changeValues(50)
    }, [rulesPayment])


    function handleSliderChange(event, newValue) {
        setSliderValue(newValue);
        changeValues(newValue);
    }

    function changeValues(rangeSlide) {

        let range = (rangeSlide * rulesPayment.greaterValue) / 100
        range = parseInt(range.toFixed(2))

        let cash;
        let points;

        let rangePoints = (rangeSlide * rulesPayment.greaterValuePoints) / 100
        rangePoints = Math.ceil(parseInt(rangePoints))

        const calc = rulesPayment.greaterValuePoints - rangePoints

        if (rulesPayment.lowerValuePoints !== 0 && calc <= rulesPayment.lowerValuePoints) {

            cash = rulesPayment.greaterValue - rulesPayment.lowerValue
            points = rulesPayment.lowerValuePoints

        } else if(calc >= rulesPayment.greaterValuePoints) {

            cash = 0
            points = Math.ceil(rulesPayment.greaterValuePoints)

        } else {

            cash = range
            points = Math.floor(rulesPayment.greaterValuePoints - rangePoints)

            if (points === 0) {
                cash = Math.ceil(rulesPayment.greaterValue)
            }
        }

        setCashValue(cash)
        setPointsValue(points)

        changeTotal({
            points: points,
            cash: cash
        })
    }

    return (
        <RangeSlider value={sliderValue} onChange={handleSliderChange}>
            <SliderValues>
                <span className="fw-normal fs-5">Total:</span>
                <div className="d-flex flex-column">
                    <p className="border-bottom" >
                        { pointsValue ? parseFloat(pointsValue).toLocaleString() : 0 } Pts
                    </p>
                </div>
                <span className="mx-0">+</span>
                <div className="d-flex flex-column">
                    <p className="border-bottom">
                        { cashValue ? formatCurrency(cashValue) : 0 }
                    </p>
                </div>
            </SliderValues>
        </RangeSlider>
    )
}