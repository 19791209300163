import React, { useState } from 'react'

import SearchBarPassage from 'components/SearchBarPassage'
import { FiChevronRight, FiChevronLeft } from 'react-icons/fi'
import { NotFoundResultsPassage } from "components/NotFoundResults";
import { BoxHotel } from "components/BoxHotel";
import { DivPagination } from './styles'
import { Container } from 'react-bootstrap'

/* import Pagination from '../../components/Pagination' */

export default function PassageSearchResult() {
    const [info, setInfo] = useState(true)

    function handleInfo() { setInfo(!info) }

    const result = []

    return (
        <>
            <SearchBarPassage info={info} handleInfo={handleInfo} />
            <Container>
                <div className="filter-content">
                    <div className="row align-items-end">
                        <div className="col-7 d-flex gap-2">
                            {/*<FilterResult />*/}
                        </div>
                        <div className="col-5">
                            <select className="form-select form-select-custom border-custom">
                                <option value="">Ordenar por</option>
                                <option value="A-Z">A-Z</option>
                                <option value="Z-A">Z-A</option>
                                <option value="price">Preço</option>
                                <option value="stars">Estrelas</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="result-content">

                    { !result.length &&  <NotFoundResultsPassage handleInfo={handleInfo} />  }

                    { result.map((item, index) =>
                        <div key={index}>
                            <BoxHotel />
                        </div>
                    )}

                </div>

                { result.length > 0 &&
                    <DivPagination>
                        <li>
                            <button className="anterior" ><FiChevronLeft />Anterior</button>
                        </li>

                        <li>
                            <button>1</button>
                        </li>

                        <li>
                            <button>2</button>
                        </li>

                        <li>
                            <button className="prox" >Próximo<FiChevronRight /></button>
                        </li>
                    </DivPagination>
                }

                {/* <Pagination /> */}

            </Container>
        </>
    )

}
