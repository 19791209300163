import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import Loader, { LoaderSearchImage } from "components/Loader";
import Header from "components/Header";
import HeaderMenu from "components/Genial/HeaderMenuContent";
import { OpenProvider } from "components/Genial/hooks/Open";
import { UserProvider as UserProviderGenial } from "components/Genial/hooks/UserContext";

import Routes from "routes";
import RoutesSafra from "./routesSafra";

import GlobalStyles from "globalStyle";
import { AuthProvider } from "contexts/AuthProvider";
import { AppProvider } from "contexts/AppContext";
import { GenialAppProvider } from "contexts/GenialAppContext";

import { AuthSafraProvider } from "contexts/Safra/AuthProvider";

import { urlChannel } from "utils";
import store from "store"

//Safra
import SafraHeader from "components/Safra/Header";

const MainContent = () => {
    urlChannel()
    return (
        <>
        {
            localStorage.getItem('channel') === 'genial' &&
            <AuthProvider>
                <AppProvider>
                    <Suspense fallback={<Loader/>}>
                        <Provider store={store}>
                            <GenialAppProvider>
                                <UserProviderGenial>
                                    <OpenProvider>
                                        <HeaderMenu/>
                                        <Routes />
                                    </OpenProvider>
                                    <ToastContainer limit={1}/>
                                    <GlobalStyles/>
                                </UserProviderGenial>
                            </GenialAppProvider>
                        </Provider>
                    </Suspense>
                </AppProvider>
            </AuthProvider>
        }
        {
            localStorage.getItem('channel') === 'll' &&
                <AuthProvider>
                    <AppProvider>
                        <Suspense fallback={<LoaderSearchImage/>}>
                            <Header/>
                            <Routes/>
                            <GlobalStyles/>
                        </Suspense>
                    </AppProvider>
                </AuthProvider>
        }
            {
                localStorage.getItem('channel') === 'safra' &&
                <AuthSafraProvider>
                    <AppProvider>
                        <Suspense fallback={<LoaderSearchImage/>}>
                            {/*<SafraHeader />*/}
                            <RoutesSafra/>
                            <GlobalStyles/>
                        </Suspense>
                    </AppProvider>
                </AuthSafraProvider>
            }
        </>
    );
};

export default function App() {
    return <MainContent />
}
