import React, {useEffect, useState} from 'react'

import {DivMarcas, BrandLabel,TitleProduct} from './styles'
import {Container} from 'react-bootstrap'
import AlphabetList from 'react-alphabet-list'
import api from '../../../../_services/api'
import {useNavigate} from 'react-router'

import SearchInput from "../../../SearchInput";
import {useOpen} from "../../../../hooks/Open";
import Title from "../../../../components/Title";

export default function brands() {

    const { OpenButton, setBrands, forceCloseAllMenu } = useOpen()
    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState('')

    async function getBrands() {
        const response = await api.get('api/product/brand')
        return response.data
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await getBrands()
                setData(response)
                setLoading(false)
            } catch (err) {
                setLoading(false)
                /*  notify('Erro ao tentar carregar campos', 'error')  */
            }
        }
        fetchData()
    }, [])

    const redirectPage = (label) => {
        setBrands(false)
        OpenButton()
        const returItem = data.filter(item => item.label === label)
        navigate(`/loja/brands/${returItem[0].id}`)
        window.location.reload();
    }

    const labelBrands = data.map(item => item.label)
    const filterBrands = search ? labelBrands.filter(item => item.toLowerCase().includes(search.toLowerCase())) : labelBrands;

    return (
        <div className="genial-page">
            <Container>
                <Title titleClass="text-center width-100" label="Marcas" />
                <SearchInput
                    value={search}
                    onChange={(e) => setSearch(e)}
                    placeholder="Procurar marcas"
                />

                {loading ?
                    <div className="text-center">
                        <p className="fs-6">Carregando marcas </p>
                    </div>

                    : !filterBrands.length ?
                        <div className="text-center">
                            <p className="fs-6">Nenhuma marca encontrada </p>
                        </div>
                        :

                        <DivMarcas>
                            <div className="div-list overflow-none" style={{height: "90vh", overflow: "scroll"}}>
                                <AlphabetList
                                    style={{position: 'initial', marginBottom: "200px", paddingRight: "0"}}
                                    className="alpha-list font-genial-400"
                                    data={filterBrands}
                                    generateFn={(label, index) => {
                                        return (
                                            <div key={label + index} style={{paddingRight: "0"}}>
                                                <BrandLabel className="font-genial-400 text-lg" onClick={() => redirectPage(label)}>{label}</BrandLabel>
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </DivMarcas>
                }
            </Container>
        </div>
    )
}
