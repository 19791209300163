import api from '_config/http'

export function getLocation(location, limit) {

    const body = JSON.stringify({
        "locationName": location,
        "limit": limit
    });

    return api.post('/hotels/search-location', body)
        .then(response => {

            if (response.status === 200) {
               return response.data;
            }

        })
        .catch(error => console.log(error))
}
