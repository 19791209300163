import React from 'react'

import { IoSearch} from "react-icons/io5";
import { Collapse } from "components/Collapse";
import Button from "components/Button";
import {DivContentCar} from "./styles";
import format from "date-fns/format";
import SearchAirfare from "../SearchAirfare";

export default function SearchBarCars({info, handleInfo}) {


    return (
        <div className="container-search-bar">
            {info && (
                <>
                    <DivContentCar>
                        <p className="label">Origem</p>

                        <div className="info">
                            <div className="date d-flex gap-2">
                               <span>
                                   {/*{dates?.startDateInfo ?? format(new Date, 'dd/MM/yy')}*/}
                               </span>
                            </div>
                            {/*<span className="local">{locationAirfare ? locationAirfare : '-'}</span>*/}
                        </div>

                        <p className="label">Destino</p>

                        <div className="info">
                            <div className="date d-flex gap-2">
                                {/*<span>{dates?.endDateInfo ?? format(new Date, 'dd/MM/yy')} </span>*/}
                            </div>
                            {/*<span className="local">{returnAirFare ? returnAirFare : '-'}</span>*/}
                        </div>
                    </DivContentCar>

                    <div className="mb-3">
                        <Button width="200px" onClick={handleInfo} data-bs-toggle="collapse"
                                data-bs-target="#filter-collapse" aria-expanded="false" aria-controls="filter-collapse">
                            <IoSearch/> Refazer Busca
                        </Button>
                    </div>
                </>
            )}

            <Collapse handleClick={handleInfo}>
                <p className="fw-bolder pt-3">Pesquisar passagem</p>

                    <SearchAirfare />

                    <div className="text-center mb-4">
                        <Button onClick={() => {}} width="200px"><IoSearch/> Refazer Busca</Button>
                    </div>
            </Collapse>
        </div>
    )
}
