import React,{ useEffect }  from 'react'
import { Container } from './styles'
import Badge from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import { AiOutlineShoppingCart } from 'react-icons/ai'

import { useSelector,useDispatch } from 'react-redux'
import { totalItens,getCart  } from "../../../Redux/cartSlice"

import {useOpen} from "../hooks/Open";
import {useUserData} from '../hooks/UserContext'

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: -9,
    border: `2px solid #FFFFFF`,
    backgroundColor: '#636779',
    color: '#FAFBFF',
  }
}))

function CarBuy() {
  const { openCart } = useOpen()
  const total = useSelector(totalItens)
  const dispatch = useDispatch()
  const {user, addressShipping} = useUserData()

  const redirectGenialCart = () => {
    parent.window.location = `${process.env.REACT_APP_URL_GENIAL_LOJA}/orderresume/`
  }

  return (
    <Container>
      <IconButton aria-label="cart" onClick={redirectGenialCart}>

        <StyledBadge  badgeContent={total}  color="secondary">

        </StyledBadge>

        <AiOutlineShoppingCart/>
      </IconButton>
     </Container>
  )
}

export default CarBuy
