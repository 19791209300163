import Styled from 'styled-components'

import { GoSearch } from 'react-icons/go'

export const DivContentCar = Styled.div`
display: flex;
margin: 20px 0 5px;
justify-content: space-between;
margin-right: 20px;
margin-left: 20px;
`

export const ParagraphFirst = Styled.p`
display: flex;
color: #47464690;
font-weight: 400;
font-size: 14px;
align-items: center;
`

export const ParagraphSecond = Styled.p`
text-align: center;
color: #474646;
font-weight: 400;
font-size: 13px;
display: flex;
flex-direction: column;
span{
    color: #47464690;
    font-weight: 400;
    font-size: 11px;
}
`

export const ImgSearch = Styled(GoSearch)`
margin-right: 10px;
`

export const ButtonSearch = Styled.button`
background: #40545E;
border-radius: 9px;
color: white;
border: 0;
height: 30px;
width: 187px;
margin-bottom: 20px;
`

export const ContainerFilter = Styled.select`
float: right;
border-style: none;
background-color: white;
border-bottom: 1px solid;
color: rgba(71, 70, 70, 0.5);

:focus, :focus {
box-shadow: 0 0 0 0;
outline: 0;
}
`

export const ContainerDad = Styled.div`
border-bottom: 2px solid rgba(117, 117, 117, 0.32);
margin-bottom: 25px;
`

export const ContainerCar = Styled.div`
display: flex;
align-items: center;
`

export const DivImagem = Styled.div`
height: 105px;
width: 172px;
`

export const InformationCar = Styled.div`
margin-left: 10px;

.type-car {
    font-size: 17px;
    font-weight: 700;
    color: #40545E;
    margin-bottom: 10px;
    text-transform: capitalize;
}

.info{
    color: #474646;
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 5px;
    text-transform: capitalize;

    svg {
        margin-right: 8px;
    }
}

p:nth-child(3) {
    svg {
        margin-right: 12px;
    }
}

p{
    margin-bottom: 0;
}

`
export const VehicleInformation = Styled.div`
margin-bottom: 25px;
margin-left: 5px;

.label-info {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 700;
    color: #40545E;
}

.desc-info {
    color: #474646;
    font-weight: 400;
    font-size: 10px;
}

p{
    margin-bottom: 0;
}
`

export const DivPriced = Styled.div`
display: flex;
justify-content: space-between;
align-items: center;
margin-top: 20px;

.diaria{
    color: #263238;
    font-weight: 400;
    font-size: 10px;
}

.price{
    color: #263238;
    font-weight: 700;
    font-size: 26px;

span{
    font-size: 20px;
}
}

.rate{
font-size: 10px;
font-weight: 400;
color: rgba(38, 50, 56, 0.5);
}
`

export const ButtonSelect = Styled.button`
background: #40545E;
border-radius: 9px;
height: 30px;
width: 159px;
border: 0;
color: #fff;
`

export const HiddenInformation = Styled.div`
margin-top: 20px;
background: #EEEEEE;
min-height: 200px;
max-height: 500px;
overflow-y: auto;
border-radius: 4px;

::-webkit-scrollbar{
    background: #C4C4C4;
    border-radius: 10px;
    width: 8.75px;
} 

::-webkit-scrollbar-thumb {
    background: #40545E;
    border-radius: 10px;
    width: 9px
`
export const  DivDaughter = Styled.div`
padding: 0 10px 30px; 
    .label {
        margin-top: 15px;
        margin-bottom: 0;
        color: #40545E;
        font-weight: 700;
        font-size: 15px;
    }
}
`

export const DivCityAlternatives = Styled.div`
display: flex;
gap: 20px;
justify-content: center;
align-items: center;
background: #40545E;
height:  34px;
border-radius: 4px 4px 0px 0px;


.retirada{
font-weight: 400;
font-size: 10px;
color: #EEEEEE;
}

.city{
color: #EEEEEE;
font-weight: 700;
font-size: 14px;
}

.alterar{
font-weight: 400;
font-size: 10px;
color: #EEEEEE;
}
`
export const TitleSelection = Styled.p`
color: #40545E;
font-weight: 700;
font-size: 16px;
padding-top: 17px;
`

export const DivRadio = Styled.label`
display: flex;
padding-top: 20px;
`


export const ContentTextRadioButton = Styled.div`
padding-left: 10px;


.location{
    font-size: 14px;
    font-weight: 600;
    color: #40545E;
}

.address {
    font-size: 13px;
    font-weight: 400;
    color: #474646;
}

.time{
    font-size: 11px;
    font-weight: 400;
    color: rgba(71, 70, 70, 0.5);
}

.days{
    font-size: 12px;
    font-weight: 700;
    color: #40545E;
}
`
