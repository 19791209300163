import Styled from 'styled-components'

export const Nav = Styled.nav`
width: 100%;
position: absolute;
z-index: 1032;

animation: fadeInRight .8s ease-in-out;
@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50rem);
  }
  to {
    opacity: 1;
  }
}
@media (min-width: 768px) {
}

`

export const Stored = Styled.nav`
position: absolute;
width: 100%;
height: 100%;
z-index: 1032;
animation: fadeInRight .8s ease-in-out;

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50rem);
  }
  to {
    opacity: 1;
  }
}
`

export const ContainerBar = Styled.div`
text-align: center;

.alignment{
 display: flex;
 border-radius: 8px;
 padding: 10px 0;
 margin-bottom: 30px;
 
 
 svg{
    margin-right: 30px;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}
`

export const Category = Styled.p`

`

export const BrandLabel = Styled.p`
  color: #000000 !important;
  cursor: pointer;
`
export const SearchBar = Styled.input`

    width: 100%;
    text-align: center;
    outline: none;
    border: 0;
    ::Placeholder{
      padding-left: 60px;
    }

`
export const DivMarcas = Styled.div`
.alpha-list {
    
}

.alpha-list p {
  margin-top: 0;
  padding-bottom: 0;
}

.alpha-list > div > div {
  margin-left: 12px;
  border-bottom: 1px solid rgba(154,158,163,0.2) !important;
}

.alpha-list > div > div > div:first-child {
    margin: 5px 0 10px;
    color: ${props => props.theme.primary} !important;
    font-size: 23px !important;
    font-weight: bold !important;
  }
  .alpha-list > div:last-child > div {
    visibility: hidden;
  }
  
`
export const TitleProduct = Styled.p`
 margin-top: 50px;
 svg {
    margin-right: 5px;
 }
 
`