import Styled from 'styled-components'

export const Container = Styled.nav`
margin-bottom: 40px;
`

export const ContainerFilter = Styled.select`
float: right;
border-style: none;
background-color: white;
border-bottom: 1px solid;
color: rgba(71, 70, 70, 0.5);
:focus, :focus {
box-shadow: 0 0 0 0;
outline: 0;
}
`
export const ButtonHotel = Styled.button`
height: 30px;
width: 145px;
border-radius: 9px;
background-color: #40545E;
border-radius: 9px;
border: 0;
color: white;
margin-left: 15px;

`
export const Line = Styled.div`
border-bottom: 2px solid #C6C6C5; 
margin-top: 30px;
`
export const DivPagination = Styled.ul`
justify-content: center;
display: flex;

button{
    border: 0;
    background: none;
    color: rgba(71, 70, 70, 0.5);
    margin-right: 15px;
}

li{
    list-style-type: none;
}

button:focus{
    color: #474646;
}

.prox{
    color: #474646;
    font-weight: 400;
    font-size: 14px;
svg{
    margin-left: 5px;
}}

.anterior{
    font-weight: 400;
    font-size: 14px;
    color: #474646;
svg{
    margin-right: 5px;
}
}

`
