import Styled from "styled-components";

export const Container = Styled.section`
margin: 6%;
margin-bottom: 50px;
`
export const Label = Styled.p`
color: #263238;
font-size: 17px;
font-weight: 700;
`

export const Resume = Styled.div`
background-color: #F0F0F0;
padding: 20px;
margin-top: 20px;
margin-bottom: 6%;
.title-resume{
    color: #40545E;
    font-weight: 400;
    font-size: 18px;
    font-weight: bold;
}

.title{
    color: #40545E;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0;
   
}
.value{
    color: #6C6C6C;
    font-size: 13px;
}

.points{
    color: #40545E;
    font-weight: 400;
    font-size: 15px;   

}

.number-value{
    color: #6C6C6C;
    font-weight: 400;
    font-size: 18px;
    font-weight: bold;
}
`;

export const Points = Styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin-top: 6px;
margin-bottom: 25px;
text-align: end;

span {
    font-size: 13px;
    color: #6C6C6C;
}

.dots {
  flex: 1;
  border-bottom: 1px dashed #818181;
  margin-right: 10px;
  margin-left: 10px;
}

.daily {
    line-height: 12px;
}

.daily span {
    color: rgba(108, 108, 108, 0.7);
    font-size: 10px;
}


`;

export const Line = Styled.div`
border-bottom: 1px solid #C6C6C5;
margin-top: 25px;
`

export const Title = Styled.div`
    color: #40545E;
    font-weight: 400;
    font-size: 15px;
    margin-bottom: 0; 
`;

export const ValuePoints = Styled.div`
    color: #6C6C6C;
    font-size: 13px;
`;

export const Values = Styled.div`

display: flex;
flex-direction: row;
align-items: center;
padding-top: 20px;
font-size: 17px;

.dots {
  flex: 1;
  border-bottom: 1px dashed #818181;
  margin-right: 10px;
  margin-left: 10px;
}

`;


export const ContainerPointsSlider = Styled.div`
padding: 6%;
background-color: #F0F0F0;
`

export const ContentDiv = Styled.div`

.title-resume{
    color: #263238;
    font-weight: 400;
    font-size: 18px;
    font-weight: bold;
    padding-top: 20px;
    padding-bottom: 20px;
}
`
