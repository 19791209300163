import React from 'react'

import { Routes, Route } from 'react-router-dom'

import Home from './page/Home'

import HotelsSearchResult from "./page/HotelsSearchResult";
import HotelPage from './page/HotelPage'
import HotelResume from "./page/HotelResume";

import CarSearchResult from './page/CarSearchResult'
import CarResume from './page/CarResume'
import PassageSearchResult from "./page/PassageSearchResult";

import Checkout from './page/Checkout'
import CheckoutMessage from "./page/CheckoutMessage";

import {Error404} from "./page/Error";

export default function ConnectionBetweenPages () {
  return (
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path="hotels-search-result" element={<HotelsSearchResult />} />
            <Route path="hotel-page" element={<HotelPage />} />
            <Route path="hotel-resume" element={<HotelResume />} />

            <Route path="cars-search-result" element={<CarSearchResult/>} />
            <Route path="car-resume" element={<CarResume />} />

            <Route path="passage-search-result" element={<PassageSearchResult/>} />

            <Route path="checkout/:product" element={<Checkout />} />

            <Route path="success-checkout/:id" element={<CheckoutMessage />} />
            <Route path="error-checkout" element={<CheckoutMessage />} />

            <Route path="*" element={<Error404 />} />
        </Routes>
  )
}
