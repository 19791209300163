
import banner from '../../../assets/img/Banner.png'

export const carouselApi = [
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b19a",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b192",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b193",
        "label": "Banner",
        "image": banner,
        "location": ""
    },
    {
        "id": "47f141ad-45e1-4353-8487-cf5829489b194",
        "label": "Banner",
        "image": banner,
        "location": ""
    }
]