import Styled from 'styled-components'

export const ContainerDate = Styled.div`

.rdrSelected, .rdrInRange{
    background: #51636deb;
    
}

.rdrDateDisplayWrapper {
    background-color: transparent !important;
    
}

.rdrDateDisplay {
    margin: 0;
}

.rdrDateDisplay {
    gap: 0;
}

.rdrDayPassive > .rdrDayNumber * {
 // color: transparent !important;
  visibility: hidden;
}

.rdrMonthName {
    text-align: center;
    font-weight: 400;
    color: #000000;
    text-transform: capitalize;
}


// .rdrDayWeekend:not(.rdrDayDisabled) .rdrDayNumber span {
//     color: #333333 !important;
// }


.rdrMonthAndYearPickers {
    text-transform: capitalize;
}

.rdrWeekDay {
    text-transform: capitalize;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
    top: 0px;
    bottom: 0px;
}

.rdrStartEdge:last-child  {
    background-color: #D9D9D9;
}

.rdrDayToday .rdrDayNumber span:after {
    background: #40545E;
}

.rdrDayDisabled, .rdrDayPassive {
    background-color: #FFF;
}

.rdrDay {
    line-height: 3.4em;
    height: 3.4em;
    margin: 1px 0;
}

.rdrInRange {
    background-color: ${props => !props.noDate ? '#D9D9D9' : '#FFF' };
}

.rdrEndEdge {
    border-radius: 50%;
}

.rdrDayEndOfMonth .rdrInRange {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    margin-right: -2px;
}

.rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
}

.rdrDayStartOfMonth .rdrInRange {
    border-top-left-radius:  50%;
    border-bottom-left-radius:  50%;
    margin-left: -2px;
}

.rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius:  50%;
    border-bottom-left-radius:  50%;
}

button.rdrDay:not(.rdrDayPassive):has(span):has(.rdrStartEdge) {
    border-top-left-radius:  50%;
    border-bottom-left-radius:  50%;
    background-color: ${props => !props.noDate ? '#D9D9D9' : '#FFF' };    
}

button.rdrDay:not(.rdrDayPassive):has(span):has(.rdrEndEdge) {
    border-top-right-radius:  50%;
    border-bottom-right-radius:  50%;
    background-color: #D9D9D9;    
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
   // color: #8f8f8fd9;
}

.rdrDay .rdrInRange ~ .rdrDayNumber span {
    color: #8f8f8fd9;
}

.rdrDay:not(.rdrDayDisabled) .rdrInRange ~ .rdrDayNumber span {
    color: #333 !important;
}


.rdrStartEdge ~ .rdrDayNumber span {
    color: #FFF;
}

.rdrEndEdge ~ .rdrDayNumber span {
    color: #FFF;
}

.rdrDay:is(.rdrDayPassive) .rdrEndEdge, .rdrDay:is(.rdrDayPassive) .rdrStartEdge {
  //  background-color: rgb(64, 84, 94);
 //   border-radius: 50%;
}

.rdrDayPassive .rdrEndEdge,.rdrDayPassive .rdrStartEdge, .rdrDayPassive .rdrInRange {
 //   display: block;
}


.rdrStartEdge {
    border-radius: 50%;    
}

.rdrStartEdge .rdrDayNumber span  {
    color: #FFF !important;
    border-radius: 50%;    
}

.rdrStartEdge span.rdrDayNumber {
    position: relative;
}

.rdrStartEdge span.rdrDayNumber:before {
    content: "";
    position: absolute;
    background-color: #D9D9D9;
    height: 20px;
    width: 1.4rem;
    z-index: -1;
}

.rdrDayEndPreview {
    border-top-right-radius:  50%;
    border-bottom-right-radius:  50%;
}

.rdrDayStartPreview {
    border-top-left-radius:  50%;
    border-bottom-left-radius:  50%;
}

.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview {
    background: rgba(255, 255, 255, 0.09);
    position: absolute;
    top: 3px;
    left: 0px;
    right: 0px;
    bottom: 3px;
    pointer-events: none;
    border: 0px solid transparent; 
    z-index: 1;
}
`
