import React from 'react'

import ReserveDateTime from "components/SearchComponents/ReserveDateTime";
import Offers from "components/SearchComponents/Offers"
import { SameLocal } from "components/SearchComponents/SameLocal";
import SearchLocation from "components/SearchComponents/SearchLocation";

import { useApp } from "contexts/AppContext";
import {changeDropOffAction, changeOfferCarAction, changePickupAction} from "contexts/reducers/cars/actions";
import {isChannel} from "../../utils";
import {useGenialApp} from "../../contexts/GenialAppContext";

export default function SearchCar () {

    const { carState, carDispatch } =  !isChannel('genial') ? useApp() : useGenialApp()
    const { offersCar, locationPickupCar, locationDropOffCar, returnSameLocalCar } = carState

    const handlePickup = (data) => {
        carDispatch(changePickupAction(data))
    }

    const handleDropOff = (data) => {
        carDispatch(changeDropOffAction(data))
    }

    const changeOffers = (offers) => {
        carDispatch(changeOfferCarAction(offers))
    }

    return (
        <div className="box-search">
            <SearchLocation
                placeholder="Local de retirada"
                onChange={handlePickup}
                location={locationPickupCar}
            />

            <SearchLocation
                placeholder="Local de entrega"
                disabled={returnSameLocalCar}
                onChange={handleDropOff}
                location={returnSameLocalCar ? locationPickupCar : locationDropOffCar}
            />

            <SameLocal />

            <ReserveDateTime />

            <Offers
                offers={offersCar}
                changeOffers={changeOffers}
            />
        </div>
    )
}
