import Styled from 'styled-components'

export const Wrapper = Styled.main`
  width: 100%;
  height: calc(${props => props.size === 'small' ? '70vh' : '85vh'} - 200px);
  z-index: 1;
  position: relative;
  
  .gm-style .gm-style-iw-c{
    visibility: hidden;
}

.gm-style .gm-style-iw-tc{
    visibility: hidden;
}
`;
