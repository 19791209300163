import React, {useEffect, useState} from "react";

import {Container} from 'react-bootstrap';
import Carousel from "react-bootstrap/Carousel";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import ImgTemporaria from '../../../assets/img/Rectangle 41.svg'

import {
    Ticket,
    ContainerCarousel,
    Spance,
    Banner,
    ContainerTicketData,
    ContainerContent,
    SearchTicket,
    CheckboxContainer,
    Radio,
    AlignRadio,
    Cidade,
    Img,
    Label
} from "./styles";


import {carouselApi} from "./carousel";
import {isChannel, search_option, urlChannel} from 'utils/Safra'

import {useNavigate, useLocation } from "react-router-dom";

import Button from "components/Safra/Button";
import {CarouselDefault} from "components/Carousel";
import SearchCar from "components/SearchCar";
import SearchHotel from "components/Safra/SearchHotel";
import SearchAirfare from "../../../components/SearchAirfare";
import api from "../../../_config/http";

export default function Home() {

    const [selected, setSelected] = useState('compra_hotel');

    const navigate = useNavigate()

    const handleSearchHotels = () => {
        navigate('/hotels-search-result')
    }

    const handleSearchCars = () => {
        navigate('/cars-search-result')
    }

    const handleRadioSelect = (e) => {

        console.log(e)
    }

    const clickRadioSelect = (e) => {
        if (e === 'compra_passagem') {
            window.top.parent.location = process.env.REACT_APP_SAFRA_PASSAGEM + '/safra/passagens-aereas'
        }
    }

    const [bannerImages, setBannerImages] = useState(carouselApi)

    useEffect(() => {

        const radio = document.getElementById('radio1');
        if (radio) {
            radio.checked = true;
            radio.click();
        }

        setSelected(search_option[1].value)

    }, [])

    var carousel = carouselApi;

    useEffect(() => {
        const body = JSON.stringify({
            "channel": "safra-hotel",
        });

        api.post('/hotels/banner', body)
            .then(response => {
                if (response.status === 200) {
                    setBannerImages(response.data)
                }
            })
            .catch(error => console.log(error))
    }, [])

    return (
        <>
            <Banner >
                <Carousel variant="dark">
                    {bannerImages.map((item, index) =>
                        <Carousel.Item key={index}>
                            <img
                                className="d-block"
                                src={`${process.env.REACT_APP_SAFRA_BANNER_LINKS}/media/bannerCarrossel/slide/${item.uuid}.${item.extension}`}
                                alt={item.label}
                            />
                        </Carousel.Item>
                    )}
                </Carousel>
            </Banner>
            <Container>
                <ContainerTicketData>
                    <ContainerContent className="shadow">
                        <SearchTicket>Pesquisar Viagens</SearchTicket>
                        <CheckboxContainer>
                            {search_option.map((option, index) =>
                                <AlignRadio key={index} id={`align_${option.value}`}>
                                    <Radio
                                        type="radio"
                                        name="order"
                                        id={`radio${index}`}
                                        value={option.value}
                                        onChange={(e) => handleRadioSelect(e.target.value)}
                                        onClick={(e) => clickRadioSelect(e.target.value)}
                                        defaultChecked={!(!!index)}
                                    />
                                    <label htmlFor={`radio${index}`}>{option.label} </label>
                                </AlignRadio>

                            )}
                        </CheckboxContainer>
                        {/*  -------------------------Pesquisa---------------------- */}
                        { selected === 'compra_passagem' &&
                            <>
                                <SearchAirfare />
                                <div className="text-center">
                                    <Button onClick={() => handleSearchCars()} width="200px">Buscar</Button>
                                </div>
                            </>
                        }
                        { selected === 'compra_hotel' &&
                            <>
                                <SearchHotel buttonLabel="Continuar"/>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => handleSearchHotels()}
                                        width="200px"
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </>
                        }

                        { selected === 'compra_carro' &&
                            <>
                                <SearchCar/>
                                <div className="d-flex justify-content-center">
                                    <Button
                                        onClick={() => handleSearchCars()}
                                        width="200px"
                                    >
                                        Continuar
                                    </Button>
                                </div>
                            </>
                        }

                        {/*  -------------------------Pesquisa---------------------- */}
                    </ContainerContent>
                </ContainerTicketData>
            </Container>
        </>
    );
}
