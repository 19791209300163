/* eslint-disable react/prop-types */
import React, {useState} from 'react';

import useDebounce from "hooks/useDebounce";
import {AiOutlineSearch} from "react-icons/ai";
import TextField from '@mui/material/TextField';

const SearchInput = (props) => {

    const [loading, setLoading] = useState(false)
    const [displayValue, setDisplayValue] = useState('')
    const debouncedChange = useDebounce(props.onChange, 500, setLoading);

    const handleChange = (e) => {
        setLoading(true)
        setDisplayValue(e.target.value);
        debouncedChange(e.target.value);
    }

    return (
        <div className="input-group align-items-center">
             <TextField label={props.placeholder} defaultValue={displayValue} onChange={handleChange} variant="standard" className="w-100"/>
            <span className="input-group-genial">
               {!loading ? <AiOutlineSearch size="1rem" /> : <span className="spinner-border spinner-border-xs" role="status"/>}
            </span>
        </div>
    )
}

export default SearchInput
