import Styled from 'styled-components'

export const Container = Styled.nav`


svg{
    width: 20px;
    height: 20px;
}
`
