import Styled from 'styled-components'

export const LoaderSearch = Styled.div`

.preloader {
    background-color: #FFFFFF;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: -webkit-center;
}

.preloader .preloader-circle {
    width: 90px;
    height: 90px;
    position: relative;
    border-style: solid;
    border-width: 4px;
    border-top-color: #3BCAC5;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 10;
    border-radius: 50%;
    -webkit-box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
    box-shadow: 0 1px 5px 0 rgba(35, 181, 185, 0.15);
    background-color: #ffffff;
    -webkit-animation: zoom 1000ms infinite ;
    animation: zoom 1000ms linear infinite;
    -webkit-transition: .0s;
    -o-transition: .0s;
    transition: 0;
}

.preloader-img img {
    width: 380px;
}


@-webkit-keyframes zoom {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .0s;
        -o-transition: .0s;
        transition: .0s;
   }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .0s;
        -o-transition: .0s;
        transition: .0s;
   }
}
@keyframes zoom {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .0s;
        -o-transition: .0s;
        transition: .0s;
   }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
        -webkit-transition: .0s;
        -o-transition: .0s;
        transition: .0s;
   }
}

`
