import React from "react";

import {IoLocationSharp} from "react-icons/io5";

export const LocationAirfare = () => {

    // const {pass} = useSearch()
    // const {
    //     locationAirfare,
    //     returnAirFare,
    //     changeLocationAirfare
    // } = pass


    return (
        <>
            <div className="row">
                <div className="col">
                    <div className="input-group">
                <span className="input-group-custom">
                    <IoLocationSharp/>
                </span>
                        <input
                            className="form-control form-control-custom border-custom"
                            value={''}
                            onChange={(e) => () => {
                            }}
                            placeholder="Origem"
                        />
                    </div>
                </div>

                <div className="col">
                    <div className="input-group">
                <span className="input-group-custom">
                    <IoLocationSharp/>
                </span>
                        <input
                            className="form-control form-control-custom border-custom"
                            value={''}
                            onChange={(e) => () => {
                            }}
                            placeholder="Destino"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
