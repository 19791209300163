import Styled from "styled-components";

export const DivCarousel = Styled.div`
margin: 20px 0;

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 80%;
  width: 100%;
}

.mySwiper {
  height: 20%;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
  opacity: 0.4;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next:after, .swiper-button-prev:after {
    display: none;
}

`;

export const Img = Styled.img`
 cursor: pointer;
 display: block;
 width: 100%;
 height: 100%;
object-fit: cover;
`

export const Cidade = Styled.p`
background-color: #40545E;
text-align: center;
font-weight: 700;
font-size: 12px;
color: white;
padding-top: 5px;
padding-bottom: 5px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
`
export const ContainerCarousel = Styled.div`
  .swiper-pagination-bullet{
    display: none !important;
  }
`
