import api from '_config/http'
import format from "date-fns/format";

export function getCars(
    currentPage,
    locationPickupCar,
    locationDropOffCar,
    datesSearchCar,
    filter,
    order
) {

    const checkin = format(datesSearchCar.startDate, "yyyy-MM-dd HH:mm");
    const checkout = format(datesSearchCar.endDate, "yyyy-MM-dd HH:mm");

    let orderBy = "price"
    let ordering = "asc"

    if ( order === 'az' || order === 'za') {
        orderBy = 'name'
        ordering = order === 'az' ? 'asc' : 'desc'
    }

    if ( order === 'asc_price' || order === 'desc_price') {
        orderBy = 'price'
        ordering = order === 'asc_price' ? 'asc' : 'desc'
    }


    const filterParameters = filter?.filterParameters

    const body = JSON.stringify({
        "pickupLocationId": locationPickupCar.id,
        "dropoffLocationId": locationDropOffCar.id ?? locationPickupCar.id,
        "pickupDate": checkin,
        "dropoffDate": checkout,
        "travellers": [
            {
                "ageGroup": "adult",
                "age": 35
            }
        ],
        "paginationParameters": {
            "pageNumber": currentPage,
            "itemsPerPage": 10
        },
        filterParameters,
       "ordering": ordering,
       "orderBy": orderBy,
    });

   return api.post('/cars/search', body)
        .then(response => {

            const { data } = response.data

            if (data?.carsResponseData) {
                return { data: data.carsResponseData, status: true };
            }

            return { status: false };
        })
        .catch(error => {
            console.log(error)
            return { status: false };
        })
}

